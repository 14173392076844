import React, { useState, useEffect, useRef } from "react";
import { message, Modal, Form, Input, DatePicker, Select, InputNumber, Radio, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { innovitiPaymentCodes } from "../../../constants/config";
import { clock, timeStamp, timeValidator, currentDay, dateValidator } from "../../../utility/clock";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import { kotPrinter } from "../../../lib/printer/kotPrinter";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../lib/auth";
import { initializeCart, defaultCustomer } from "./cartUtils";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import useDebounce from "../../../lib/hooks/useDebounce";
import DefaultProductImage from "../../../assets/images/default-product.webp";
import db from "../../../database";
import Axios from "axios";
import { debounce } from "lodash";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import moment from "moment";
// import { sendOrder } from "../../../socket";
import { SyncData } from "../Restaurant/Tables-Management/SyncData";

// OMS Orders Modal Imports //
import NewWhite from "../../../assets/images/pending.svg";
import PreparingWhite from "../../../assets/images/parkedOrder.svg";
import ReadyWhite from "../../../assets/images/completed.svg";
import CompletedWhite from "../../../assets/images/prepared.svg";
import New from "../../../assets/images/new.svg";
import Preparing from "../../../assets/images/preparing.svg";
import Ready from "../../../assets/images/ready.svg";
import Completed from "../../../assets/images/todayOrders.svg";
import _ from "lodash";
import CoreModals from "./coreModals";
import ReturnBill from "./returnBill";
import { CheckoutTotalManualDiscount } from "./PricingRules/CheckoutTotalManualDiscount";
import { CheckoutFlatDiscount } from "./PricingRules/CheckoutFlatDiscount";
import { CheckoutPercentageDiscount } from "./PricingRules/CheckoutPercentageDiscount";
import { createCustomer, getCustomer, updateCustomer } from "./customer";
import { pricingRuleController } from "./PricingRules/pricingRuleController";
import { barcodeScaner } from "./scaner";
import { TotalBillDiscount } from "./PricingRules/totalBillDiscount";
import { TotalBillFreeProductDiscount } from "./PricingRules/totalBillFreeProductDiscount";
import HCPrint from "../../../lib/printer/hardWareControllerPrinter";
import { paymentProcess } from "./payment/paymentProcess";
import PrintController from "../../../lib/printer/printController";
import { addProductToCart } from "./cart";
import { addAmount, completePayment } from "./payment/paymentController";
import PoleDisplay from "../../../lib/printer/poleDisplay";
import { useTranslation } from "react-i18next";
import upsertPOSLog from "../Retail/posLog";

// import BillManagement from "../Restaurant/billManagement";

// PointOfsaleCore Component Start
const PointOfsaleCore = (props) => {
  const { t } = useTranslation();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const edcUrl = process.env.REACT_APP_edcUrl;
  const RenderComponent = props.component;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  const ObFlag = tillData.tillAccess.cwrTill.printTemplate.obController === "Y" ? true : false;
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const precision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  // const defaultCustomer = JSON.parse(localStorage.getItem("defaultCustomer"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
  let tillDataPaymentMethods = tillData.tillAccess.csBunit.paymentMethodList;
  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
  const isPrintModeXML = tillData.tillAccess.cwrTill.hardwareController.printReceipt === "Y" ? true : false;
  const history = useHistory();
  const qs = require("querystring");
  const tillValue = JSON.parse(localStorage.getItem("tillValue"));
  // let setAuthTokens;
  const paymentModalInputRef = useRef();
  const quantityInputRef = useRef();
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(async () => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  // KeyBord changes start
  const [orderHistoryInput, setOrderHistoryInput] = useState("");
  const [keyboardType, setKeyboardType] = useState({ product: false, parkedBill: false, salesHistoryDoc: false, salesHistoryCus: false });
  const [layout, setLayout] = useState("default");
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputFocused, setInputFocused] = useState(null);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filtersFlag, setFiltersFlag] = useState(false);
  const [filteredDate, setFilterdDate] = useState(null);
  const [searchHistoryInput, setSearchhistoryInput] = useState("");
  const keyboardProduct = useRef(null);
  const keyboardParkbill = useRef(null);
  const keyboardRef = useRef(null);
  const orderHistorySearchInputRef = useRef(null);
  const [notesValue, setNotesValue] = useState("");
  const [selectedProductForNotes, setSelectedProductForNotes] = useState(null);

  const handleKeyPress = (button) => {
    if (button === "{shift}" || button === "{caps}") setLayout("shift");
    if (button === "{default}" || button === "{small}") setLayout("default");
    if (button === "{numbers}") setLayout("numbers");
    if (button === "{number}") setLayout("number");
    if (button === "{done}") {
      switch (true) {
        case keyboardType.parkedBill:
          searchParkedBill();
          break;
        case keyboardType.salesHistoryDoc:
          searchOrderHistory();
        case keyboardType.salesHistoryCus:
          searchOrderHistory();
          break;
        case keyboardType.product:
          // getSearchedProducts();
          break;
        // Add more cases as needed
        default:
          // Handle the default case or do nothing
          break;
      }
    }
  };

  const handleKeyboardInput = (inputs) => {
    if (keyboardType.parkedBill === true) {
      setProductSearchInput("");
      setParkedBillSearchInput(inputs.default);
    } else if (keyboardType.product === true) {
      if (inputs.default === "") {
        clearProductSearchResults();
      } else {
        setProductSearchInput(inputs.default);
      }
    } else if (keyboardType.salesHistoryDoc === true) {
      setOrderHistoryInput(inputs.default);
    } else if (keyboardType.salesHistoryCus === true) {
      setOrderHistoryInput(inputs.default);
    }
  };

  // KeyBord changes End

  // ORDER TYPE BLOCK START
  const [orderType, setOrderType] = useState();
  const [posSaleTypes, setPosSaleTypes] = useState([]);
  const [displaySetOrderType, setDisplayOrderType] = useState(false);
  const changeOrderType = (type) => {
    setDisplayOrderType(false);
    setOrderType(type);
  };
  useEffect(() => {
    db.posSaletypes.toArray().then((saleType) => {
      setPosSaleTypes([...saleType]);
      const saleIndex = saleType.findIndex((st) => st.cwrSaletype.isdefault === "Y");
      setOrderType(saleType[saleIndex]);
    });
  }, []);
  // ORDER TYPE BLOCK END

  // Cash Management Start
  const [cashAddInFlag, setCashAddInFlag] = useState(false);
  const [cashManagementForm] = Form.useForm();
  const [cashIn, setCashOut] = useState(true);
  const [pettCashIn, setPettCashIn] = useState(false);
  const [addCashFlag, setAddCashFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [giftCardFlag, setGiftCardFlag] = useState(false);
  const [isGiftCardFlag, setIsGiftCardFlag] = useState(false);
  const [isCardPaymentFlag, setIsCardPaymentFlag] = useState(false);
  const [CardPaymentForm] = Form.useForm();

  const handleCahInOut = async (data) => {
    let formData = cashManagementForm.getFieldsValue(true);
    formData.key = uuidv4().replace(/-/g, "").toUpperCase();
    formData.id = uuidv4().replace(/-/g, "").toUpperCase();
    formData.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    if (cashAddInFlag === true) {
      if (formData.type === "cashOut" || !formData.type) {
        formData.type = "cashOut";
      } else {
        formData.type = "pettyCashOut";
      }
    } else {
      if (formData.type === "cashIn" || !formData.type) {
        formData.type = "cashIn";
      } else {
        formData.type = "pettyCashIn";
      }
    }
    console.log(formData.type, formData);
    // formData.notes = data.note
    if (formData.type === "cashIn") {
      upsertPOSLog(formData, "CAI");
    } else if (formData.type === "cashOut") {
      upsertPOSLog(formData, "CAO");
    }
    setPettCashIn(false);
    setCashOut(true);
    db.cashInCashOut.add(formData);
    cashManagementForm.resetFields();
    setAddCashFlag(false);

    let obj = { cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 };
    let cashDetails = await db.cashInCashOut.toArray();

    cashDetails.map((item) => {
      if (item.type === "cashIn") {
        obj.cashIn += parseFloat(item.amount);
      } else if (item.type === "cashOut") {
        obj.cashOut += parseFloat(item.amount);
      }
      if (item.type === "pettyCashIn") {
        obj.pettCashIn += parseFloat(item.amount);
      }
      if (item.type === "pettyCashOut") {
        obj.pettCashOut += parseFloat(item.amount);
      }
    });
    const tillSession = JSON.parse(localStorage.getItem("tillSession"));
    db.tillEvents
      .where("tillSessionId")
      .equals(tillSession.tillSessionId)
      .modify((tillEvent) => {
        tillEvent.cashInOutData = obj;
      });
  };

  const onChangeCheckbox = (e) => {
    const eventId = e.target.id;
    const checkedValue = e.target.checked;
    let formData = cashManagementForm.getFieldsValue(true);
    if (cashAddInFlag) {
      if (eventId === "cashOut") {
        if (checkedValue === true) {
          setCashOut(true);
          setPettCashIn(false);
          formData.type = "cashOut";
        }
      }
      if (eventId === "pettyCashOut") {
        if (checkedValue === true) {
          formData.type = "pettyCashOut";
          setCashOut(false);
          setPettCashIn(true);
        }
      }
    } else {
      if (eventId === "cashIn") {
        if (checkedValue === true) {
          formData.type = "cashIn";
          setCashOut(true);
          setPettCashIn(false);
        }
      }
      if (eventId === "pettyCashIn") {
        if (checkedValue === true) {
          formData.type = "pettyCashIn";
          setCashOut(false);
          setPettCashIn(true);
        }
      }
    }
    cashManagementForm.setFieldsValue(formData);
  };

  // Cash Management End
  const [giftCardForm] = Form.useForm();
  const [giftCardItems, setGiftCardItems] = useState([]);
  const [giftCardType, setGiftCardType] = useState("giftCard");
  const [selectGiftCardItem, setSelectGiftCardItem] = useState({});
  const [validateGiftCard, setValidateGiftCard] = useState(false);
  const [giftCardData, setGiftCardData] = useState([]);
  const [giftCardBalance, setGiftCardBalance] = useState(0);
  const [validateGiftCardForm] = Form.useForm();

  const handleGiftCardDetails = async (data) => {
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    let returnFlag = cart.isReturn === true || cart.isReturn === "Y" ? data.amount <= Math.abs(cart.total) : true;
    if (returnFlag && data.amount > 0) {
      let matchedGiftCardData;
      await db.giftCardData.toArray().then((giftCardData) => {
        giftCardData.map((giftcard) => {
          if (giftcard.mProductId === selectGiftCardItem.mProductId) {
            matchedGiftCardData = giftcard;
          }
        });
      });
      let refId = uuidv4().replace(/-/g, "").toUpperCase();
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation{
              verifyGiftCard(giftCards:[{
              cwrGiftcardTypeId: "${matchedGiftCardData?.cwrGiftcardTypeId}"
              cardNo: ${data.number ? `"${data.number}"` : null}
              referenceNo: "${refId}"
              b2cCustomerId: "${cart.customer.cwrCustomerId}"
              cardPin: ${data.cardPin ? `"${data.cardPin}"` : null}
              currentBalance: ${parseFloat(data.amount)}
                  }]){
                  status
                  message
                  cardNo
                  expiryDate
              }
          }
                    `,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      })
        .then(async (giftCardResponse) => {
          if (giftCardResponse.data.data.verifyGiftCard.status === "200") {
            setValidateGiftCard(false);
            setGiftCardBalance("0.00");
            const currentDate = moment();
            // Add the number of days to the current date
            const futureDate = currentDate.add(matchedGiftCardData.validity, "days");
            selectGiftCardItem.isGiftCard = true;
            selectGiftCardItem.realPrice = parseFloat(data.amount);
            selectGiftCardItem.total = parseFloat(data.amount);
            selectGiftCardItem.nettotal = parseFloat(data.amount);
            selectGiftCardItem.salePrice = parseFloat(data.amount);
            selectGiftCardItem.discAmount = 0;
            selectGiftCardItem.taxRate = 0;
            selectGiftCardItem.taxAmount = 0;
            selectGiftCardItem.unitPrice = parseFloat(data.amount);
            selectGiftCardItem.giftCardType = matchedGiftCardData.type;
            selectGiftCardItem.cardNo = matchedGiftCardData.type === "PHY" ? data.number : giftCardResponse.data.data.verifyGiftCard.cardNo;
            let netList = parseFloat(data.amount);
            selectGiftCardItem.linetax = 0;
            selectGiftCardItem.linenet = parseFloat(data.amount);
            selectGiftCardItem.linegross = selectGiftCardItem.nettotal;
            selectGiftCardItem.netunit = parseFloat(data.amount);
            selectGiftCardItem.listprice = parseFloat(data.amount);
            selectGiftCardItem.grossunit = parseFloat(data.amount);
            selectGiftCardItem.grossstd = selectGiftCardItem.sunitprice;
            selectGiftCardItem.grosslist = parseFloat(data.amount);
            selectGiftCardItem.netList = netList;
            selectGiftCardItem.expiryGiftCard = futureDate.format("DD-MM-YYYY");
            let cardDetails = [...giftCardData];
            cardDetails.push({
              number: matchedGiftCardData.type === "PHY" ? data.number : giftCardResponse.data.data.verifyGiftCard.cardNo,
              amount: parseFloat(data.amount),
              refId: refId,
            });
            setCart({
              ...cart,
              giftCardData: cardDetails,
            });
            setGiftCardData(cardDetails);
            upsertPOSLog(cart, "GCI");
            addProduct(selectGiftCardItem, 1);
          } else {
            Sentry.captureException(new Error("verify Gift card failed"), {
              extra: JSON.stringify({
                cwrGiftcardTypeId: matchedGiftCardData?.cwrGiftcardTypeId || null,
                cardNo: data.number ? `"${data.number}"` : null,
                referenceNo: refId,
                b2cCustomerId: cart.customer?.cwrCustomerId || null,
                cardPin: data.pin ? `"${data.pin}"` : null,
                currentBalance: data.amount || null,
                response: giftCardResponse?.data?.data || null,
              }),
            });
            // Sentry.captureException(giftCardResponse.data.data.verifyGiftCard.message);
            message.error(giftCardResponse.data.data.verifyGiftCard.message);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          message.error(err);
        });
    } else {
      message.error(`The ${giftCardType} amount cannot exceed the return amount. Please enter a valid amount.`);
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const redeemGiftCard = async (data) => {
    setIsSubmitting(true);
    let uniqId = uuidv4().replace(/-/g, "").toUpperCase();
    let giftCardAmount = 0;
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();

    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }

    await Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
              checkGiftCardBalance(cardNo: "${data.number}", cardPin: ${data.pin ? `"${data.pin}"` : null}){
                  currentBalance
                  status
                  message
                }
            }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${setAuthTokens}`,
      },
    })
      .then(async (giftCardResponse) => {
        if (giftCardResponse.data.data.checkGiftCardBalance.length > 0 && giftCardResponse.data.data.checkGiftCardBalance[0].status === "200") {
          giftCardAmount = giftCardResponse.data.data.checkGiftCardBalance[0].currentBalance;
        }
      })
      .catch((err) => {
        message.error(err);
      });

    if (cart.total - cart.paid >= data.amount && data.amount > 0) {
      await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation{
                            upsertGiftCardTransaction(giftCardTransactions:[{
                            cardNo: "${data.number}"
                            type: "RD"
                            referenceNo: "${uniqId}"
                            amount: ${parseFloat(data.amount).toFixed(2)}
                            cardPin: ${data.pin ? `"${data.pin}"` : null}
                                }]){
                                status
                                message
                            }
                        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      })
        .then(async (upsertGiftCard) => {
          if (upsertGiftCard.data.data.upsertGiftCardTransaction.status === "200") {
            setGiftCardFlag(false);
            giftCardForm.resetFields();
            setGiftCardBalance(0);
            let cardDetails = [...giftCardData];
            let giftCardIndex = giftCardData.findIndex((item) => item.number !== data.number);
            if (giftCardIndex >= 0) {
              cardDetails[giftCardIndex].number = data.number;
              cardDetails[giftCardIndex].amount = giftCardAmount >= data.amount ? data.amount : giftCardAmount + cardDetails[giftCardIndex].amount;
              cardDetails[giftCardIndex].redemptionId = uniqId;
              cardDetails[giftCardIndex].refId = cardDetails[giftCardIndex].refId;
              cardDetails[giftCardIndex].pin = data.pin ? data.pin : null;
            } else {
              cardDetails.push({
                number: data.number,
                amount: giftCardAmount >= data.amount ? data.amount : giftCardAmount,
                redemptionId: uniqId,
                pin: data.pin ? data.pin : null,
              });
            }
            setCart({
              ...cart,
              giftCardData: cardDetails,
            });
            setGiftCardData(cardDetails);
            requestPayment(selectedPaymentMethod, giftCardAmount >= data.amount ? data.amount : giftCardAmount);
            upsertPOSLog(cart, "GCR");
            setIsSubmitting(false);
          } else {
            setIsSubmitting(false);
            message.error(upsertGiftCard.data.data.upsertGiftCardTransaction.message);
            Sentry.captureException(new Error("Gift card transaction failed"), {
              extra: {
                cardNo: data.number,
                type: "RD",
                referenceNo: uniqId,
                amount: data.amount,
                cardPin: data.pin || null,
                response: upsertGiftCard.data.data,
              },
            });
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          Sentry.captureException(err);
          message.error(err);
        });
    } else {
      message.error("The redeem amount exceeds your gift card balance. Please enter an amount up to the available balance.");
    }
  };

  const handleGiftCard = async (data) => {
    let formData = giftCardForm.getFieldsValue(true);
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();

    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    if (formData.number !== "") {
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {
              checkGiftCardBalance(cardNo: "${formData.number}", cardPin: ${formData.pin ? `"${formData.pin}"` : null}){
                  currentBalance
                  status
                  message
                }
            }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      })
        .then(async (giftCardResponse) => {
          if (giftCardResponse.data.data.checkGiftCardBalance.length > 0 && giftCardResponse.data.data.checkGiftCardBalance[0].status === "200") {
            setGiftCardBalance(giftCardResponse.data.data.checkGiftCardBalance[0].currentBalance);
          } else {
            message.error(`${giftCardResponse.data.data.checkGiftCardBalance[0].message}`);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      message.error("Please enter the Gift Card Number.");
    }
  };

  // CUSTOMER  SEARCH AND SELECTION BLOCK START
  const [displayCustomerSearch, setDisplayCustomerSearch] = useState(false);
  const [displayUAECustomerSearch, setDisplayUAECustomerSearch] = useState(false);
  const [displayUAECustomer, setDisplayUAECustomer] = useState(false);
  const [customerSearchType, setCustomerSearchType] = useState(() => (posConfig.defaultCustomerSearch === "Search Key" ? "searchKey" : "mobile"));
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [customerSearchResults, setCustomerSearchResults] = useState();
  const [closeCustomerFlag, setCloseCustomerFlag] = useState(false);
  const [properties, setProperties] = useState("");
  const [kioskUI, setKioskUI] = useState(parseFloat(localStorage.getItem("kioskUI")) ? parseFloat(localStorage.getItem("kioskUI")) : 0);
  const [kioskLogin] = Form.useForm();
  const [layoutType, setLayoutType] = useState(parseFloat(localStorage.getItem("layoutType")) ? parseFloat(localStorage.getItem("layoutType")) : 0);
  const [addToBagProducts, setAddToBagProducts] = useState([]);
  const [addToBagFlag, setAddToBagFlag] = useState(false);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      const dynamoDBValue = event.detail.newValue;
      if (event.detail.key === "kioskUI") {
        setKioskUI(dynamoDBValue ? dynamoDBValue : parseFloat(localStorage.getItem("kioskUI")) || 0);
      } else if (event.detail.key === "layoutType") {
        setLayoutType(dynamoDBValue ? dynamoDBValue : parseFloat(localStorage.getItem("layoutType")) || 0);
      }
    };

    db.products.toArray().then((productsFetched) => {
      let items = [];
      let bagItems = [];
      productsFetched.map(async (item) => {
        if (item.productSegment === "GC") {
          const giftCardData = await db.giftCardData.toArray();
          const matchingGiftCard = giftCardData.filter((giftcard) => giftcard.mProductId === item.mProductId);
          item.matchingGiftCard = matchingGiftCard;
          items.push(item);
        } else if (item.productSegment === "BG") {
          bagItems.push(item);
        }
      });
      setAddToBagProducts(bagItems);
      setGiftCardItems(items);
    });
    window.addEventListener("customStorageChange", handleCustomEvent);

    return () => {
      window.removeEventListener("customStorageChange", handleCustomEvent);
    };
  }, []);

  const debouncedCustomerSearch = useDebounce(customerSearchInput, 350);
  useEffect(() => {
    if (debouncedCustomerSearch !== "") {
      handleCustomerSearch(debouncedCustomerSearch);
    } else {
      setCustomerSearchResults([]);
    }
  }, [debouncedCustomerSearch]);

  const closeCustomerSearch = (obj) => {
    setDisplayCustomerSearch(false);
    setDisplayUAECustomerSearch(false);
    setCustomerSearchInput("");
    setCustomerSearchResults();
    setIsInputFocused(false);
    const productSearchInput = document.getElementById("sm-product-search");
    if (productSearchInput) {
      productSearchInput.focus();
    }
  };

  const handleCustomerSearch = async () => {
    getCustomer(form, tillLayout, customerSearchInput, setKioskUI, setCustomerSearchResults, kioskLogin);
  };

  const [orderTimeDetails, setOrderTimeDetails] = useState({ orderStartTime: "", orderEndTime: "", paymentStartTime: "" });

  const selectCustomer = async (index) => {
    setCustomerFlag(false);
    let latestData = removeAllDiscounts();

    const truncateDecimal = (num, decimalPlaces) => {
      // Convert the number to a string
      let numStr = num.toString();

      // Find the position of the decimal point
      let decimalIndex = numStr.indexOf(".");

      // If there is no decimal point or if there are less decimal places than desired, return the number as is
      if (decimalIndex === -1 || numStr.length - decimalIndex - 1 <= decimalPlaces) {
        return num;
      }

      // Truncate the string to the desired number of decimal places
      numStr = numStr.slice(0, decimalIndex + decimalPlaces + 1);

      // Parse the truncated string back to a number
      return parseFloat(numStr);
    };

    let num = Math.abs(customerSearchResults[index].retlLoyaltyBalance);
    let truncatedNum = truncateDecimal(num, 2);

    customerSearchResults[index].retlLoyaltyBalance = truncatedNum;
    const cartObj = {
      ...latestData,
      customer: customerSearchResults[index],
    };
    let updatedCart = cartObj;

    if (cartObj.items.length > 0) {
      await Promise.all(
        cartObj.items.map(async (ele) => {
          let addToCart = ele;
          updatedCart = await pricingRuleController(addToCart, cartObj, cartObj, setCart, cartObj, orderType);
          return updatedCart;
        })
      );
    } else {
      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
        ? JSON.parse(localStorage.getItem("orderTimeDetails"))
        : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails = {
        ...orderTimeDetails,
        orderStartTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // Update orderStartTime to current time
      };
      localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    }

    if (updatedCart?.couponInput?.length > 0) {
      let addToCart = cartObj.items[0];
      await Promise.all(
        updatedCart.couponInput.map(async (coupon) => {
          const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(coupon.mPricingruleId).toArray();
          if (matchingPricingRules[0].type !== "TD" && matchingPricingRules[0].type !== "TDF") {
            updatedCart = await pricingRuleController(
              addToCart,
              updatedCart,
              cart,
              setCart,
              cartRef,
              orderType,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          } else {
            updatedCart = await processBillDiscounts(
              matchingPricingRules[0],
              updatedCart,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          }
        })
      );
    }
    if (cartObj.totalDiscountFlag) {
      await openPaymentModalByCustomer(updatedCart);
    }
    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;

    updatedCart.items = updatedCart.items.map((item, i) => {
      const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
      const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
      const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

      // Update aggregated totals
      updatedTotalPrice += nettotalFixed;
      updatedTotalItemsQty += item.weight;
      updatedTotalTax += taxAmountFixed;
      updatedTotalDiscounts += discountFixed;

      // Update individual item properties
      item.discount = discountFixed;
      item.key = i;
      item.nettotal = parseFloat(item.nettotal.toFixed(precision));

      if (!item.isGiftCard) {
        let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
        if (!isFinite(unitPrice)) unitPrice = 0;

        const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
        const netList = (item.listPrice - item.listPrice / (1 + item.taxRate / 100)).toFixed(precision);

        item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
        item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
        item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
        item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
        item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
        item.grosslist = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
        item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
        item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      }
      return item;
    });

    const sumLineTotals = (lines) => {
      let totalLineGross = 0;
      let totalLineTax = 0;

      lines.forEach((line) => {
        totalLineGross += line.nettotal;
        totalLineTax += line.taxAmount;
      });

      return { totalLineGross, totalLineTax };
    };
    // Compare and Adjust Order Totals
    const adjustOrderTotals = (order, lines) => {
      const { totalLineGross, totalLineTax } = sumLineTotals(lines);
      let adjustedOrderGross = order.total;
      let adjustedOrderTax = order.tax;

      const grossDifference = totalLineGross - adjustedOrderGross;
      const taxDifference = totalLineTax - adjustedOrderTax;

      if (Math.abs(grossDifference) > 0.01) {
        adjustedOrderGross += grossDifference;
      }

      if (Math.abs(taxDifference) > 0.01) {
        adjustedOrderTax += taxDifference;
      }

      return {
        ...order,
        total: adjustedOrderGross,
        tax: adjustedOrderTax,
      };
    };
    updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);
    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
      customer: customerSearchResults[index],
    };
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    setCart({ ...finalCartObj });
    closeCustomerSearch(finalCartObj, true);
    setShowPaymentMethods(false);
    setIsInputFocused(false);
    upsertPOSLog(finalCartObj, "ACT");
  };
  // CUSTOMER  SEARCH AND SELECTION BLOCK END

  // ADD NEW CUSTOMER BLOCK START
  const [form] = Form.useForm();
  const [UAECustomerForm] = Form.useForm();
  const [displayAddNewCustomer, setDisplayAddNewCustomer] = useState(false);

  const showAddNewCustomerFields = async () => {
    setDisplayCustomerSearch(false);
    let customerSearchType = "name";
    if (/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(customerSearchInput)) {
      customerSearchType = "email";
    } else if (/^\d+$/.test(customerSearchInput)) {
      customerSearchType = "mobile";
    } else {
      customerSearchType = "name";
    }
    if (customerSearchType === "mobile") {
      form.setFieldsValue({
        mobile: customerSearchInput,
        name: "",
        email: "",
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      });
    } else if (customerSearchType === "name") {
      form.setFieldsValue({
        name: customerSearchInput,
        mobile: "",
        email: "",
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      });
    } else if (customerSearchType === "email") {
      form.setFieldsValue({
        name: "",
        mobile: "",
        email: customerSearchInput,
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      });
    }
    let loyaliryData = await db.loyalityData.toArray();
    loyaliryData.map((item) => {
      if (item.isDefault === "Y") {
        form.setFieldsValue({
          program: item.loyaltylevelId,
        });
      }
    });
    setDisplayAddNewCustomer(true);
  };

  const showAddNewUAECustomerFields = async () => {
    setDisplayUAECustomerSearch(false);
    let customerSearchType = "name";
    if (/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(customerSearchInput)) {
      customerSearchType = "email";
    } else if (/^\d+$/.test(customerSearchInput)) {
      customerSearchType = "mobile";
    } else {
      customerSearchType = "name";
    }
    let obj = {};
    if (customerSearchType === "mobile") {
      obj = {
        mobile: customerSearchInput,
        name: "",
        email: "",
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      };
      UAECustomerForm.setFieldsValue(obj);
    } else if (customerSearchType === "name") {
      obj = {
        name: customerSearchInput,
        mobile: "",
        email: "",
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      };
      UAECustomerForm.setFieldsValue(obj);
    } else if (customerSearchType === "email") {
      obj = {
        name: "",
        mobile: "",
        email: customerSearchInput,
        country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
        state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
      };
      UAECustomerForm.setFieldsValue(obj);
    }
    let loyaliryData = await db.loyalityData.toArray();
    loyaliryData.map((item) => {
      if (item.isDefault === "Y") {
        form.setFieldsValue({
          program: item.loyaltylevelId,
        });
      }
    });
    setDisplayUAECustomer(true);
  };

  const addNewCustomer = async (data) => {
    createCustomer(data, cart, setCart, form, tillLayout, closeCustomerSearch, tillData, posLogActivity, setKioskUI, setDisplayAddNewCustomer, UAECustomerForm);
  };
  // ADD NEW CUSTOMER BLOCK END

  // EDIT CUSTOMER BLOCK END
  const [displayEditOldCustomer, setDisplayEditOldCustomer] = useState(false);
  const [selectedEditOldCustomer, setSelectedEditOldCustomer] = useState();

  const showEditOldCustomerFields = async (customer) => {
    setDisplayCustomerSearch(false);
    setSelectedEditOldCustomer(customer);
    if (!customer) {
      console.error("Customer data not available");
      return; // Exit the function if no customer data is provided
    }

    form.setFieldsValue({
      editName: customer.name || "", // Fallback to empty string if name is not available
      editMobile: customer.mobileNo || "",
      editEmail: customer.email || "",
      editPincode: customer.pincode || "",
      name: customer.name || "",
      mobile: customer.mobileNo || "",
      birthday: customer.birthday ? moment(customer.birthday) : null,
      anniversaryDate: customer.anniversaryDate ? moment(customer.anniversaryDate) : null,
      city: customer.customerAddress?.line2 || "",
      street: customer.customerAddress?.line1 || "",
      gender: customer.gender || "",
      lastName: customer.lastName || "",
      taxID: customer.taxId || "",
      pincode: customer.pincode || "",
      country: customer.customerAddress?.country || tillData.tillAccess.csBunit.customerAddress?.csCountry?.name || "",
      state: customer.customerAddress?.region || tillData.tillAccess.csBunit.customerAddress?.csRegion?.name || "",
    });

    try {
      let loyaltyData = await db.loyalityData.toArray();
      loyaltyData.forEach((item) => {
        if (item.isDefault === "Y") {
          form.setFieldsValue({
            program: item.loyaltylevelId || "", // Handle missing loyalty level
          });
        }
      });
    } catch (error) {
      console.error("Error fetching loyalty data:", error);
    }

    setDisplayEditOldCustomer(true);
  };

  const editOldCustomer = async (data) => {
    updateCustomer(
      data,
      cart,
      setCart,
      form,
      closeCustomerSearch,
      selectedEditOldCustomer,
      setSelectedEditOldCustomer,
      setDisplayEditOldCustomer,
      tillData,
      cart?.customer,
      UAECustomerForm
    );
  };
  // EDIT CUSTOMER BLOCK END

  //// CENTER BUTTON BLOCK START /////

  const [isQtyUpdate, setIsQtyUpdate] = useState(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [selectedProductInCart, setSelectedProductInCart] = useState({});
  const [selectedProductQty, setSelectProductQty] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const selectProductInCart = (record, e) => {
    if (record.productId === selectedProductInCart.productId && e !== "1") {
      // setSelectedRowKeys([]);
      // setSelectedProductInCart({});
    } else {
      record.weight = parseFloat(record.weight.toFixed(record?.isQtyDesimal));
      setSelectedRowKeys([record.key]);
      setSelectedProductInCart(record);
    }
  };

  const enterTotalQty = async () => {
    PoleDisplay(selectedProductInCart, "Quantity update");
    if (cart.items.length > 0 && selectedProductInCart.weight > 0 && !selectedProductInCart.isReturn) {
      let totalQtyFlag = true;
      setSelectedProductInCart({});
      let product = {};
      const addedToCart = cart.items;

      // Use Promise.all to wait for all async operations to complete
      await Promise.all(
        addedToCart.map(async (item) => {
          if (item.value === selectedProductInCart.value && item.lineId === selectedProductInCart.lineId) {
            product = item;
          }
          // if (item.value === selectedProductInCart.value && !item.bundleId) {
          //   product = item;
          // }
          // if (item.value === selectedProductInCart.value && item.bundleId) {
          //   const productItem = await getProductData(item.productId);
          //   product = productItem;
          //   product.weight = 1;
          //   product.isReturn = false;
          //   product.bundleId = null;
          // }
        })
      );
      delete product.expiryId;
      setIsInputFocused(false);
      addProduct(product, selectedProductInCart.weight, totalQtyFlag);
      const amountInput = document.getElementById("sm-amount-input");
      if (amountInput) {
        amountInput?.focus();
        amountInput?.blur();
      }
    }
  };

  const onChangeTotalQuantity = async (e) => {
    if (!selectedProductInCart.isReturn && Object.keys(selectedProductInCart).length > 0) {
      delete selectedProductInCart.expiryId;
      setSelectedProductInCart({ ...selectedProductInCart, weight: selectedProductInCart.isDecimalQty === true ? e : e.replaceAll(".", "") });
    }
  };

  const handleTotalQty = async (value) => {
    // if (isInputFocused === false) {
    //   if (productSearchInput === "" && value === "x") {
    //     setProductSearchInput("");
    //   } else if (value === "x") {
    //     setProductSearchInput(`${productSearchInput.toString().substring(0, productSearchInput.toString().length - 1)}`);
    //   } else {
    //     setProductSearchInput(`${productSearchInput ? productSearchInput : ""}${value}`);
    //   }
    // } else {
    if (Object.keys(selectedProductInCart).length > 0 && !selectedProductInCart.isReturn) {
      if (selectedProductQty === "" && value === "x") {
        setSelectedProductInCart({ ...selectedProductInCart, weight: 0 });
      } else if (value === "x") {
        setSelectedProductInCart({
          ...selectedProductInCart,
          weight: `${selectedProductInCart.weight.toString().substring(0, selectedProductInCart.weight.toString().length - 1)}`,
        });
      } else if (value === "clear") {
        setSelectedProductInCart({ ...selectedProductInCart, weight: 0 });
      } else {
        if (qtyNumberFlag === 0) {
          setQtyNumberFlag(1);
          setSelectedProductInCart({ ...selectedProductInCart, weight: value });
        } else {
          setSelectedProductInCart({
            ...selectedProductInCart,
            weight: selectedProductInCart.isDecimalQty === true ? `${selectedProductInCart.weight}${value}` : `${selectedProductInCart.weight}${value}`.replaceAll(".", ""),
          });
        }
      }
    }
    const amountInput = document.getElementById("sm-amount-input");
    if (amountInput) {
      amountInput?.focus();
    }
    // }
  };
  const selectProduct = (record) => {
    setSelectedKeys([record.key]);
    // setSelectedProduct(record);
  };

  const selectSalseProduct = (record) => {
    setSelectedKeys([record.sOrderID]);
    // setSelectedProduct(record);
  };

  const deleteProduct = async (addToCart) => {
    clearSelectedProductInCart();
    if (!addToCart.isReturn && cart.payments.length <= 0) {
      addProduct(addToCart, -addToCart.weight);
      if (giftCardData?.length > 0) {
        let setAuthTokens;
        const authHeaders = await getOAuthHeaders();
        if (authHeaders && authHeaders.access_token) {
          setAuthTokens = authHeaders.access_token;
        }
        let details = [];
        let paidAmount = 0;
        giftCardData.map((ele) => {
          paidAmount += ele.amount;
          details.push(`{
              cardNo: ${ele.number ? `"${ele.number}"` : null}
              type: "RD"
              referenceNo: "${cart.referenceId}"
              amount: ${ele.amount * -1}
              cardPin: ${ele.pin ? `"${ele.pin}"` : null}
                  }`);
        });
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation{
                            upsertGiftCardTransaction(giftCardTransactions:[${details}]){
                                status
                                message
                            }
                        }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });

        let updatedPayemnt = cart.payments.filter((payment) => payment.name.toLowerCase() !== "gift card");
        // cart.paid = cart.paid - paidAmount;
        cart.payments = updatedPayemnt;
        cart.giftCardData = [];
        cart.total = cart.total - paidAmount;
        setGiftCardData([]);
        setCart(cart);
      }
      if (JSON.parse(localStorage.getItem("posConfig"))?.DLN === "Y") {
        posLogActivity(addToCart, "DLN");
      }
    } else if (cart.payments.length > 0) {
      message.warning("please delete the payment before clearing the cart items");
    }
  };

  const decreaseProductQty = (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
        if (addToCart.weight - 1 !== 0) {
          delete addToCart.expiryId;
        }
        addProduct(addToCart, -1);
        if (JSON.parse(localStorage.getItem("posConfig"))?.RQT === "Y") {
          posLogActivity(addToCart, "RQT");
        }
        const amountInput = document.getElementById("sm-amount-input");
        if (amountInput) {
          amountInput?.focus();
          amountInput?.blur();
        }
      }
    }
  };

  const increaseProductQty = (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
        delete addToCart.expiryId;
        addProduct(addToCart, 1);
        const amountInput = document.getElementById("sm-amount-input");
        if (amountInput) {
          amountInput?.focus();
          amountInput?.blur();
        }
      }
    }
  };

  const deleteReceipt = (param) => {
    let cartObj = {
      items: [],
      couponInput: [],
      advancePayment: 0,
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
      giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      customer: defaultCustomer,
      salesRepId: null,
      cardPaymentData: {},
      documentno: param ? `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}` : `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 2}`,
    };
    setCart(cartObj);
    localStorage.setItem("cartObj", JSON.stringify(cartObj));
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    setAmount("");
    setSelectedPaymentMethod("");
  };

  const deleteCart = (status = false, type) => {
    if (status === true) {
      if (JSON.parse(localStorage.getItem("posConfig"))?.DOR === "Y") {
        upsertPOSLog(cart, "DOR");
      }
    }
    deleteReceipt(type);
    /* if (parkedList.length > 0) {
        selectParkedBill(parkedList[0]);
      } */
  };

  // ORDER HISTORY BLOCK START
  const [displayOrderHistory, setDisplayOrderHistory] = useState(false);
  const [orderHistoryDetails, setOrderHistoryDetails] = useState([]);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const [salesHistoryType, setSalesHistoryType] = useState([]);
  const [startRowData, setStartRowData] = useState({ startRow: "0", endRow: "10" });

  const [orderHistorySearchType, setOrderHistorySearchType] = useState("orderDocumentNo");
  const changeOrderHistorySearchType = (value, e) => {
    setOrderHistorySearchType(value);
    setSelectDate(e);
  };

  const showOrderHistory = () => {
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        // setOrdersCopy([...data]);
        // setOrderHistoryDetails([...data]);
        setDisplayOrderHistory(true);
      });
  };

  const [selectedOrderHistoryLine, setSelectedOrderHistoryLine] = useState("");
  const showOrderHistoryLine = (orderID) => {
    if (selectedOrderHistoryLine === orderID) {
      setSelectedOrderHistoryLine("");
    } else {
      setSelectedOrderHistoryLine(orderID);
    }
  };

  useEffect(() => {
    localStorage.setItem("dataLength", orderHistoryDetails.length);
  }, [orderHistoryDetails]);

  const searchOrderHistory = async (date, dateValue, row, flag, dateFlag) => {
    let setAuthTokens;
    let data;
    let filteredData;
    let dateFilter = null;
    let startDate;
    let endDate;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    // Search by customer key
    // const searchByCustomerKey = async (customerKey) => {
    //   return db.orders?.where("documentno").equalsIgnoreCase(dateValue?.target?.value).or("customerSearchKey").equalsIgnoreCase(dateValue?.target?.value).limit(20).toArray();
    // };

    // Search by date
    // const searchByDate = async () => {
    //   return db.orders.where("orderTime").equalsIgnoreCase(moment(dateValue[0]).format("YYYY-MM-DD")).limit(20).toArray();
    // };
    const sortOptions = {
      documentno: "desc",
      dateordered: "desc",
      customer: null,
      totalAmount: null,
    };
    const filteredSortOptions = Object.fromEntries(Object.entries(sortOptions).filter(([key, value]) => value !== null));
    if (dateValue?.length > 0) {
      localStorage.setItem("orderType", JSON.stringify([date, dateValue]));
      dateFilter = JSON.stringify(JSON.stringify({ startDate: moment(dateValue[0])?.format("YYYY-MM-DD"), endDate: moment(dateValue[1])?.format("YYYY-MM-DD") }));
      startDate = moment(dateValue[0]).format("YYYY-MM-DD");
      endDate = moment(dateValue[1]).format("YYYY-MM-DD");
    }
    // data = await searchByDate();

    const dateFiltered = `"{\\"DateRange\\":[\\"${startDate}\\",\\"${endDate}\\"]}"`;
    const sortData = JSON.stringify(filteredSortOptions).replace(/"/g, '\\"');
    if (date === "orderDateSearchKey") {
      setLoading(true);
      // setOrderHistoryDetails([])

      const startRow = row;
      const endRow = parseInt(startRow, 10) + 50;

      let orderHistoryData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {salesHistory(
                    q: ${searchHistoryInput.trim() && flag ? `"${searchHistoryInput.trim()}"` : null},
                    filter_by: ${dateFiltered},
                    startRow: "${startRow}",
                    endRow: "${endRow}",
                    sort_by: "${sortData}",
                    tillId: ${dateFlag ? `"${tillValue.cwr_till_id}"` : null}
                  )          {
                  sOrderID
                  created
                  createdby
                  updated
                  updatedby
                  documentno
                  dateordered
                  cwrProductQty
                  orderTime
                  taxamt
                  grosstotal
                  discAmount
                  layAway
                  isReturn
                  paid
                  csUser {
                    user
                  }
                  csBUnit {
                    csBunitId
                    name
                  }
                  csbUnitLocation {
                    fulladdress
                  }
                  cwrB2cCustomer {
                    cwrCustomerId
                    code
                    name
                    mobileNo
                    pincode
                    email
                    retlLoyaltyBalance
                    sCustomer {
                      sCustomerID
                      customerCategory {
                        sCustomerCateforyId
                        value
                        name
                        description
                      }
                    }
                  }
                  saleType {
                    cwrSaletypeId
                    name
                    value
                  }
                  cwrTill {
                    cwrTillID
                    till
                  }
                  tablename
                  fboRder {
                    guests
                  }
                  saLesRep {
                    waitername
                  }
                  finReceiptPlan {
                    finReceiptPlanDetails {
                      amount
                      cwrPaymentmethod {
                        cWRPaymentMethodID
                        finFinancialAccountId
                        finPaymentmethodId
                        integratedPayment
                        isloyalty
                        paymentProvider
                      }
                    }
                  }
                  line {
                    sOrderlineID
                    sOrderId
                    line
                    description
                    qty
                    netlist
                    netunit
                    created
                    linetax
                    unittax
                    linenet
                    linegross
                    grosslist
                    grossstd
                    returnline
                    returnQty
                    discount
                    product {
                      mProductId
                      name
                      value
                      upc
                      hsncode
                      imageurl
                      isManualQty
                      shortDescription
                      returnable
                      returnDays
                    }
                    uom {
                      csUomId
                      name
                    }
                    tax {
                      csTaxID
                      name
                      rate
                    }
                    pricingRule {
                      mPricingrulesId
                      name
                    }
                  }
                }
              }
              `,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });
      if (orderHistoryData?.data?.data?.salesHistory.length === 0) {
        // salesHistory is an empty array
        message.error("No more records!");
        setFiltersFlag(true);
        setLoading(false);
      } else {
        setFilterdDate(dateFiltered);
        let totalQty = 0;
        const arrayData = await Promise.all(
          orderHistoryData?.data?.data?.salesHistory?.map(async (item) => {
            let paymentMethods = [];
            let change = 0;

            // Use Promise.all to ensure all products are processed
            const lineItems = await Promise.all(
              item.line.map(async (pro) => {
                let productObj = await db.products.where("mProductId").equals(pro.product.mProductId).first(); // Use `first()` to get a single product

                const productDefined = {
                  batchno: productObj?.batchno || "",
                  description: productObj?.description || "",
                  discount: pro.discount ?? 0,
                  discountName: pro.pricingRule?.name || "",
                  imageurl: productObj?.imageurl || "",
                  isDecimal: productObj?.isDecimal || false,
                  isManualQty: productObj?.isManualQty || false,
                  isPromoApplicable: productObj?.isPromoApplicable || false,
                  isReturn: false,
                  mBatchId: null,
                  mPricingruleId: null,
                  name: productObj?.name || "",
                  name2: productObj?.name2 || "",
                  nettotal: pro.linegross || 0,
                  primaryOrderLine: null,
                  productId: productObj?.mProductId || "",
                  realPrice: pro.grossstd || 0,
                  listPrice: productObj?.slistprice || 0,
                  sunitprice: productObj?.sunitprice || 0,
                  returnQty: null,
                  salePrice: pro.grossstd || 0,
                  stock: productObj?.onhandQty || 0,
                  tax: productObj?.cTaxId || "",
                  taxCategory: productObj?.taxCategory || "",
                  taxAmount: pro.linetax || 0,
                  taxRate: productObj?.taxRate || 0,
                  uom: productObj?.csUomId || "",
                  uom_name: productObj?.uomName || "",
                  customAttributes: productObj?.customAttributes || [],
                  newCustomAttributes: [],
                  selectedAddons: [],
                  isDecimalQty: productObj?.uomData?.[0]?.decimal === "Y",
                  isQtyDesimal: productObj?.uomData?.[0]?.stdprecision ?? 2,
                  upc: pro.product?.upc || "",
                  value: productObj?.value || "",
                  weight: pro.qty || 0,
                  order: "N",
                  productionCenter: productObj?.productionCenter || "",
                  shortDescription: productObj?.shortDescription || "",
                  hsncode: productObj?.hsncode || "",
                  csBunitId: productObj?.csBunitId || "",
                  mProductCategoryId: productObj?.mProductCategoryId || "",
                  productManufacturerId: productObj?.productManufacturerId || "",
                  productBrandId: productObj?.productBrandId || "",
                  productCategoryName: productObj?.productCategoryName || "",
                  productAddons: productObj?.productAddons || [],
                  batchedProduct: productObj?.batchedProduct || false,
                  batchedForSale: productObj?.batchedForSale || false,
                  batchedForStock: productObj?.batchedForStock || false,
                  multiPrice: productObj?.multiPrice || [],
                  shelfLife: productObj?.shelfLife || 0,
                  lineId: uuidv4().replace(/-/g, "").toUpperCase(),
                };

                // Merge productDefined into pro
                return Object.assign(pro, productDefined);
              })
            );

            let paidAmount = 0;
            item?.finReceiptPlan?.[0]?.finReceiptPlanDetails?.forEach((ele) => {
              const paymentMethod = tillDataPaymentMethods.find((pi) => pi.finPaymentmethodId === ele.cwrPaymentmethod.finPaymentmethodId);

              if (paymentMethod && ele.amount > 0) {
                const clonedPaymentMethod = { ...paymentMethod, amount: ele.amount, advancePayment: ele.amount };
                // paymentMethods.push(clonedPaymentMethod);
                paidAmount += ele.amount;
              } else if (ele.amount < 0) {
                change = ele.amount;
              }
            });

            const obj = {
              change,
              items: lineItems, // This will now contain resolved objects, not Promises
              cardPaymentData: {},
              originalPrice: item.grosstotal,
              customer: item.cwrB2cCustomer,
              documentno: item.documentno,
              total: item.grosstotal.toFixed(2),
              discount: item.discAmount || 0,
              tax: item.taxamt || 0,
              orderTime: item.orderTime,
              orderDate: item.dateordered,
              totalQty: item.cwrProductQty,
              payments: paymentMethods,
              roundOff: 0,
              sOrderID: item.sOrderID,
              key: item.documentno,
              salesHistory: "Y",
              status: "Success",
              isSynced: 0,
              creditAmount: 0,
              createdBy: "",
              layAway: item.layAway,
              isReturn: item.isReturn,
              paid: paidAmount,
              advancePayment: paidAmount,
            };

            return obj;
          })
        );

        if (row === null) {
          setOrderHistoryDetails((prevData) => [...prevData, ...arrayData]);
          setOrdersCopy((prevData) => [...prevData, ...arrayData]);
        } else {
          setOrderHistoryDetails((prevData) => [...prevData, ...arrayData]);
          setOrdersCopy((prevData) => [...prevData, ...arrayData]);
        }
        setFiltersFlag(dateFlag ? false : true);
        setLoading(false);
      }
    } else {
      setLoading(true);
      setOrderHistoryDetails([]);
      // if (orderHistoryDetails.length > 0) {
      prevHistoryRef.current = orderHistoryDetails;
      // filteredData = orderHistoryDetails.filter(item => {
      //   // Check if item.documentno matches dateValue
      //   const documentNoMatch = item.documentno === dateValue;
      //   // Check if item.customer.name matches dateValue
      //   const nameMatch = item.customer.name === dateValue;
      //   // Check if item.customer.mobileNo matches dateValue
      //   const mobileNoMatch = item.customer.mobileNo === dateValue;
      //   // Return true if any of the conditions match
      //   return documentNoMatch || nameMatch || mobileNoMatch;
      // });
      // if(filteredData?.length>0){
      //   setOrderHistoryDetails(filteredData);
      // } else {
      let orderHistoryData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {salesHistory(
                  q: "${dateValue.trim()}",
                  filter_by: ${flag ? filteredDate : null},
                  startRow: "0",
                  endRow: "100",
                  sort_by: "${sortData}",
                  tillId:""
                )          {
                sOrderID
                created
                createdby
                updated
                updatedby
                documentno
                dateordered
                cwrProductQty
                orderTime
                taxamt
                grosstotal
                discAmount
                layAway
                isReturn
                paid
                csUser {
                  user
                }
                csBUnit {
                  csBunitId
                  name
                }
                csbUnitLocation {
                  fulladdress
                }
                cwrB2cCustomer {
                  cwrCustomerId
                  code
                  name
                  mobileNo
                  pincode
                  email
                  retlLoyaltyBalance
                  sCustomer {
                    sCustomerID
                    customerCategory {
                      sCustomerCateforyId
                      value
                      name
                      description
                    }
                  }
                }
                saleType {
                  cwrSaletypeId
                  name
                  value
                }
                cwrTill {
                  cwrTillID
                  till
                }
                tablename
                fboRder {
                  guests
                }
                saLesRep {
                  waitername
                }
                finReceiptPlan {
                  finReceiptPlanDetails {
                    amount
                    cwrPaymentmethod {
                      cWRPaymentMethodID
                      finFinancialAccountId
                      finPaymentmethodId
                      integratedPayment
                      isloyalty
                      paymentProvider
                    }
                  }
                }
                line {
                  sOrderlineID
                  sOrderId
                  line
                  description
                  qty
                  netlist
                  netunit
                  created
                  linetax
                  unittax
                  linenet
                  linegross
                  grosslist
                  grossstd
                  returnline
                  returnQty
                  discount
                  product {
                    mProductId
                    name
                    value
                    upc
                    hsncode
                    imageurl
                    isManualQty
                    shortDescription
                    returnable
                    returnDays
                  }
                  uom {
                    csUomId
                    name
                  }
                  tax {
                    csTaxID
                    name
                    rate
                  }
                  pricingRule {
                    mPricingrulesId
                    name
                  }
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      let totalQty = 0;
      let arrayData = [];

      orderHistoryData?.data?.data?.salesHistory?.map((item) => {
        let paymentMethods = [];
        let change = 0;
        item.line.map((pro) => {
          pro.name = pro.product.name;
          pro.weight = pro.qty;
          pro.salePrice = pro.linegross;
          pro.nettotal = pro.linegross;
          pro.realPrice = pro.grossstd;
          pro.taxAmount = pro.linetax;
          pro.lineId = uuidv4().replace(/-/g, "").toUpperCase();
          pro.discount = pro.discount === null || pro.discount === undefined ? 0 : pro.discount;
        });
        let paidAmount = 0;
        item?.finReceiptPlan[0]?.finReceiptPlanDetails.forEach((ele) => {
          let payment_method = tillDataPaymentMethods.find((pi) => pi.finPaymentmethodId === ele.cwrPaymentmethod.finPaymentmethodId);
          if (payment_method && parseInt(ele.amount) > 0) {
            let cloned_payment_method = { ...payment_method }; // Create a deep copy
            cloned_payment_method.amount = ele.amount;
            cloned_payment_method.advancePayment = ele.amount;
            paidAmount = paidAmount + ele.amount;
            // paymentMethods.push(cloned_payment_method);
          } else if (parseInt(ele.amount) < 0) {
            change = parseInt(ele.amount);
          }
        });

        const obj = {
          change,
          items: item.line,
          cardPaymentData: {},
          originalPrice: item.grosstotal,
          customer: item.cwrB2cCustomer,
          documentno: item.documentno,
          total: item.grosstotal.toFixed(2),
          discount: item.discAmount,
          sOrderID: item.sOrderID,
          tax: item.taxamt,
          orderTime: item.orderTime,
          orderDate: item.dateordered,
          totalQty: item.cwrProductQty,
          payments: paymentMethods,
          roundOff: 0,
          key: item.documentno,
          salesHistory: "Y",
          status: "Success",
          isSynced: 0,
          creditAmount: 0,
          createdBy: "",
          layAway: item.layAway,
          isReturn: item.isReturn,
          paid: paidAmount,
        };
        if (obj.items.length > 0) {
          arrayData.push(obj);
        }
        return obj;
      });
      // setOrdersCopy([...arrayData]);
      setOrderHistoryDetails(arrayData);
      setFiltersFlag(dateFlag ? false : true);
    }
    setLoading(false);

    setOrderHistoryInput("");
  };

  // parked block

  const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
  const initialParkedList = storedParkedList ? storedParkedList : [];
  const [displayParkedBillModal, setDisplayParkedBillModal] = useState(false);
  const [parkedList, setParkedList] = useState(initialParkedList);
  const [filterdParkedList, setFilterdParkedList] = useState(initialParkedList);

  useEffect(() => {
    setFilterdParkedList(parkedList);
  }, [parkedList]);

  const discardParkedBill = async (record) => {
    upsertPOSLog(record.parkedCart, "DPO");
    let array = [];
    filterdParkedList.map((item) => {
      if (item.key !== record.key) {
        array.push(item);
      }
    });
    localStorage.setItem("parkedList", JSON.stringify(array));
    setParkedList([...array]);
    setFilterdParkedList(array);
    const tillSession = JSON.parse(localStorage.getItem("tillSession"));
    const tillSessionId = tillSession.tillSessionId;
    let cartToDb = record.parkedCart;
    cartToDb.orderTime = timeStamp();
    cartToDb.createdBy = tillData.tillAccess.csUserId;
    cartToDb.orderType = orderType?.cwrSaletype?.cwrSaletypeId;
    cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
    cartToDb.tillSessionId = tillSessionId;
    cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
    cartToDb.isSynced = 0;
    cartToDb.syncAttempts = 0;
    cartToDb.customerSearchKey = cart.customer.code;
    cartToDb.total = 0;
    cartToDb.totalQty = 0;
    cartToDb.tax = 0;
    cartToDb.isReturn = false;
    cartToDb.items.forEach((item) => {
      item.salePrice = 0;
      item.sunitprice = 0;
      item.netStd = 0;
      item.nettotal = 0;
      item.unitTax = 0;
      item.taxAmount = 0;
      item.weight = 0;
      item.unitPrice = 0;
      item.netList = 0;
      item.discount = 0;
      item.isReturn = false;
      item.linetax = 0;
      item.linenet = 0;
      item.linegross = 0;
      item.netunit = 0;
      item.listPrice = 0;
      item.grossunit = 0;
      item.grossstd = 0;
      item.grosslist = 0;
      item.realPrice = 0;
    });
    if (record.parkedCart.type === "Layaway") {
      setDocumnetSequence(documentSequence);
    } else {
      await db.orders.add(cartToDb);
    }
  };

  const openDisplayParkedBillModal = (param, record) => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        autoFocusButton: null,
        onOk() {
          parkBill(param, record);
          const productSearchInput = document.getElementById("sm-product-search");
          setTimeout(() => {
            productSearchInput.focus();
          }, 600);
          // message.info("Please wait...");
          // setTimeout(() => {
          //   setDisplayParkedBillModal(true);
          // }, 2000);
        },
        onCancel() {
          const productSearchInput = document.getElementById("sm-product-search");
          setTimeout(() => {
            productSearchInput?.focus();
          }, 300);
          setDisplayParkedBillModal(true);
        },
      });
    } else {
      setDisplayParkedBillModal(true);
    }
  };

  const parkedListRef = useRef(initialParkedList);
  useEffect(() => {
    parkedListRef.current = parkedList;
  }, [parkedList]);

  const parkBill = (value, record) => {
    const presentParkedList = value === "parkKey" ? parkedListRef.current : parkedList;
    if (cart.parked !== "Y" && cart.layAway !== "Y") {
      const newDocumentSequence = documentSequence + 1;
      localStorage.setItem("documentSequence", newDocumentSequence);
      setDocumnetSequence(newDocumentSequence);
      deleteReceipt();
      if (value === "retrieve") {
        selectParkedBill(record, "management");
      }
    } else {
      if (value === "retrieve") {
        selectParkedBill(record, "management");
      } else {
        let cartObj = {
          items: [],
          total: 0,
          tax: 0,
          discount: 0,
          paid: 0,
          change: 0,
          totalQty: 0,
          roundOff: 0,
          payments: [],
          redemptionPoints: 0,
          accumulationPoints: 0,
          creditAmount: 0,
          sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
          referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
          giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
          couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
          customer: defaultCustomer,
          salesRepId: null,
          cardPaymentData: {},
          documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}`,
        };
        setCart(cartObj);
        localStorage.setItem("cartObj", JSON.stringify(cartObj));
      }
    }
    cart.parked = "Y";
    const presentCart = cart;
    const presentTimeStamp = timeStamp();
    const parkedBill = {
      parkedCart: presentCart,
      parkedTime: presentTimeStamp,
      parkedDocNo: cart.documentno,
      parkedOrderID: cart.items[0].sOrderReturnId,
      parkedBillId: uuidv4().replace(/-/g, "").toUpperCase(),
    };
    presentParkedList.push(parkedBill);
    localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
    setParkedList([...presentParkedList]);
    {
      message.success(`${t("bill_parking_successful")}`);
    }
  };

  const selectParkedBill = (item, fieldName) => {
    if (fieldName === "management") {
      const listItemIndex = parkedList.findIndex((bill) => bill.parkedBillId === item.parkedBillId);
      let selectedParkedBill = parkedList[listItemIndex].parkedCart;
      selectedParkedBill.isRetrived = "Y";
      const presentParkedList = parkedList;
      presentParkedList.splice(listItemIndex, 1);
      localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
      setParkedList([...presentParkedList]);
      setCart(selectedParkedBill);
      localStorage.setItem("cartObj", JSON.stringify(selectedParkedBill));
      setDisplayParkedBillModal(false);
      setManagementScreenShow(false);
    } else {
      const listItemIndex = parkedList.findIndex((bill) => bill.parkedBillId === item.parkedBillId);
      let selectedParkedBill = parkedList[listItemIndex].parkedCart;
      selectedParkedBill.isRetrived = "Y";
      const presentParkedList = parkedList;
      presentParkedList.splice(listItemIndex, 1);
      localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
      setParkedList([...presentParkedList]);
      setCart(selectedParkedBill);
      localStorage.setItem("cartObj", JSON.stringify(selectedParkedBill));
      setDisplayParkedBillModal(false);
    }
  };

  const selectLayAwayOrder = (item, fieldName) => {
    const itemWithNumberTotal = {
      ...item,
      total: parseFloat(item.total),
    };
    setShowPaymentMethods(true);
    localStorage.setItem("cartObj", JSON.stringify(itemWithNumberTotal));
    setCart(itemWithNumberTotal);
  };

  const [parkedBillSearchInput, setParkedBillSearchInput] = useState("");
  const [salesHistoryCustomerSearchInput, setSalesHistoryCustomerSearchInput] = useState("");
  const [salesHistoryDocumentNoSearchInput, setSalesHistoryDocumentNoSearchInput] = useState("");

  const handleParkedBillSearchInput = (e) => {
    setParkedBillSearchInput(e.target.value);
    searchParkedBill(e.target.value);
  };

  const searchParkedBill = (key) => {
    const lowercaseKey = key.toLowerCase();
    const filteredBills = parkedList.filter((list) => {
      const lowercaseCustomer = list.customer.toLowerCase();
      const lowercaseParkedDocNo = list.parkedDocNo.toLowerCase();
      return lowercaseCustomer.includes(lowercaseKey) || lowercaseParkedDocNo.includes(lowercaseKey);
    });

    // Return the filtered records
    if (filteredBills.length > 0) {
      setFilterdParkedList(filteredBills);
    }
    // let tempArray = [];
    // let tempKey = typeof key === "string" ? key : "";
    // parkedList.map((list) => {
    //   let listKey = list.parkedCart.customer.name.toLowerCase();

    //   if (listKey.includes(tempKey?.toLowerCase())) {
    //     tempArray.push(list);
    //   }
    // });

    // if (tempArray.length > 0) {
    //   setFilterdParkedList([...tempArray]);
    // } else {
    //   setFilterdParkedList(parkedList);
    // }
  };

  const closeParkedBillModal = () => {
    setDisplayParkedBillModal(false);
    setParkedBillSearchInput("");
    setFilterdParkedList([...parkedList]);
  };

  // PARKED BILL BLOCK END

  // prodcut add ons start

  const [displayAddOnSelection, setDisplayAddOnSelection] = useState(false);
  const [addOnsList, setAddOnsList] = useState({
    requiredList: [],
    optionsList: [],
  });
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [isUpdatingAddon, setIsUpdatingAddon] = useState(false);

  const addDefinedProductWithAddons = (productObjs, upc, batchno, mBatchId, price) => {
    console.log("Adding Product with Addons:", productObjs);
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      const originalPrice = price - (price - price * (100 / (100 + productObj.taxRate)));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }

    addProductToCart(
      productObj,
      productObj.qty,
      false,
      cart,
      setCart,
      setSelectedProductInCart,
      deleteCart,
      processTotalManualDiscount,
      setLoader,
      salesRepresentDefaultLine,
      tillData,
      cartRef,
      productsCopy,
      salesRepresent,
      orderType,
      processBillDiscounts,
      undefined,
      setSelectedRowKeys,
      setOrderTimeDetails,
      setDisplayAddOnSelection,
      displayAddOnSelection,
      handleAddOnModal
    );
    setSelectedAddons([]);
  };

  const handleAddOnModal = (record, qty) => {
    console.log("Record for add-on modal:", record);
    setDisplayAddOnSelection(true);
    record.qty = qty;
    setSelectedProduct(record);

    const requiredListFilter = _.filter(record.productAddons, (item) => item.mAddonGroup.minqty === 1);
    const optionsListFilter = _.filter(record.productAddons, (item) => item.mAddonGroup.minqty === 0);
    setAddOnsList({
      requiredList: record.productAddons,
      optionsList: record.productAddons,
    });
  };

  const handleAddOnValue = (e, fieldName) => {
    let newSelectedAddons = [...selectedAddons];
    const indexValue =
      fieldName === "addOnRadio"
        ? _.findIndex(newSelectedAddons, (item) => item.mAddonGroup.mAddonGroupId === e.target.value.mAddonGroup.mAddonGroupId)
        : _.findIndex(newSelectedAddons, (item) => item === e.target.value);
    if (indexValue !== -1) {
      if (e.target.checked) {
        newSelectedAddons[indexValue] = e.target.value;
      } else {
        newSelectedAddons.splice(indexValue, 1);
      }
    } else {
      newSelectedAddons = [...selectedAddons, e.target.value];
    }
    setSelectedAddons(newSelectedAddons);
  };

  const handleQty = (fieldName) => {
    let newSelectedProduct = {
      ...selectedProduct,
      weight: selectedProduct?.weight || 1,
    };
    if (fieldName === "plus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) + 1;
    }
    if (fieldName === "minus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) - 1;
      if (newSelectedProduct.weight <= 1) {
        newSelectedProduct.weight = 1;
      }
    }
    setSelectedProduct({
      ...newSelectedProduct,
    });
  };

  const handleAdd = async () => {
    console.log("selected addon");
    await addDefinedProductWithAddons(
      {
        ...selectedProduct,
        sProductID: uuidv4().replace(/-/g, "").toUpperCase(),
        selectedAddons: selectedAddons || [],
      },
      selectedProduct.upc,
      null,
      null,
      selectedProduct.sunitprice
    );
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
    setFilterDrawer(false);
  };

  const handleAddOnModalClose = () => {
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
  };

  // product add ons end

  //// CENTER BUTTON BLOCK END ////

  //// CART OPERATIONS START ////

  // DEFAULT CART START
  const [cart, setCart] = useState({
    items: [],
    couponInput: [],
    advancePayment: 0,
    total: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    change: 0,
    totalQty: 0,
    roundOff: 0,
    payments: [],
    redemptionPoints: 0,
    accumulationPoints: 0,
    creditAmount: 0,
    sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
    referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
    giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
    couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
    customer: defaultCustomer,
    salesRepId: null,
    cardPaymentData: {},
    documentno: `${tillData.tillAccess.cwrTill.prefix}${tillDocumentSequence + 1}`,
  });
  // DEFAULT CART END

  // BARCODE READER BLOCK START
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const padleft = (nr, n, str) => {
    return Array(n - String(nr).length + 1).join(str || "0") + nr;
  };

  const decTobin = (dec) => {
    return (dec >>> 0).toString(2);
  };

  const onBarcodeInput = (data, flag) => {
    let chackBarcodeFlag = false;
    if (data?.trim() !== "" && data !== null) {
      barcodeScaner(
        data,
        tillData,
        tillLayout,
        addDefinedProduct,
        setBatchSetAvailable,
        setDisplayBatchSelection,
        setLayoutType,
        setIsProductsVisible,
        setProductsData,
        chackBarcodeFlag,
        setSelectedProductInCart,
        setProductSearchInput,
        cart
      );
    }
  };

  const addDefinedProduct = (productObjs, upc, batchno, mBatchId, price, checkWeight, modifiedQty, batchedItem, modifiedPrice) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      // prettier-ignore
      const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }
    let productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mPricingruleId: null,
      name: productObj.name,
      name2: productObj.name2,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      listPrice: productObj.slistprice,
      sunitprice: productObj.sunitprice,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxCategory: productObj.taxCategory,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      customAttributes: productObj.customAttributes,
      newCustomAttributes: [],
      selectedAddons: [],
      isDecimalQty: productObj.uomData[0]?.decimal === "Y" ? true : false,
      isQtyDesimal: productObj.uomData[0]?.stdprecision ? productObj.uomData[0]?.stdprecision : 2,
      upc: upc,
      value: productObj.value,
      weight: 0,
      order: "N",
      productionCenter: productObj.productionCenter,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
      productCategoryName: productObj?.productCategoryName || "",
      productAddons: productObj?.productAddons || [],
      batchedProduct: productObj.batchedProduct,
      batchedForSale: productObj.batchedForSale,
      batchedForStock: productObj.batchedForStock,
      multiPrice: productObj.multiPrice,
      shelfLife: productObj.shelfLife,
    };
    let cartItems = JSON.parse(localStorage.getItem("cartObj"))?.items;
    let filterItem = cartItems?.filter((item) => item.productId === productDefined.productId);
    if (filterItem?.length === 1 && filterItem?.selectedAddons?.length <= 0) {
      productDefined.lineId = filterItem[0].lineId;
    }
    setSelectedProductInCart(productDefined);
    if (checkWeight === true) {
      let index = cart.items.findIndex((item) => item.value === productDefined.value);
      if (batchedItem === true) {
        modifiedQty = cart.items > 0 ? cart.items[index].weight + 1 : 1;
      }
      const productSearchInput = document.getElementById("sm-product-search");
      if (productSearchInput) {
        productSearchInput.blur();
      }
      addProduct(productDefined, modifiedQty, false, modifiedPrice);
    } else {
      checkIsManualWeight(productDefined);
    }
    setFilterDrawer(false);
    setIsProductsVisible(false);
    setQtyNumberFlag(0);
  };

  const selectProductToCart = (data) => {
    checkIsManualWeight(data);
    setDisplayBatchSelection(false);
  };
  // BARCODE READER BLOCK END

  // PRODUCT WEIGHT MODAL START
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});
  const [stockList, setStockList] = useState([]);

  const onProductModalChangeWeight = (event) => {
    setProductWeightModalInput(event.target.value);
  };

  const handleWeightManual = (value) => {
    if (productWeightModalInput === "" && value === "x") {
      setProductWeightModalInput("");
    } else if (value === "x") {
      setProductWeightModalInput(`${productWeightModalInput.toString().substring(0, productWeightModalInput.toString().length - 1)}`);
    } else {
      setProductWeightModalInput(`${productWeightModalInput}${value}`);
    }
  };

  const pickProduct = (obj) => {
    if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
      if (obj.mBatch.length === 1) {
        addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
      } else {
        const productSet = [];
        const localObj = obj;
        for (let i = 0; i < obj.mBatch.length; i += 1) {
          const batchItem = { ...localObj.mBatch[i] };
          const obj = { ...localObj };
          if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
            // prettier-ignore
            const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
            const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
            batchItem.price = taxedPrice;
            obj.cTaxId = obj.contraTaxId;
            obj.taxRate = obj.contraRate;
          }
          const productDefined = {
            batchno: batchItem.batchno,
            description: obj.description,
            discount: 0,
            discountName: "",
            imageurl: obj.imageurl,
            isDecimal: obj.isDecimal,
            isManualQty: obj.isManualQty,
            isPromoApplicable: obj.isPromoApplicable,
            isReturn: false,
            mBatchId: batchItem.mBatchId,
            mPricingruleId: null,
            name: obj.name,
            name2: obj.name2,
            nettotal: 0,
            primaryOrderLine: null,
            productId: obj.mProductId,
            realPrice: batchItem.price,
            listPrice: obj.slistprice,
            sunitprice: obj.sunitprice,
            returnQty: null,
            salePrice: batchItem.price,
            mrpPrice: batchItem.listPrice,
            stock: obj.onhandQty,
            tax: obj.cTaxId,
            taxCategory: obj.taxCategory,
            customAttributes: obj.customAttributes || [],
            newCustomAttributes: [],
            selectedAddons: [],
            taxAmount: 0,
            taxRate: obj.taxRate,
            uom: obj.csUomId,
            uom_name: obj.uomName,
            isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
            isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
            upc: batchItem.upc,
            value: obj.value,
            weight: 0,
            order: "N",
            shortDescription: obj.shortDescription,
            hsncode: obj.hsncode,
            csBunitId: obj.csBunitId,
            mProductCategoryId: obj.mProductCategoryId,
            productManufacturerId: obj.productManufacturerId,
            productBrandId: obj.productBrandId,
            batchedProduct: obj.batchedProduct,
            batchedForSale: obj.batchedForSale,
            batchedForStock: obj.batchedForStock,
            multiPrice: obj.multiPrice,
            shelfLife: obj.shelfLife,
          };
          productSet.push(productDefined);
        }
        setBatchSetAvailable([...productSet]);
        setDisplayBatchSelection(true);
      }
    } else {
      addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
    }
  };

  const checkIsManualWeight = (prod) => {
    if (prod.isManualQty && posConfig.showWeightPopup === "Y") {
      setDisplayManualQtyWeightInput(true);
      setCurrentWeightSelectedProduct(prod);
    } else {
      addProduct(prod, 1);
    }
  };

  const addManualWeightToProduct = () => {
    let inputValidator = new RegExp(/^[0-9]*$/);
    if (currentWeightSelectedProduct.isDecimal) inputValidator = new RegExp(/^[0-9]\d*(\.\d+)?$/);
    if (inputValidator.test(productWeightModalInput) && parseFloat(productWeightModalInput) > 0) {
      setDisplayManualQtyWeightInput(false);
      addProduct(currentWeightSelectedProduct, productWeightModalInput);
      setTimeout(() => {
        setProductWeightModalInput("");
        setCurrentWeightSelectedProduct({});
      }, 500);
    } else {
      console.warn("Invalid weight input: ", productWeightModalInput);
      message.warning("Invalid input value");
    }
  };

  // PRODUCT WEIGHT MODAL END

  // PRODUCT FILTER START
  const [isProductsFilter, setIsProductsFilter] = useState(() => (posConfig.defaultSearchScreen === "Category Search" ? true : false));
  const [allProductCategories, setAllProductCategories] = useState([]);
  const [selectedProductBrand, setSelectedProductBrand] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("allProducts");
  const [selectCategotyList, setSelectCategotyList] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const prevProductsListRef = useRef(null);
  const prevHistoryRef = useRef(orderHistoryDetails);
  const [productsCopy, setProductsCopy] = useState([]);

  useEffect(() => {
    db.AllProductCategories.toArray().then((res) => {
      setAllProductCategories(res);
    });
    if (!localStorage.getItem("cartObj")) {
      localStorage.setItem("cartObj", JSON.stringify(cart));
    }
  }, []);

  useEffect(() => {
    getCategoryProducts(selectedProductCategory);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectProductCategory = (category) => {
    if (selectedProductCategory !== category) {
      setSelectedProductCategory(category);
    }
  };

  const filterProducts = (brands, categories) => {
    let filteredItems = productsCopy;

    if (brands?.length > 0 || categories?.length > 0) {
      filteredItems = productsCopy.filter(
        (item) => (brands?.length === 0 || brands?.includes(item.brandId)) && (categories?.length === 0 || categories?.includes(item.mProductCategoryId))
      );
    }

    // Update the productsList with filtered items
  };

  const restaurantProductCategory = async (categorie) => {
    await db.products
      .where("mProductCategoryId")
      .equals(categorie)
      .toArray()
      .then(async (response) => {
        setProductsData(response);
      });
  };

  // Debounced handler for brand checkbox changes
  const debouncedBrandChange = debounce((brandId) => {
    const isSelected = selectedProductBrand?.includes(brandId);
    let updatedSelectedBrands = [];

    if (isSelected) {
      // Brand is already selected, remove it from the selectedProductBrand array
      updatedSelectedBrands = selectedProductBrand?.filter((id) => id !== brandId);
    } else {
      // Brand is not selected, add it to the selectedProductBrand array
      let tempBrand = selectedProductBrand || [];
      updatedSelectedBrands = [...tempBrand, brandId];
    }

    setSelectedProductBrand(updatedSelectedBrands || []); // Ensure it's always an array

    // Filter products based on selected brands and categories
    filterProducts(updatedSelectedBrands || [], selectCategotyList);
  }, 300);

  const handleBrandCheckboxChange = (brandId) => {
    debouncedBrandChange(brandId);
  };

  // Debounced handler for category checkbox changes
  const debouncedCategoryChange = debounce((checkedValues) => {
    setSelectCategotyList(checkedValues);
    // Filter products based on selected brands and categories
    filterProducts(selectedProductBrand, checkedValues);
  }, 300); // Adjust debounce delay as needed
  const handleCategoryCheckboxChange = (checkedValues) => {
    debouncedCategoryChange(checkedValues);
  };

  const handleSelectProduct = () => {
    prevProductsListRef.current = [];
    const lowerCaseSearchInput = productSearchInput.toLowerCase();
    const filteredProducts = productsCopy.filter(
      (product) =>
        product?.name?.toLowerCase().includes(lowerCaseSearchInput) ||
        product?.batchIndex === lowerCaseSearchInput ||
        product?.upcIndex === lowerCaseSearchInput ||
        product?.value === lowerCaseSearchInput ||
        product?.upc === lowerCaseSearchInput
    );
    if (filteredProducts.length === 0) {
      {
        message.info(`${t("product_search_category_error")}`);
      }
    } else {
    }
  };

  useEffect(() => {
    if (selectedProductCategory !== "all") {
      getCategoryProducts();
    }
  }, [selectedProductCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const getMoreProducts = () => {
    if (productSearchInput === "") {
      getCategoryProducts(selectedProductCategory);
    }
  };

  const getCategoryProducts = () => {
    // if (selectedProductCategory === "allProducts") {
    //   db.products.toArray().then((productsFetched) => {
    //     const additionalProductsFetched = productsList.concat(productsFetched);
    //     additionalProductsFetched.map((ele) => {
    //       cart.items.map((item) => {
    //         if (ele.mProductId === item.productId) {
    //           ele.selected = "Y";
    //         }
    //       });
    //     });
    //     setProductsList([...additionalProductsFetched.slice(0, 50)]);
    //     setProductsCopy([...additionalProductsFetched]);
    //   });
    // } else {
    //   db.products
    //     .where("mProductCategoryId")
    //     .equalsIgnoreCase(selectedProductCategory)
    //     .offset(productsList.length)
    //     .limit(50)
    //     .toArray()
    //     .then((productsFetched) => {
    //       const additionalProductsFetched = productsList.concat(productsFetched);
    //       setProductsList([...additionalProductsFetched]);
    //     });
    // }
  };
  // PRODUCT FILTER END

  // PRODUCT SEARCH START
  const [isSearchProducts, setIsSearchProducts] = useState(() =>
    posConfig.defaultSearchScreen === "Product Search" || posConfig.defaultSearchScreen === "Category Search" || posConfig.defaultSearchScreen === undefined ? true : false
  );
  const [isProductsVisible, setIsProductsVisible] = useState(false);
  const [productSearchInput, setProductSearchInput] = useState("");
  const [numb, setNumb] = useState(0);
  const [qtyNumberFlag, setQtyNumberFlag] = useState(0);

  /* useEffect(() => {
      if (productSearchInput !== "" && productSearchInput.length >= 3) {
        getSearchedProducts();
      }
    }, [productSearchInput]); */ // eslint-disable-line

  const getProductData = (data) => {
    return new Promise(function (resolve) {
      db.products
        .where("mProductId")
        .equalsIgnoreCase(data)
        .toArray()
        .then((product) => {
          if (product.length > 0) {
            const obj = { ...product[0] };
            if (obj.overRideTax === "Y" && obj.sunitprice <= obj.overRideCondition) {
              // prettier-ignore
              const originalPrice = obj.sunitprice - (obj.sunitprice - (obj.sunitprice * (100 / (100 + obj.taxRate))));
              const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
              obj.sunitprice = taxedPrice;
              obj.cTaxId = obj.contraTaxId;
              obj.taxRate = obj.contraRate;
            }
            const productDefined = {
              batchno: null,
              description: obj.description,
              discount: 0,
              discountName: "",
              imageurl: obj.imageurl,
              isDecimal: obj.isDecimal,
              isManualQty: obj.isManualQty,
              isPromoApplicable: false,
              isReturn: true,
              mBatchId: null,
              mPricingruleId: null,
              name: obj.name,
              name2: obj.name2,
              nettotal: 0,
              primaryOrderLine: null,
              productId: obj.mProductId,
              realPrice: obj.sunitprice,
              listPrice: obj.slistprice,
              sunitprice: obj.sunitprice,
              returnQty: null,
              salePrice: obj.sunitprice,
              mrpPrice: obj.sunitprice,
              stock: obj.onhandQty,
              tax: obj.cTaxId,
              taxAmount: 0,
              taxRate: obj.taxRate,
              taxCategory: obj.taxCategory,
              uom: obj.csUomId,
              customAttributes: obj.customAttributes,
              newCustomAttributes: [],
              selectedAddons: [],
              uom_name: obj.uomName,
              isDecimalQty: obj.uomData?.length > 0 ? (obj.uomData[0].decimal === "Y" ? true : false) : false,
              isQtyDesimal: obj.uomData?.length > 0 ? obj.uomData[0].stdprecision : 2,
              upc: obj.upc,
              value: obj.value,
              weight: 0,
              shortDescription: obj.shortDescription,
              hsncode: obj.hsncode,
              csBunitId: obj.csBunitId,
              mProductCategoryId: obj.mProductCategoryId,
              productManufacturerId: obj.productManufacturerId,
              productBrandId: obj.productBrandId,
              batchedProduct: obj.batchedProduct,
              batchedForSale: obj.batchedForSale,
              batchedForStock: obj.batchedForStock,
              multiPrice: obj.multiPrice,
              shelfLife: obj.shelfLife,
            };
            resolve(productDefined);
          } else {
            {
              message.warning(`${t("product_search_error")}`);
            }
            resolve(null);
          }
        });
    });
  };

  const getSearchedProducts = () => {
    db.products
      .filter(
        (product) =>
          (typeof product.name === "string" && product.name.toLowerCase().includes(productSearchInput.toLowerCase())) ||
          (typeof product.value === "string" && product.value.toLowerCase() === productSearchInput.toLowerCase())
      )
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        if (productsFetched.length > 1) {
          setIsProductsVisible(true);
        } else if (productsFetched.length === 1) {
          const obj = productsFetched[0];
          if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
            const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === productSearchInput.toLowerCase());
            if (batchProductIndex >= 0 && obj.multiPrice === "N") {
              addDefinedProduct(
                obj,
                obj.mBatch[batchProductIndex].upc,
                obj.mBatch[batchProductIndex].batchno,
                obj.mBatch[batchProductIndex].mBatchId,
                obj.mBatch[batchProductIndex].price
              );
              setNumb(0);
              paymentModalInputRef.current.focus();
            } else if (obj.mBatch.length === 1) {
              addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
              paymentModalInputRef.current.focus();
              setNumb(0);
            } else {
              // let upcKeys = 0;
              const productSet = [];
              const localObj = obj;
              for (let i = 0; i < obj.mBatch.length; i += 1) {
                const batchItem = { ...localObj.mBatch[i] };
                const obj = { ...localObj };
                if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                  // prettier-ignore
                  const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                  const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                  batchItem.price = taxedPrice;
                  obj.cTaxId = obj.contraTaxId;
                  obj.taxRate = obj.contraRate;
                }
                const productDefined = {
                  batchno: batchItem.batchno,
                  description: obj.description,
                  discount: 0,
                  discountName: "",
                  imageurl: obj.imageurl,
                  isDecimal: obj.isDecimal,
                  isManualQty: obj.isManualQty,
                  isPromoApplicable: obj.isPromoApplicable,
                  isReturn: false,
                  mBatchId: batchItem.mBatchId,
                  mPricingruleId: null,
                  name: obj.name,
                  name2: obj.name2,
                  nettotal: 0,
                  primaryOrderLine: null,
                  productId: obj.mProductId,
                  realPrice: obj.cTaxId,
                  listPrice: obj.slistprice,
                  sunitprice: obj.sunitprice,
                  returnQty: null,
                  salePrice: obj.cTaxId,
                  mrpPrice: batchItem.listPrice,
                  stock: obj.onhandQty,
                  taxCategory: obj.taxCategory,
                  tax: obj.cTaxId,
                  taxAmount: 0,
                  taxRate: obj.taxRate,
                  uom: obj.csUomId,
                  customAttributes: obj.customAttributes,
                  newCustomAttributes: [],
                  selectedAddons: [],
                  uom_name: obj.uomName,
                  isDecimalQty: obj?.uomData[0]?.decimal === "Y" ? true : false,
                  isQtyDesimal: obj?.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                  upc: batchItem.upc,
                  value: obj.value,
                  weight: 0,
                  shortDescription: obj.shortDescription,
                  hsncode: obj.hsncode,
                  csBunitId: obj.csBunitId,
                  mProductCategoryId: obj.mProductCategoryId,
                  productManufacturerId: obj.productManufacturerId,
                  productBrandId: obj.productBrandId,
                  batchedProduct: obj.batchedProduct,
                  batchedForSale: obj.batchedForSale,
                  batchedForStock: obj.batchedForStock,
                  multiPrice: obj.multiPrice,
                  shelfLife: obj.shelfLife,
                };
                // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                productSet.push(productDefined);
              }
              setBatchSetAvailable([...productSet]);
              setDisplayBatchSelection(true);
            }
          } else {
            addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
            paymentModalInputRef?.current?.focus();
            setNumb(0);
          }
        } else {
          {
            message.warning(`${t("product_search_error")}`);
          }
        }
      });
  };

  const kioskFilteredProducts = (filter) => {
    db.products
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        if (productsFetched.length > 1) {
          let filteredData = [];
          productsFetched.forEach((item) => {
            const itemName = item.name[0].toUpperCase();

            if (filter.includes("-")) {
              const filters = filter.split("-");
              const start = filters[0];
              const end = filters[filters.length - 1];

              if (itemName >= start && itemName <= end) {
                filteredData.push(item);
              }
            } else if (itemName === filter) {
              filteredData.push(item);
            }
          });

          setIsProductsVisible(true);

          if (filter === "All") {
            // setProductsList([...productsFetched]);
          } else {
            // setProductsList([...filteredData]);
          }
        }
      });
  };

  const getSearchedItem = () => {
    db.products
      .where("name")
      .startsWithIgnoreCase(productSearchInput)
      .or("batchIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("upcIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("value")
      .equalsIgnoreCase(productSearchInput)
      .limit(100)
      .toArray()
      .then((product) => {
        if (product.length > 0) {
          const obj = product[0];
          if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
            const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === productSearchInput.toLowerCase());
            if (batchProductIndex >= 0 && obj.multiPrice === "N") {
              addDefinedProduct(
                obj,
                obj.mBatch[batchProductIndex].upc,
                obj.mBatch[batchProductIndex].batchno,
                obj.mBatch[batchProductIndex].mBatchId,
                obj.mBatch[batchProductIndex].price
              );
            } else if (obj.mBatch.length === 1) {
              addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
            } else {
              // let upcKeys = 0;
              const productSet = [];
              const localObj = obj;
              for (let i = 0; i < obj.mBatch.length; i += 1) {
                const batchItem = { ...localObj.mBatch[i] };
                const obj = { ...localObj };
                if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                  // prettier-ignore
                  const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                  const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                  batchItem.price = taxedPrice;
                  obj.cTaxId = obj.contraTaxId;
                  obj.taxRate = obj.contraRate;
                }
                const productDefined = {
                  batchno: batchItem.batchno,
                  description: obj.description,
                  discount: 0,
                  discountName: "",
                  imageurl: obj.imageurl,
                  isDecimal: obj.isDecimal,
                  isManualQty: obj.isManualQty,
                  isPromoApplicable: obj.isPromoApplicable,
                  isReturn: false,
                  mBatchId: batchItem.mBatchId,
                  mPricingruleId: null,
                  name: obj.name,
                  name2: obj.name2,
                  nettotal: 0,
                  primaryOrderLine: null,
                  productId: obj.mProductId,
                  realPrice: obj.cTaxId,
                  listPrice: obj.slistprice,
                  sunitprice: obj.sunitprice,
                  returnQty: null,
                  salePrice: obj.cTaxId,
                  mrpPrice: batchItem.listPrice,
                  stock: obj.onhandQty,
                  taxCategory: obj.taxCategory,
                  tax: obj.cTaxId,
                  taxAmount: 0,
                  taxRate: obj.taxRate,
                  uom: obj.csUomId,
                  customAttributes: obj.customAttributes,
                  newCustomAttributes: [],
                  selectedAddons: [],
                  uom_name: obj.uomName,
                  isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
                  isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                  upc: batchItem.upc,
                  value: obj.value,
                  weight: 0,
                  shortDescription: obj.shortDescription,
                  hsncode: obj.hsncode,
                  csBunitId: obj.csBunitId,
                  mProductCategoryId: obj.mProductCategoryId,
                  productManufacturerId: obj.productManufacturerId,
                  productBrandId: obj.productBrandId,
                  batchedProduct: obj.batchedProduct,
                  batchedForSale: obj.batchedForSale,
                  batchedForStock: obj.batchedForStock,
                  multiPrice: obj.multiPrice,
                  shelfLife: obj.shelfLife,
                };
                // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                productSet.push(productDefined);
              }
              setBatchSetAvailable([...productSet]);
              setDisplayBatchSelection(true);
            }
          } else {
            addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
          }
        } else {
          {
            message.warning(`${t("product_search_error")}`);
          }
        }
      });
  };

  const clearProductSearchResults = () => {
    setProductSearchInput("");
    // setProductsList([]);
    // if (selectedProductCategory !== "allProducts") {
    //   setSelectedProductCategory("allProducts");
    // } else {
    //   setSelectedProductCategory("all");
    //   setTimeout(() => {
    //     setSelectedProductCategory("allProducts");
    //   }, 200);
    // }
  };

  const productListCardRef = useRef(null);

  const closeProductPanel = () => {
    const productSearchInput = document.getElementById("sm-product-search");
    productSearchInput.focus();
    setIsSearchProducts(false);
    setIsProductsVisible(false);
    setIsProductsFilter(false);
    setProductSearchInput("");
    clearProductSearchResults();
    setProductsData([]);
  };
  // PRODUCT SEARCH END

  const cartRef = useRef();
  useEffect(() => {
    let cartObj = JSON.parse(localStorage.getItem("cartObj")) ? JSON.parse(localStorage.getItem("cartObj")) : cart;
    cartRef.current = { ...cart };
    if (cart.items.length === 0) {
      cart.couponInput = [];
      cart.discount = 0;
      cart.payments = [];
      cart.paid = 0;
      cart.giftCardRefId = null;
      cart.totalBillDicount = null;
      setOverPayedAmount(0);
      setSelectedProductInCart({});
      setShowPaymentMethods(false);
      setCart(cart);
    }
  }, [cart]);

  // dynamic pop up modal start
  const [dynamicForm, setDynamicForm] = useState(false);
  const [dynamicModalForm] = Form.useForm();

  const submitCustomAttributs = (prod) => {
    const productObj = { ...prod };

    addProductToCart(
      productObj,
      productObj.qty,
      false,
      cart,
      setCart,
      setSelectedProductInCart,
      deleteCart,
      processTotalManualDiscount,
      setLoader,
      salesRepresentDefaultLine,
      tillData,
      cartRef,
      productsCopy,
      salesRepresent,
      orderType,
      processBillDiscounts,
      undefined,
      setSelectedRowKeys,
      setOrderTimeDetails,
      setDisplayAddOnSelection,
      displayAddOnSelection,
      handleAddOnModal
    );
    setSelectedAddons([]);
  };

  const addProduct = async (addToCart, qty, totalQtyFlag, modifiedPrice) => {
    setStockList([]);
    const cart = { ...cartRef.current };

    // if (cart.layAway === "Y") {
    //   message.info("This order cannot be edited!");
    //   return;
    // }

    if (Object.keys(addToCart).length === 0) {
      return;
    }

    if (!("isBlindReceipt" in cart) || (cart.isBlindReceipt && addToCart.isGiftCard)) {
      // Do nothing
    } else {
      setShowPaymentMethods(false);
    }

    setProductSearchInput("");

    let freeFlag = true;
    if (addToCart.isFree) {
      const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(addToCart.mPricingruleId).toArray();
      const matchingProductIndex = matchingPricingRules[0]?.mPricingXProducts.find((op) => op.mProductId === addToCart.productId);
      freeFlag = matchingProductIndex && addToCart.weight + qty <= matchingProductIndex.quantity;
    }

    const findItemIndex = (condition) =>
      cart?.items?.findIndex(
        (p) =>
          p.productId === addToCart.productId &&
          p.upc === addToCart.upc &&
          p.mBatchId === addToCart.mBatchId &&
          p.isReturn === false &&
          p.lineId === addToCart.lineId &&
          condition(p)
      );

    const addonIndex = findItemIndex((p) => p?.selectedAddons?.length > 0);
    const attributeIndex = findItemIndex((p) => p?.newCustomAttributes?.length > 0);

    let addOnFlag = false;

    if (addToCart?.productAddons?.length > 0 && addToCart.weight + qty !== 0 && qty > 0) {
      if (addonIndex !== -1) {
        addOnFlag = true;
      } else {
        setDisplayAddOnSelection(true);
        handleAddOnModal(addToCart, qty);
      }
    }

    if (addToCart?.customAttributes?.length > 0 && addToCart.weight + qty !== 0 && qty > 0) {
      if (attributeIndex !== -1) {
        addOnFlag = true;
      } else {
        setDynamicForm(true);
        addToCart.qty = qty;
        setSelectedProductInCart(addToCart);
      }
    }

    if (
      freeFlag &&
      (addToCart?.productAddons?.length <= 0 || addToCart.weight + qty === 0 || qty < 0 || addOnFlag) &&
      (addToCart?.customAttributes?.length <= 0 || addToCart.weight + qty === 0 || qty < 0 || addOnFlag)
    ) {
      await addProductToCart(
        addToCart,
        qty,
        totalQtyFlag,
        cart,
        setCart,
        setSelectedProductInCart,
        deleteCart,
        processTotalManualDiscount,
        setLoader,
        salesRepresentDefaultLine,
        tillData,
        cartRef,
        productsCopy,
        salesRepresent,
        orderType,
        processBillDiscounts,
        modifiedPrice,
        setSelectedRowKeys,
        setOrderTimeDetails,
        setDisplayAddOnSelection,
        displayAddOnSelection,
        handleAddOnModal
      );
    }
  };

  const [documentSequence, setDocumnetSequence] = useState(tillDocumentSequence);
  const [orderDelay, setOrderDelay] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
  const [activeOrderProcess, setActiveOrderProcess] = useState(false);
  const orderState = useRef(0);

  const processOrder = (param) => {
    orderState.current += 1;
    if (orderState.current === 1 && cart.items.length !== 0) {
      processOrderApproved(param);
    } else {
      setPaymentModal(false);
    }
  };

  const processOrderApproved = async (param, obj) => {
    if (!activeOrderProcess) {
      setOverPayedAmount(0);
      setAmount(0);
      setActiveOrderProcess(true);
      const cartToDb = typeof obj === "object" ? obj : cart;
      const newDocumentSequence = documentSequence + 1;

      const tillSession = JSON.parse(localStorage.getItem("tillSession"));
      const tillSessionId = tillSession.tillSessionId;

      if (param !== "layaway") {
        if (cart.layAway === "Y") {
          cartToDb.layAway === "Y";
        } else {
          cartToDb.layAway = "N";
        }
        const change = cartToDb.paid - Math.abs(cartToDb.total);

        if (cart.items.filter((it) => it.isReturn === true).length > 0 && cartToDb.total === 0) {
          cartToDb.change = 0;
          let paymentType = selectedPaymentMethod.name.toLowerCase() === "cash" ? "cash" : selectedPaymentMethod.name.toLowerCase() === "card" ? "card" : "cash";
          const pMindex = tillDataPaymentMethods.findIndex((pi) => pi.name.toLowerCase() === paymentType);
          cartToDb.payments.push({ ...tillDataPaymentMethods[pMindex], amount: 0 });
        } else {
          cartToDb.change = parseFloat(cartToDb.change.toFixed(2));
        }
        // console.log(cPi,"====>cPi")
        if (cart.isReturn === true || cart.isReturn === "Y") {
          cartToDb.payments.findIndex((pi) => {
            pi.amount = pi.amount * -1;
          });
        } else {
          // const updatedCashAmt = parseFloat(cartToDb.payments[0].amount) - change;
          // cartToDb.payments[0].amount = `${updatedCashAmt}`;
        }
      } else {
        cartToDb.layAway = "Y";
      }

      if (cartToDb.customer?.loyaltyLevel?.cwrLoyaltyLevelId) {
        let loyaliryData = await db.loyalityData.toArray();
        loyaliryData.forEach((loyality) => {
          if (loyality.loyaltylevelId === cartToDb.customer.loyaltyLevel?.cwrLoyaltyLevelId) {
            const eventCalenders = loyality.loyaltyEventCalenders;
            const hasValidEventCalenders = eventCalenders?.length > 0 ? dateValidator(eventCalenders[0].startDate, eventCalenders[0].endDate) : true;
            // const accumulationLength = loyality.LoyaltyAccumulation?.length;
            loyality.LoyaltyAccumulation?.forEach((item) => {
              const productValidation = item.mProductId && item.mProductId !== "" ? cart.items.some((pc) => pc.mProductId === item.mProductId) : true;
              const categoryValidation =
                item.mProductCategoryId && item.mProductCategoryId !== "" ? cart.items.some((pc) => pc.mProductCategoryId === item.mProductCategoryId) : true;
              const checkEventsCalender =
                item.loyaltyEvent === "Y" && eventCalenders?.length > 0
                  ? eventCalenders.some((i) => dateValidator(i.startDate, i.endDate))
                  : item.loyaltyEvent === "Y" && (!eventCalenders || eventCalenders.length === 0);

              if (
                item.loyaltyEvent === "Y" &&
                item.minPurchase <= Math.abs(cart.total) &&
                productValidation &&
                categoryValidation &&
                hasValidEventCalenders &&
                checkEventsCalender
              ) {
                cartToDb.accumulationPoints = parseFloat((item.pointsPerUnit * (cart.total - cart.tax)).toFixed(3));
              } else if (item.loyaltyEvent === "N" && item.minPurchase <= Math.abs(cart.total) && productValidation && categoryValidation) {
                cartToDb.accumulationPoints = parseFloat((item.pointsPerUnit * (cart.total - cart.tax)).toFixed(3));
              }
            });
          }
        });
      }
      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
        ? JSON.parse(localStorage.getItem("orderTimeDetails"))
        : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails.orderEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      cartToDb.orderTime = timeStamp();
      cartToDb.createdBy = tillData.tillAccess.csUserId;
      cartToDb.documentno = cart?.isRetrived === "Y" || cart?.type === "Layaway" ? cart.documentno : `${tillData.tillAccess.cwrTill.prefix}${newDocumentSequence}`;
      cartToDb.orderType = orderType?.cwrSaletype?.cwrSaletypeId;
      cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
      cartToDb.tillSessionId = tillSessionId;
      cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
      cartToDb.isSynced = 0;
      cartToDb.syncAttempts = 0;
      cartToDb.giftCardData = giftCardData;
      cartToDb.roundOff = parseFloat((cart.isReturn === true || cart.isReturn === "Y" ? cartToDb.roundOff * -1 : cartToDb.roundOff).toFixed(precision));
      cartToDb.discount = parseFloat(cartToDb?.discount?.toFixed(precision));
      cartToDb.customerSearchKey = cartToDb?.customer?.code;
      cartToDb.customer.retlLoyaltyBalance = (cartToDb?.customer?.retlLoyaltyBalance || 0) + (cartToDb?.accumulationPoints || 0);
      cartToDb.orderTimeDetails = orderTimeDetails;
      cartToDb.totalQty = parseFloat(cartToDb?.totalQty?.toFixed(precision));
      cartToDb.dineIn = localStorage.getItem("dineIn") ? localStorage.getItem("dineIn") : "Y";
      cartToDb.typeOrder = localStorage.getItem("homeDelivery") ? localStorage.getItem("homeDelivery") : "N";
      setGiftCardData([]);

      cartToDb.items = cartToDb.items.map((item, i) => {
        const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
        const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
        const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

        // Update individual item properties
        item.discount = discountFixed;
        item.key = i;
        item.nettotal = parseFloat(item.nettotal.toFixed(precision));

        if (!item.isGiftCard) {
          let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
          if (!isFinite(unitPrice)) unitPrice = 0;
          item.sunitprice = item.sunitprice ? item.sunitprice : item.salePrice;
          const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
          const netList = (item.listPrice - item.listPrice / (1 + item.taxRate / 100)).toFixed(precision);
          item.listPrice = item.listPrice ? item.listPrice : item.grosslist;
          item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
          item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
          item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
          item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
          item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
          item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
          item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
          item.grosslist = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
          item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
          item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
          item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        }
        return item;
      });

      let taxDetails = [];

      const combineTaxRates = (items) => {
        // Create an object to store tax rates as keys and total tax amounts as values
        const taxMap = {};

        // Loop through each item to calculate total tax amounts
        items.forEach((item) => {
          if (!item.isGiftCard) {
            let { taxRate, taxAmount, nettotal, tax, cardNo, taxCategory } = item;
            nettotal = nettotal - taxAmount;
            if (taxMap[taxRate]) {
              // If the tax rate already exists, add the tax amount and nettotal to the existing total
              taxMap[taxRate].taxAmount += parseFloat(taxAmount.toFixed(precision));
              taxMap[taxRate].nettotal += parseFloat(nettotal.toFixed(precision));
            } else {
              // If the tax rate doesn't exist, create a new entry
              taxMap[taxRate] = {
                taxAmount: parseFloat(taxAmount.toFixed(precision)),
                nettotal: parseFloat(nettotal.toFixed(precision)),
                tax: tax,
                taxRate: taxRate,
                taxCategory: taxCategory,
              };
            }
          }
        });

        // Create a new array from the taxMap object
        const combinedTaxRates = Object.keys(taxMap).map((taxRate) => {
          taxDetails.push({
            csTaxID: taxMap[taxRate].tax,
            taxableAmt: parseFloat(taxMap[taxRate].taxAmount.toFixed(precision)),
            taxAmt: parseFloat(taxMap[taxRate].nettotal.toFixed(precision)),
            taxRate: taxMap[taxRate].taxRate,
            taxCategory: taxMap[taxRate].taxCategory,
          });
          return `{
              csTaxID: "${taxMap[taxRate].tax}"
              taxableAmt: ${parseFloat(taxMap[taxRate].nettotal.toFixed(precision))}
              taxAmt: ${parseFloat(taxMap[taxRate].taxAmount.toFixed(precision))}
          }`;
        });

        return combinedTaxRates;
      };
      cartToDb.taxDetails = taxDetails;
      cartToDb.combinedTaxRates = combineTaxRates(cart.items);
      // cartToDb = adjustOrderTotals(cartToDb, cartToDb.items);
      // cartToDb.isReturn = false;
      localStorage.removeItem("orderTimeDetails");
      db.ordersData
        .where("tillSessionId")
        .equals(tillSessionId)
        .modify((event) => {
          event.salesTax = event.salesTax ? event.salesTax : [];
          event.returnTax = event.returnTax ? event.returnTax : [];
          const groupedItems = [...event.items];
          cartToDb.items.forEach((item) => {
            let index = groupedItems.findIndex((ele) => item.value === ele.value);
            if (index >= 0) {
              let obj = {};
              let valuesr = parseFloat(item.taxAmount.toFixed(precision));
              obj.taxAmount = parseFloat(groupedItems[index].taxAmount.toFixed(precision)) + parseFloat(item.taxAmount.toFixed(precision));
              obj.nettotal = parseFloat(item.nettotal.toFixed(precision)) + groupedItems[index].nettotal - parseFloat(item.taxAmount.toFixed(precision));
              obj.nettotal = parseFloat(obj.nettotal.toFixed(precision));
              obj.taxAmount = parseFloat(obj.taxAmount.toFixed(precision));
              obj.weight = item.weight + groupedItems[index].weight;
              obj.value = item.value;
              obj.name = item.name;
              let newItem = obj;
              groupedItems.splice(index, 1);
              groupedItems.push(newItem);
            } else {
              let obj = {
                taxAmount: parseFloat(item.taxAmount.toFixed(precision)),
                nettotal: parseFloat((item.nettotal - item.taxAmount).toFixed(precision)),
                weight: item.weight,
                value: item.value,
                name: item.name,
              };
              groupedItems.push(obj);
            }
          });

          // Initialize targetTaxArray
          let targetTaxArray = cartToDb.isReturn ? event.returnTax : event.salesTax;

          // Loop through each newTax
          taxDetails.forEach((newTax) => {
            const existingTax = targetTaxArray.find((tax) => tax.taxRate === newTax.taxRate);

            if (existingTax) {
              existingTax.taxAmt = existingTax.taxAmt + parseFloat(newTax.taxAmt.toFixed(precision));
              existingTax.taxAmt = parseFloat(existingTax.taxAmt.toFixed(precision));
              existingTax.taxableAmt = existingTax.taxableAmt + parseFloat(newTax.taxableAmt.toFixed(precision));
              existingTax.taxableAmt = parseFloat(existingTax.taxableAmt.toFixed(precision));
            } else {
              targetTaxArray.push({
                csTaxID: newTax.csTaxID,
                taxableAmt: parseFloat(newTax.taxableAmt.toFixed(precision)),
                taxAmt: parseFloat(newTax.taxAmt.toFixed(precision)),
                taxRate: newTax.taxRate,
                taxCategory: newTax.taxCategory,
              });
            }
          });

          // Assign the modified targetTaxArray back to event.salesTax or event.returnTax
          if (cartToDb.isReturn) {
            event.returnTax = targetTaxArray;
            event.netReturnTotal = event.netReturnTotal + parseFloat(cart.total.toFixed(precision)) - parseFloat(cart.tax.toFixed(precision));
            event.grossReturnTotal = event.grossReturnTotal + parseFloat(cart.total.toFixed(precision));
            event.netReturnTotal = parseFloat(event.netReturnTotal.toFixed(precision));
            event.totaReturnlTax = event.totaReturnlTax + cart.tax;
            event.totaReturnlTax = parseFloat(event.totaReturnlTax.toFixed(precision));
            event.returnsTransactions = event.returnsTransactions + 1;
            upsertPOSLog(cartToDb, "SLR");
          } else {
            event.netTotal = event.netTotal + parseFloat(cart.total.toFixed(precision)) - parseFloat(cart.tax.toFixed(precision));
            event.netTotal = parseFloat(event.netTotal.toFixed(precision));
            event.grossTotal = parseFloat(event.grossTotal.toFixed(precision)) + parseFloat(cart.total.toFixed(precision));
            event.totalTax = event.totalTax + parseFloat(cart.tax.toFixed(precision));
            event.totalTax = parseFloat(event.totalTax.toFixed(precision));
            event.salesTax = targetTaxArray;
            event.grossTotal = parseFloat(event.grossTotal.toFixed(precision));
            event.salesTransactions = event.salesTransactions + 1;
          }

          event.items = groupedItems;
        });

      db.paymentsData
        .where("tillSessionId")
        .equals(tillSessionId)
        .modify((event) => {
          let paymentsData = [];
          event.payments.forEach((payment) => {
            let currPaymentAmount = 0;
            let transactionCount = 0;
            let change = 0;
            let currPaymentReturn = 0;
            change += cart.change;
            cart.payments.forEach((orderPayment) => {
              if (payment.name.toLowerCase() === orderPayment.name.toLowerCase() && cart.total > 0) {
                payment.amount = payment.amount ? payment.amount : 0 + parseFloat(orderPayment.amount);
                payment.transactionCount = payment.transactionCount ? payment.transactionCount : 0 + 1;
                currPaymentAmount += parseFloat(orderPayment.amount);
              } else if (payment.name.toLowerCase() === orderPayment.name.toLowerCase() && cart.total < 0) {
                payment.paymentReturn = payment.paymentReturn ? payment.paymentReturn : 0 + parseFloat(Math.abs(orderPayment.amount));
                payment.transactionCount = payment.transactionCount ? payment.transactionCount : 0 + 1;
                currPaymentReturn += parseFloat(Math.abs(orderPayment.amount));
              }
            });
            payment.expectedAmount = payment.expectedAmount ? payment.expectedAmount : 0 + currPaymentAmount;
            payment.amount = payment.amount ? payment.amount : 0 + currPaymentAmount;
            payment.paymentReturn = payment.paymentReturn ? payment.paymentReturn : 0 + currPaymentReturn;
            payment.difference = payment.difference ? payment.difference : 0 + payment.expectedAmount;
            payment.transactionCount = payment.transactionCount ? payment.transactionCount : 0 + transactionCount;
            payment.change = payment.change ? payment.change : 0 + change;
            paymentsData.push(payment);
          });

          event.payments = paymentsData;
        });

      setPaymentProcessFlag(false);
      if (cart.type !== "Layaway") {
        try {
          await db.orders.add(cartToDb);
          setPaymentModal(false);
          let takeAway = localStorage.getItem("dineIn");
          if (takeAway === "Y") {
            let tableData = JSON.parse(localStorage.getItem("tableName"));
            await db.tableData
              .where("cwrFbTableId")
              .equals(tableData.cwrFbTableId)
              .toArray()
              .then(async (response) => {
                if (response.length > 0) {
                  // let finalData = {...response[0]};
                  response[0].salesHistory = "Y";
                  response[0].fbOrderSync = "N";
                  response[0].tableSync = "N";
                  response[0].statusType = "OPN";
                  response[0].fbOrderStatus = "CO";
                  response[0].color = "#a7c957";
                  response[0].ordered = "N";
                  let obj;
                  await db.fbOrderData
                    .where("cwrFbTableId")
                    .equals(tableData?.cwrFbTableId)
                    .toArray()
                    .then((ordersFetched) => {
                      if (ordersFetched.length > 0) {
                        ordersFetched.map(async (fbOrder) => {
                          if (fbOrder.fbOrderStatus === "IP") {
                            fbOrder.salesHistory = "Y";
                            fbOrder.fbOrderSync = "N";
                            fbOrder.tableSync = "N";
                            fbOrder.statusType = "OPN";
                            fbOrder.fbOrderStatus = "CO";
                            fbOrder.color = "#a7c957";
                            fbOrder.sOrderID = cart.sOrderID;
                          }
                          obj = {
                            fbOrderId: fbOrder.fbOrderId,
                            order: fbOrder,
                          };
                          await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                        });
                      }
                    });
                  await db.tableData.put(response[0], response[0].cwrFbTableId);
                  SyncData(response[0], "upsertTableStatus");
                  SyncData(response[0], "upsertFbOrder");
                  const orderData = {
                    tableDetails: {
                      cwrFbTableId: response[0].cwrFbTableId,
                      data: response[0],
                    },
                    fbOrder: obj,
                  };
                  // sendOrder(orderData);
                }
              });
          }
          localStorage.setItem("cartObj", []);
          if (cart?.isRetrived !== "Y") {
            localStorage.setItem("documentSequence", newDocumentSequence);
            setDocumnetSequence(newDocumentSequence);
            deleteCart();
          } else {
            let cartObj = {
              items: [],
              couponInput: [],
              advancePayment: 0,
              total: 0,
              tax: 0,
              discount: 0,
              paid: 0,
              change: 0,
              totalQty: 0,
              roundOff: 0,
              payments: [],
              redemptionPoints: 0,
              accumulationPoints: 0,
              creditAmount: 0,
              sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
              referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
              giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              customer: defaultCustomer,
              salesRepId: null,
              cardPaymentData: {},
              documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}`,
            };
            setCart(cartObj);
            localStorage.setItem("cartObj", JSON.stringify(cartObj));
          }
          setNotesValue("");
          setOrderDelay(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
          message.success(`Order ${cartToDb.documentno} Created Successfully`);
          // keyboardRef.current.clearInput();
          // const productSearchInput = document.getElementById("sm-product-search");
          // if (productSearchInput) {
          //   productSearchInput.focus();
          //   productSearchInput.select();
          // }
          setIsInputFocused(false);
          orderState.current = 0;
          setActiveOrderProcess(false);
          localStorage.removeItem("totalDiscountsCartRef");
          if (cartToDb.hasOwnProperty("giftVoucherType")) {
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation {
              generateGiftVoucher(
                giftVoucher: {
                  cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                  tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                  b2cCustomerId: "${cartToDb.customer.cwrCustomerId}"
                  giftVoucherType: "${cartToDb.giftVoucherType}"
                  voucherAmount: ${cartToDb.giftVoucherAmount}
                }
              ) {
                status
                message
                cwrGiftVoucherId
                voucherAmount
                voucherCode
              }
            }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${tokens.token_type} ${tokens.access_token}`,
              },
            })
              .then((generateGiftVoucher) => {
                const gGV = generateGiftVoucher.data.data.generateGiftVoucher;
                if (gGV.status === "200") {
                  cartToDb.voucherCode = gGV.voucherCode;
                  PrintController(cartToDb, cart);
                } else {
                  throw new Error(gGV);
                }
              })
              .catch((error) => {
                console.error(error);
                cartToDb.voucherCode = null;
                PrintController(cartToDb, cart);
              });
          } else {
            cartToDb.voucherCode = null;
            if (tillaccess?.layout === "2") {
              cartToDb["orderSelection"] = orderTypeSelection;
            }
            PrintController(cartToDb, cart);
          }
        } catch (error) {
          console.error("Failed to place an order: ", error);
        }
      } else {
        await db.orders
          .put(cartToDb)
          .then(async () => {
            setPaymentModal(false);
            localStorage.setItem("cartObj", []);
            // if(cart?.isRetrived !== "Y" ){
            //   console.log("layway")
            //   localStorage.setItem("documentSequence", newDocumentSequence);
            //   setDocumnetSequence(newDocumentSequence);
            //   deleteCart();
            // }else {

            let cartObj = {
              items: [],
              couponInput: [],
              advancePayment: 0,
              total: 0,
              tax: 0,
              discount: 0,
              paid: 0,
              change: 0,
              totalQty: 0,
              roundOff: 0,
              payments: [],
              redemptionPoints: 0,
              accumulationPoints: 0,
              creditAmount: 0,
              sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
              referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
              giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              customer: defaultCustomer,
              salesRepId: null,
              cardPaymentData: {},
              documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}`,
            };
            setCart(cartObj);
            localStorage.setItem("cartObj", JSON.stringify(cartObj));
            // }
            setNotesValue("");
            message.success(`Order ${cartToDb.documentno} Created Successfully`);
            orderState.current = 0;
            setActiveOrderProcess(false);
            localStorage.removeItem("totalDiscountsCartRef");
            if (cartToDb.hasOwnProperty("giftVoucherType")) {
              Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation {
                generateGiftVoucher(
                  giftVoucher: {
                    cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                    tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                    b2cCustomerId: "${cartToDb.customer.cwrCustomerId}"
                    giftVoucherType: "${cartToDb.giftVoucherType}"
                    voucherAmount: ${cartToDb.giftVoucherAmount}
                  }
                ) {
                  status
                  message
                  cwrGiftVoucherId
                  voucherAmount
                  voucherCode
                }
              }`,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              })
                .then((generateGiftVoucher) => {
                  const gGV = generateGiftVoucher.data.data.generateGiftVoucher;
                  if (gGV.status === "200") {
                    cartToDb.voucherCode = gGV.voucherCode;
                    PrintController(cartToDb, cart);
                  } else {
                    throw new Error(gGV);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  cartToDb.voucherCode = null;
                  PrintController(cartToDb, cart);
                });
            } else {
              cartToDb.voucherCode = null;
              if (tillaccess?.layout === "2") {
                cartToDb["orderSelection"] = orderTypeSelection;
              }
              PrintController(cartToDb, cart);
            }
          })
          .catch((error) => {
            console.error("Failed to place an order: ", error);
          });
      }
      if (tillLayout === 2) {
        setTimeout(() => {
          setLoader(false);
          // history.push("/table-management");
          history.push("/pos");
        }, 4000);
      }
    }
  };

  //// CART OPERATIONS END ////

  // OFFERS AND DISCOUNTS BLOCK START
  const [offerProductsList, setOfferProductList] = useState();
  const [displayOfferProductSelectiton, setDisplayOfferProductSelection] = useState(false);

  const selectOfferProduct = (product) => {
    const pro = product;
    addProduct(pro, 1);
    setDisplayOfferProductSelection(false);
    // message.success(`${pro.name} (Free) Added Successfully`);
    if (tillLayout === 2) {
      setPaymentModal(true);
    }
  };
  // OFFERS AND DISCOUNTS BLOCK START

  //// PAYMENTS BLOCK START ////
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentProcessFlag, setPaymentProcessFlag] = useState(false);
  const [amount, setAmount] = useState("");
  const [overPayedAmount, setOverPayedAmount] = useState(0);
  const [denaminationsKeyboard, setDenaminationsKeyboard] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});

  const [loader, setLoader] = useState(false);

  const onChangeAmount = (event) => {
    let type = "3";
    let value = event.target.value?.replace(/[^\d.]/g, "");
    paymentProcess(type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb);
  };

  const handleAmount = (value) => {
    let type = "1";
    paymentProcess(type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb);
  };

  const handleCashPayment = (value) => {
    let type = "2";
    paymentProcess(type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb);
  };

  const [paymentModalByCustomerState, setPaymentModalByCustomerState] = useState(false);

  const openPaymentModal = async () => {
    if (tillLayout === 2) {
      setPaymentModal(true);
    }
    setPaymentProcessFlag(true);
    openPaymentModalByCustomer();
  };

  const openPaymentModalByCustomer = (cartObj) => {
    paymentModalInputRef?.current?.select();
    paymentModalInputRef?.current?.focus();
    const cartData = cartObj ? { ...cartObj } : cart;
    localStorage.setItem("totalDiscountsCartRef", JSON.stringify(cartData));
    setPaymentModalByCustomerState(false);
    if (cartData?.items?.length > 0) {
      db.pricingRules.toArray().then((pr) => {
        const pricingRuleCount = pr.length;
        if (pricingRuleCount > 0) {
          pr.sort((a, b) => {
            if (a.priority === null && b.priority === null) {
              return 0;
            } else if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return parseInt(a.priority, 10) - parseInt(b.priority, 10);
          });
          for (let i = 0; i < pricingRuleCount; i++) {
            const pricingRule = pr[i];
            if (dateValidator(pricingRule.startDate, pricingRule.endDate) && pricingRule.iscoupon === "N" && (pricingRule.type === "TD" || pricingRule.type === "TDF")) {
              if (pricingRule.timeSpecific === "Y") {
                const weekDay = currentDay();
                const pStartTime = pricingRule.starttime.substring(11);
                const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
                const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                  processBillDiscounts(pricingRule, cartData, false);
                }
              } else {
                processBillDiscounts(pricingRule, cartData, false);
              }
            }
          }
        }
      });
    }
  };

  const processBillDiscounts = (pricingRule, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId) => {
    if (pricingRule.type === "TD" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null)) {
      let updatedCart = TotalBillDiscount(pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId);
      return updatedCart;
    }

    if (pricingRule.type === "TDF" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null)) {
      let updatedCart = TotalBillFreeProductDiscount(
        pricingRule,
        setCart,
        cart,
        orderType,
        cartObj,
        addProduct,
        setOfferProductList,
        setDisplayOfferProductSelection,
        iscoupon,
        couponInput,
        uniqReferenceId,
        mPricingCouponId,
        mPricingRulesId
      );
      return updatedCart;
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setSelectedPaymentMethod("");
    // const billCart = JSON.parse(localStorage.getItem("totalDiscountsCartRef"));
    // localStorage.removeItem("totalDiscountsCartRef");
    // setCart({ ...billCart });
  };

  const [loyaltyPaymentOtp, setLoyaltyPaymentOtp] = useState();
  const [loyaltyInputValue, setLoyaltyInputValue] = useState("");
  const [loyalityOtpModalVisible, setLoyalityOtpModalVisible] = useState(false);
  const [loyalityOtpData, setLoyalityOtpData] = useState();
  const [paymentModalLoyalityMessages, setPaymentModalLoyalityMessages] = useState({ inputMessage: "Enter Amount", buttonText: "Add Payment" });

  const processOtpInput = () => {
    const { otp, paymentMethod, value, redeemPoints } = loyalityOtpData;
    setLoyalityOtpModalVisible(false);
    if (otp === loyaltyPaymentOtp) {
      message.success("Loyality Points Redeemed !");
      paymentMethod.redemptionPoints = parseInt(redeemPoints);
      processPayment(paymentMethod, value);
    } else {
      message.warning("Invalid OTP");
    }
    setLoyalityOtpData();
    setLoyaltyPaymentOtp();
  };

  const handleLoyalityInput = (value) => {
    if (value === "clear") {
      setLoyaltyInputValue("");
    } else if (value === "x") {
      setLoyaltyInputValue(`${loyaltyInputValue.toString().substring(0, loyaltyInputValue.toString().length - 1)}`);
    } else {
      setLoyaltyInputValue(`${loyaltyInputValue}${value}`);
    }
  };

  const checkLoyality = async () => {
    requestPayment(selectedPaymentMethod, parseFloat(loyaltyInputValue));
    setLoyalityOtpModalVisible(false);
    setLoyaltyInputValue(0);
    // setCart(cartObj);
    // localStorage.setItem("cartObj", JSON.stringify(cartObj));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        setDenaminationsKeyboard(true);
      }
      if (selectedPaymentMethod.isloyalty) {
        setPaymentModalLoyalityMessages({
          inputMessage: "Enter Points to Redeem",
          buttonText: "Redeem",
        });
        try {
          let loyaltyData = await db.loyalityData.toArray();
          const matchedLoyalty = loyaltyData.find((loyalty) => loyalty.name === cart.customer.loyaltyLevel.name);
          let cartObj = cart;
          let value = 0;
          loyaltyData?.forEach((loyality) => {
            if (loyality.loyaltylevelId === cartObj?.customer?.loyaltyLevel?.cwrLoyaltyLevelId) {
              loyality.loyaltyRedemption.forEach((redeemItem) => {
                value = parseFloat((cart.total - cart.paid / redeemItem.redemptionValue).toFixed(2));
              });
            }
          });
          if (Math.abs(matchedLoyalty.loyaltyRedemption[0].minPurchase <= cart.total)) {
            setLoyalityOtpModalVisible(true);
            setLoyaltyInputValue(value ? value : 0);
            upsertPOSLog(cart, "LOR");
          } else {
            message.error("The minimum purchase amount required for loyalty redemption has not been met.");
          }
        } catch (error) {
          console.error("Error fetching loyalty data:", error);
        }
      } else if (selectedPaymentMethod.isGiftCard) {
        setGiftCardFlag(true);
        setPaymentModalLoyalityMessages({
          inputMessage: "Enter Coupon Code",
          buttonText: "Redeem",
        });
      } else {
        setPaymentModalLoyalityMessages({
          inputMessage: "Enter Amount",
          buttonText: "Add Payment",
        });
      }
    };

    fetchData();
  }, [selectedPaymentMethod]);

  const requestPayment = async (paymentMethod, value, grant) => {
    addAmount(
      paymentMethod,
      value,
      grant,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      setAmount,
      setLoyalityOtpData,
      setLoyalityOtpModalVisible,
      setSelectedPaymentMethod,
      setPaytmQrCodeModalOpens,
      setQrCodeResponse,
      overPayedAmount
    );
  };

  const processPayment = (paymentMethod, value) => {
    completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod);
  };
  //// PAYMENTS BLOCK END ////

  const eBillConfig = useRef({});
  useEffect(async () => {
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
            getPOSConfig(tillId: "${tillValue.cwr_till_id}",name:null) {
              cwrPosConfigId
              name
              posType
              application
              configJson
              PricingRule
              ExpiryDiscount
              activateExpiryDiscount
              registrationTypes
            }
          }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${setAuthTokens}`,
      },
    })
      .then((resp) => {
        if (resp.data.data.getPOSConfig.length > 0) {
          const ebConfig = JSON.parse(resp.data.data.getPOSConfig[0]?.configJson);
          eBillConfig.current = ebConfig;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sendWebHookData = async (pendingOrders) => {
    try {
      // const orders = await db.orders.where("isSynced").equals(0).toArray();
      const orders = [...pendingOrders];
      for (const order of orders) {
        let shortId = "";
        const { eBillWebHookURL } = eBillConfig.current;
        try {
          const { data } = await Axios.get(`${eBillWebHookURL}/ebill/uniqueid`);
          shortId = data;
        } catch (error) {
          console.error(error);
          throw new Error("Failed to Fetch ID");
        }

        const orderItems = [];
        const taxKeyValue = [];
        const taxType = [];

        order.items.forEach((orderItem, i) => {
          let taxRate = orderItem.taxRate;
          if (taxType.indexOf(taxRate) !== -1) {
            let taxVal = orderItem.taxAmount / 2;
            for (let j = 0; j < taxKeyValue.length; j += 1) {
              const keys = Object.keys(taxKeyValue[j]);
              if (keys[0].toString() === taxRate.toString()) {
                taxKeyValue[j][taxRate] = taxKeyValue[j][taxRate] + taxVal;
              }
            }
          } else {
            taxType.push(taxRate);
            let taxVal = orderItem.taxAmount / 2;
            taxKeyValue.push({ [taxRate]: taxVal });
          }

          orderItems.push({
            line_no: i + 1,
            sku: orderItem.value,
            name: orderItem.name,
            description: null,
            hsn_code: null,
            selling_price: orderItem.salePrice,
            quantity: orderItem.weight,
            amount: orderItem.nettotal,
            list_price: orderItem.realPrice,
            uom: orderItem.uom_name,
            attributes: {
              mc: "",
              symc: "",
            },
            taxes: [
              {
                tax_name: "GST",
                percentage: orderItem.taxRate,
                amount: orderItem.taxAmount,
                taxableAmount: orderItem.nettotal,
              },
            ],
            brand: null,
            categoryId: orderItem.mProductCategoryId,
            categoryName: null,
          });
        });

        const payments = [];
        order.payments.forEach((payment, i) => {
          payments.push({
            payment_method: payment.name,
            amount: payment.amount,
          });
        });

        const orderData = {
          clientId: tillData.tillAccess.csClientId,
          storeId: tillData.tillAccess.csBunit.csBunitId,
          eBillCommType: eBillConfig.current.eBillCommType,
          created: order.orderTime,
          updated: order.orderTime,
          short_id: shortId,
          customer: {
            customer_id: order.customer.cwrCustomerId,
            first_name: order.customer.name,
            last_name: "",
            mobile: order.customer.mobileNo,
          },
          order_type: order.orderType,
          order_id: order.sOrderID,
          bill_no: order.documentno,
          bill_status: "PAID",
          order_date: order.orderTime,
          items: orderItems,
          item_count: order.items.length,
          total_qty: order.totalQty,
          net_total: order.total,
          gross_total: order.total,
          payment: payments,
          taxes: [
            {
              tax_name: "GST",
              amount: order.tax,
              taxableAmount: order.total,
            },
          ],
          additonalTaxinfo: taxKeyValue,
          feedback: false,
          feedbackStars: 0,
        };

        try {
          if (orderData.customer.mobile !== "9999999999") {
            await Axios.post(`${eBillWebHookURL}/ebill/webhook`, orderData);
          }
        } catch (error) {
          console.error(error);
          throw new Error("Failed to POST Webhook");
        }
      }
    } catch (e) {
      console.error("Webhook Error", e);
    }
  };

  // ORDER SYNC BLOCK START
  const syncOrders = async (syncTrigger) => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    let shouldCallAxios = false;
    const posConfig = JSON.parse(localStorage.getItem("posConfig"));
    if (nWStatus === "online") {
      db.logInformation.toArray().then((fetched) => {
        if (fetched.length > 0) {
          const posLogArray = [];
          const trxId = uuidv4().replace(/-/g, "").toUpperCase();
          fetched.forEach((item) => {
            let value = { ...item };
            db.logInformation.where("id").equals(value.id).delete();
            delete value.id;
            posLogArray.push(`{
              type: "${value.type}", 
              action: "LOG", 
              description: "${value.description}", 
              date: "${value.date}",
              time: "${value.time}",    
              orderNo: "${value.orderNo}",
              remarks: "${value.remarks}",
              transactionId: "${trxId}"
              status: "SCS"
              duration: ${value.duration}
          }`);
            if (posConfig?.[value.type] === "Y") {
              shouldCallAxios = true;
            }
          });
          if (shouldCallAxios) {
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation {
                    upsertPOSLog(order: {
                      tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                      userId: "${tillData.tillAccess.csUserId}" 
                      bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                      lines: [${posLogArray}]
                    }) {
                    status   
                    message
                  }
                }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${setAuthTokens}`,
              },
            });
          }
        }
      });

      const csCurrencyId = tillData.tillAccess.csBunit?.currencies[0]?.csCurrencyId;
      db.orders
        .where("isSynced")
        .equals(0)
        .or("isSynced")
        .equals(2)
        .toArray()
        .then((orders) => {
          const pendingOrdersCount = orders.length;
          if (pendingOrdersCount > 0) {
            if (eBillConfig.current.eBill === "Y") {
              sendWebHookData(orders);
            }
            for (let i = 0; i < pendingOrdersCount; i += 1) {
              const regularOrders = orders.filter((order) => {
                const isRegularOrderWithPaid =
                  posConfig.advanceLayaway === "Y"
                    ? order.layAway === "Y"
                      ? order.layAway === "Y" && order.paid + order.advancePayment < order.total
                      : order.layAway === "N" && order.paid !== 0
                    : order.layAway === "N" && order.paid !== 0;
                // const parkedOrders = order.
                const isRegularOrderWithoutPaid = (order.layAway === "N" || order.layAway === "Y") && order.paid === 0;
                const cancelledOrders = order.total === 0 && order.totalQty === 0;
                const parkedOrders = order.parked === "Y" && order.paid === 0;
                return isRegularOrderWithPaid || isRegularOrderWithoutPaid || cancelledOrders || parkedOrders;
              });

              if (regularOrders.length > 0) {
                const orderLines = [];
                for (let j = 0; j < regularOrders[i]?.items.length; j += 1) {
                  let unitTax = regularOrders[i].items[j].taxAmount / regularOrders[i].items[j].weight;
                  let linesMeta = [];

                  if (regularOrders[i].items[j].newCustomAttributes?.length > 0) {
                    Object.entries(regularOrders[i].items[j].newCustomAttributes[0]).forEach(([key, value]) => {
                      linesMeta.push(`{
                        key: "${key}",
                        value: "${value}"
                      }`);
                    });
                  }

                  orderLines.push(`{
                      sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
                      sOrderlineReturnId: ${regularOrders[i].items[j].sOrderlineReturnId ? `"${regularOrders[i].items[j].sOrderlineReturnId}"` : null}
                      created: "${regularOrders[i].orderTime}",
                      createdby: "${regularOrders[i].createdBy}",
                      updated: "${regularOrders[i].orderTime}",
                      updatedby: "${regularOrders[i].createdBy}",
                      sOrderId: "${regularOrders[i].sOrderID}",
                      line: ${(j + 1) * 10},
                      description: "${regularOrders[i].items[j].notes !== undefined && regularOrders[i].items[j].notes !== "" ? regularOrders[i].items[j].notes : ""}",
                      mProductId: "${regularOrders[i].items[j].productId ? regularOrders[i].items[j].productId : regularOrders[i].items[j].mProductId}",
                      csUomId: "${regularOrders[i].items[j].uom ? regularOrders[i].items[j].uom : regularOrders[i].items[j].uomData[0].csUomId}",
                      csTaxId: "${regularOrders[i].items[j].tax ? regularOrders[i].items[j].tax : regularOrders[i].items[j].cTaxId}",
                      qty: ${regularOrders[i].items[j].weight},
                      unitprice: ${parseFloat(regularOrders[i].items[j].realPrice) * (regularOrders[i].items[j].isReturn ? -1 : 1)},
                      netlist: ${parseFloat(regularOrders[i].items[j].netList)}
                      discount: ${regularOrders[i].items[j].discount},
                      returnline: ${regularOrders[i].items[j].isReturn ? regularOrders[i].items[j].isReturn : false},
                      returnQty: ${regularOrders[i].items[j].isReturn === true ? Math.abs(regularOrders[i].items[j].weight) : 0},
                      mBatchId: ${
                        regularOrders[i].items[j].hasOwnProperty("mBatchId") && regularOrders[i].items[j].mBatchId !== null
                          ? `"${regularOrders[i].items[j].mBatchId}"`
                          : regularOrders[i].items[j].hasOwnProperty("mBatch") && regularOrders[i].items[j].mBatch !== null
                          ? `"${regularOrders[i].items[j].mBatch}"`
                          : null
                      },
                      mPricingruleId: ${
                        regularOrders[i].items[j].mPricingruleId !== null && regularOrders[i].items[j].mPricingruleId !== undefined
                          ? `"${regularOrders[i].items[j].mPricingruleId}"`
                          : null
                      },
                      batchedForSale:"${regularOrders[i].items[j].batchedForSale}",
                      batchedForStock:"${regularOrders[i].items[j].batchedForStock}",
                      batchedProduct:"${regularOrders[i].items[j].batchedProduct}",
                      salesRepId: ${
                        regularOrders[i].items[j].salesRepId !== null && regularOrders[i].items[j].salesRepId !== undefined ? `"${regularOrders[i].items[j].salesRepId}"` : null
                      },
                      multiPrice: "${regularOrders[i].items[j].multiPrice}",
                      discountTypeId: null,
                      discountAmount: null,
                      metaData: [${linesMeta}],
                      unittax: ${unitTax > 0 ? parseFloat(unitTax.toFixed(precision)) * (regularOrders[i].items[j].isReturn ? -1 : 1) : 0}
                      linetax: ${parseFloat(regularOrders[i].items[j].linetax ? regularOrders[i].items[j].linetax : 0)}
                      linenet: ${regularOrders[i].items[j].linenet ? regularOrders[i].items[j].linenet : 0}
                      linegross: ${regularOrders[i].items[j].linegross ? regularOrders[i].items[j].linegross : 0}
                      netunit: ${Math.abs(regularOrders[i].items[j].grossunit) > 0 ? parseFloat((regularOrders[i].items[j].grossunit - unitTax).toFixed(precision)) : 0}
                      netstd: ${Math.abs(regularOrders[i].items[j].netStd) ? parseFloat(regularOrders[i].items[j].netStd) : 0}
                      listprice: ${regularOrders[i].items[j].listPrice ? regularOrders[i].items[j].listPrice : 0}
                      grossunit: ${regularOrders[i].items[j].grossunit ? regularOrders[i].items[j].grossunit : 0}
                      grossstd: ${Math.abs(regularOrders[i].items[j].sunitprice) > 0 ? regularOrders[i].items[j].sunitprice : 0}
                      grosslist: ${regularOrders[i].items[j].listPrice ? regularOrders[i].items[j].listPrice : 0}
                            tax:[{
                              csTaxID: "${regularOrders[i].items[j].tax ? regularOrders[i].items[j].tax : regularOrders[i].items[j].cTaxId}"
                              taxableAmt: ${parseFloat(regularOrders[i]?.items[j]?.linenet?.toFixed(precision))}
                              taxAmt: ${parseFloat(regularOrders[i].items[j].taxAmount.toFixed(precision))}
                          }]
                        }`);
                }
                const paymentsList = [];
                let amtMax = -100000000000000000000;
                let maxFinPaymentMethod;
                for (let k = 0; k < regularOrders[i]?.payments.length; k += 1) {
                  if (amtMax < parseFloat(regularOrders[i].payments[k].amount)) {
                    amtMax = parseFloat(regularOrders[i].payments[k].amount);
                    maxFinPaymentMethod = regularOrders[i].payments[k].finPaymentmethodId;
                  }
                  if (regularOrders[i].payments[k].name.toLowerCase() === "card") {
                    if (regularOrders[i].payments[k].integratedPayment === true) {
                      paymentsList.push(`{
                          finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                          amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                          transactionResponse: ${regularOrders[i].cardPaymentData?.response?.length > 0 ? `"${regularOrders[i].cardPaymentData?.response[0]?.resultMessage}"` : ""},
                          transactionId: null,
                          cardType: "",
                          cardNo: "",
                          transactionTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                          authorizationCode: null,
                          metaData: [{
                            key: "payload",
                            value: ${regularOrders[i].cardPaymentData?.payload?.length > 0 ? JSON.stringify(JSON.stringify(regularOrders[i].cardPaymentData?.payload[0])) : ""}
                          },
                          {
                            key: "response",
                            value: ${regularOrders[i].cardPaymentData?.response?.length > 0 ? JSON.stringify(JSON.stringify(regularOrders[i].cardPaymentData?.response[0])) : ""}
                          },
                        ]
                        }`);
                    } else {
                      paymentsList.push(`{
                          finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                          amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                          transactionResponse: null,
                          transactionId: ${regularOrders[i]?.cardPaymentData?.manual?.transactionId ? `"${regularOrders[i]?.cardPaymentData?.manual?.transactionId}"` : null},
                          cardType: ${regularOrders[i].cardPaymentData?.manual?.cardType ? `"${regularOrders[i].cardPaymentData?.manual?.cardType}"` : null},
                          cardNo: ${regularOrders[i].cardPaymentData?.manual?.cardNumber ? `"${regularOrders[i].cardPaymentData?.manual?.cardNumber}"` : null},
                          transactionTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                          authorizationCode: ${regularOrders[i]?.cardPaymentData?.manual?.authorization ? `"${regularOrders[i]?.cardPaymentData?.manual?.authorization}"` : null},
                          metaData: []
                        }`);
                    }
                  } else {
                    paymentsList.push(`{
                        finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                        amount: ${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}
                      }`);
                  }
                }
                let tableData = JSON.parse(localStorage.getItem("tableName"));
                let metaData = [];
                if (localStorage.getItem("dineIn") === "Y") {
                  const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                  localStorage.removeItem("tableName");
                  for (const key of keysToCheck) {
                    if (tableData?.hasOwnProperty(key)) {
                      metaData.push(`{
                        key: "${key}",
                        value: "${tableData[key]}"
                      }`);
                    }
                  }
                }
                if (regularOrders[i]?.orderTimeDetails) {
                  Object.keys(regularOrders[i].orderTimeDetails).map((obj) => {
                    metaData.push(`{
                        key: "${obj}",
                        value: "${regularOrders[i].orderTimeDetails[obj]}"
                      }`);
                  });
                }
                let giftCards = [];
                regularOrders[i]?.giftCardData?.map((res) => {
                  giftCards.push(`{
                      redeemRefNo: ${res.redemptionId ? `"${res.redemptionId}"` : null},
                      cardNo:${res.number ? `"${res.number}"` : null},
                      referenceNo: ${res.refId ? `"${res.refId}"` : null}
                    }`);
                });

                const paramsInput = {
                  query: `mutation{
                posOrderProcessor(posOrder:{
                sOrderID: "${regularOrders[i].sOrderID}",
                cSClientID: "${tillData.tillAccess.csClientId}",
                cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
                created: "${regularOrders[i].orderTime}",
                createdby: "${regularOrders[i].createdBy}",
                updated: "${regularOrders[i].orderTime}",
                updatedby: "${regularOrders[i].createdBy}",
                csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
                sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
                sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
                sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
                sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
                documentno: "${regularOrders[i].documentno}",
                dateordered: "${regularOrders[i].orderTime}",
                datepromised: "${regularOrders[i].orderTime}",
                csPaymenttermID: null,
                finPaymentmethodId: ${regularOrders[i].layAway === "N" ? (maxFinPaymentMethod ? `"${maxFinPaymentMethod}"` : null) : null},
                csCurrencyId: "${csCurrencyId}",
                mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
                cwrLongitude: "",
                cwrLatitude: "",
                csUserId: "${tillData.tillAccess.csUserId}",
                cwrB2cCustomerId: "${regularOrders[i].customer.cwrCustomerId}",
                orderreference: "",
                cwrPayref: "",
                cwrPayremarks: "",
                description: "${regularOrders[i].description !== undefined && regularOrders[i].description !== "" ? regularOrders[i].description : ""}",
                storeDailyOpsTillid: "${localStorage.getItem("storeDailyOpsTillid")}",
                cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
                redemption: ${regularOrders[i].redemptionPoints},
                accumulation: ${regularOrders[i].accumulationPoints},
                redeemRefId: "${regularOrders[i].referenceId}",
                roundoff: ${regularOrders[i].roundOff.toFixed(precision)},
                cwrProductQty: ${regularOrders[i].totalQty},
                cwrProductCount: ${regularOrders[i].totalQty},
                ofdStatus: "Delivered",
                ofdIspaid: "Y",
                mPricingruleId: ${regularOrders[i].mPricingruleId !== null && regularOrders[i].mPricingruleId !== undefined ? `"${regularOrders[i].mPricingruleId}"` : null},
                cwrSaletypeId: "${regularOrders[i].orderType}",
                salesRepId:${regularOrders[i].salesRepId !== null && regularOrders[i].salesRepId !== undefined ? `"${regularOrders[i].salesRepId}"` : null},
                discAmount: ${parseFloat(regularOrders[i].discount.toFixed(precision))},
                creditAmount: ${regularOrders[i].creditAmount},
                metaData: [${metaData}], 
                giftCard:[${giftCards}],
                pricingCoupon:[{
                  mPricingCouponId: ${regularOrders[i]?.couponInput?.length > 0 ? `"${regularOrders[i].couponInput[0].mPricingCouponId}"` : null},
                  redemptionCount:${regularOrders[i].couponRedemptionCount ? parseInt(regularOrders[i].couponRedemptionCount) : null},
                  referenceId:${regularOrders[i]?.couponInput?.length > 0 ? `"${regularOrders[i].couponInput[0].referenceId}"` : null}
                }]
                orderTotal: ${regularOrders[i].total}
                nettotal: ${parseFloat((regularOrders[i].total - regularOrders[i].tax).toFixed(2))}
                taxamt: ${parseFloat(regularOrders[i].tax.toFixed(2))}
                isReturn: ${regularOrders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
                sOrderReturnId: ${regularOrders[i].items?.[0]?.sOrderReturnId ? `"${regularOrders[i].items?.[0]?.sOrderReturnId}"` : null},
                layAway: ${regularOrders[i].layAway ? `"${regularOrders[i].layAway}"` : null},
                payments: [${paymentsList}],
                tax: ${regularOrders[i].combinedTaxRates ? `[${regularOrders[i].combinedTaxRates}]` : null}
                line: [${orderLines}],
                })
              {
                documentno 
                status
                message
              }
              }
              `,
                };
                Axios({
                  url: serverUrl,
                  method: "POST",
                  data: paramsInput,
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${setAuthTokens}`,
                  },
                })
                  .then(async (response) => {
                    const result = response.data.data.posOrderProcessor;
                    const { status } = result;
                    if (status === "200") {
                      console.info(`Order ${regularOrders[i].documentno} synced to Server`);
                      db.orders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 1 });
                      let rfidData = [];
                      await db.rfidData.toArray((products) => {
                        products.map((ele) => {
                          if (ele.tag_status === "SO") {
                            rfidData.push(` {
                              tagValue: "${ele.tag_value}"
                              taggingDate: null
                              batchNumber: null
                              batchId: null
                              warehouseId: null
                              tagStatus: "SO"
                              lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                              scannedBy: null
                              expirydate: null
                              customAttribute: null
                              tagType: null
                              productCode: "${ele.product_code}"
                              }`);
                            //  db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
                          }
                        });
                      });
                      await Axios({
                        url: serverUrl,
                        method: "POST",
                        data: {
                          query: `mutation{
                            RFIDTag(rfidTag:[${rfidData}]){
                            status
                            message
                            }
                            }`,
                        },
                        headers: {
                          "Content-Type": "Application/json",
                          Authorization: `${setAuthTokens}`,
                        },
                      });
                    } else {
                      console.error("Failed Order Sync ====> ", response);
                      const syncFailedCount = parseInt(regularOrders[i].syncAttempts) + 1;
                      if (parseInt(regularOrders[i].syncAttempts) < 100) {
                        db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                      } else {
                        db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 2 });
                      }
                    }
                  })
                  .catch((error) => {
                    // const err = JSON.parse(JSON.stringify(error));
                    // const { message } = err;
                    // if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
                    //   const refToken = tokens.refresh_token;
                    //   const requestBody = {
                    //     grant_type: "refresh_token",
                    //     refresh_token: refToken,
                    //   };
                    //   const config = {
                    //     headers: {
                    //       "Content-Type": "application/x-www-form-urlencoded",
                    //       Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
                    //     },
                    //   };
                    //   Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
                    //     setAuthTokens=newToken.data;
                    //   });
                    // } else {
                    //   const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    //   if (parseInt(orders[i].syncAttempts) < 100) {
                    //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    //   } else {
                    //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    //   }
                    // }
                  });
              } else {
                const layAwayOrders = orders.filter((order) => {
                  return order.layAway === "Y" && order.paid !== 0;
                });

                // const regularOrders = orders.filter(order => order.layAway !== "Y");
                //  console.log(layAwayOrders,"=======>lay")
                if (layAwayOrders.length > 0) {
                  // console.log(layAwayOrders,"->layawayirders")
                  const orderLines = [];
                  for (let j = 0; j < layAwayOrders[i].items.length; j += 1) {
                    let unitPrice;
                    if (layAwayOrders[i].items[j].weight > 0) {
                      unitPrice = layAwayOrders[i].items[j].nettotal / layAwayOrders[i].items[j].weight;
                      unitPrice = parseFloat(unitPrice).toFixed(2);
                    } else {
                      unitPrice = 0;
                    }
                    orderLines.push(`{
                  sOrderlineID: ${layAwayOrders[i].items[j].sOrderlineID ? `"${layAwayOrders[i].items[j].sOrderlineID}"` : `"${uuidv4().replace(/-/g, "").toUpperCase()}"`},
                  sOrderlineReturnId: ${layAwayOrders[i].items[j].sOrderlineReturnId ? `"${layAwayOrders[i].items[j].sOrderlineReturnId}"` : null}
                  created: "${layAwayOrders[i].orderTime}",
                  createdby: "${layAwayOrders[i].createdBy}",
                  updated: "${layAwayOrders[i].orderTime}",
                  updatedby: "${layAwayOrders[i].createdBy}",
                  sOrderId: "${layAwayOrders[i].sOrderID}",
                  line: ${(j + 1) * 10},
                  description: "",
                  mProductId: "${layAwayOrders[i].items[j].productId ? layAwayOrders[i].items[j].productId : layAwayOrders[i].items[j].product.mProductId}",
                  csUomId: ${
                    layAwayOrders[i].items[j].productId
                      ? `"${layAwayOrders[i].items[j].uom}"`
                      : layAwayOrders[i].items[j].uom && layAwayOrders[i].items[j].uom.csUomId
                      ? `"${layAwayOrders[i].items[j].uom.csUomId}"`
                      : null
                  },                 
                  csTaxId: ${
                    layAwayOrders[i].items[j].productId
                      ? `"${layAwayOrders[i].items[j].tax}"`
                      : layAwayOrders[i].items[j].tax?.csTaxID
                      ? `"${layAwayOrders[i].items[j].tax.csTaxID}"`
                      : null
                  }
                  qty: ${layAwayOrders[i].items[j].weight},
                  unitprice: ${unitPrice ? unitPrice : 0},
                  netlist: ${layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].salePrice : 0}
                  discount: ${layAwayOrders[i].items[j].discount},
                  returnline: ${
                    layAwayOrders[i].items[j].isReturn !== undefined && layAwayOrders[i].items[j].isReturn !== null
                      ? layAwayOrders[i].items[j].isReturn
                      : layAwayOrders[i].items[j].returnline
                  },
                  returnQty: ${layAwayOrders[i].items[j].isReturn === true ? Math.abs(layAwayOrders[i].items[j].weight) : 0},
                  mBatchId: ${layAwayOrders[i].items[j].mBatchId ? `"${layAwayOrders[i].items[j].mBatchId}"` : null},
                  mPricingruleId: ${
                    layAwayOrders[i].items[j].mPricingruleId !== null && layAwayOrders[i].items[j].mPricingruleId !== undefined
                      ? `"${layAwayOrders[i].items[j].mPricingruleId}"`
                      : null
                  },
                  batchedForSale:${layAwayOrders[i].items[j].batchedForSale ? `"${layAwayOrders[i].items[j].batchedForSale}"` : null},
                  batchedForStock:${layAwayOrders[i].items[j].batchedForStock ? `"${layAwayOrders[i].items[j].batchedForStock}"` : null},
                  batchedProduct:${layAwayOrders[i].items[j].batchedProduct ? `"${layAwayOrders[i].items[j].batchedProduct}"` : null},
                  salesRepId: ${
                    layAwayOrders[i].items[j].salesRepId !== null && layAwayOrders[i].items[j].salesRepId !== undefined ? `"${layAwayOrders[i].items[j].salesRepId}"` : null
                  },
                  multiPrice: ${layAwayOrders[i].items[j].multiPrice ? `"${layAwayOrders[i].items[j].multiPrice}"` : null},
                  discountTypeId: null,
                  discountAmount: null,
                  unittax: ${
                    layAwayOrders[i].items[j].weight > 0 && layAwayOrders[i].items[j].taxRate > 0
                      ? layAwayOrders[i].items[j]?.unitTax
                        ? layAwayOrders[i].items[j]?.unitTax
                        : layAwayOrders[i].items[j]?.unittax
                      : 0
                  },
                  linetax: ${parseFloat(layAwayOrders[i].items[j].linetax ? layAwayOrders[i].items[j].linetax : 0)},
                  linenet: ${layAwayOrders[i].items[j].linenet ? layAwayOrders[i].items[j].linenet : 0},
                  linegross: ${layAwayOrders[i].items[j].linegross ? layAwayOrders[i].items[j].linegross : 0},
                  netunit: ${Math.abs(layAwayOrders[i].items[j].netunit)},
                  listprice: ${layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].listPrice : 0},
                  grossunit: ${layAwayOrders[i].items[j].grossunit ? layAwayOrders[i].items[j].grossunit : 0},
                  grossstd: ${layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].sunitprice : 0},
                  grosslist: ${layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].listPrice : 0},
                            tax:[{
                              csTaxID: "${layAwayOrders[i].taxDetails[0]?.csTaxID}"
                              taxableAmt: ${parseFloat(layAwayOrders[i].items[j].linenet?.toFixed(precision))}
                              taxAmt: ${parseFloat(layAwayOrders[i].items[j].taxAmount.toFixed(precision))}
                          }]
                    }`);
                  }
                  const paymentsList = [];
                  let amtMax = -100000000000000000000;
                  let maxFinPaymentMethod = null;
                  for (let k = 0; k < layAwayOrders[i].payments.length; k += 1) {
                    if (amtMax < parseFloat(layAwayOrders[i].payments[k].amount)) {
                      amtMax = parseFloat(layAwayOrders[i].payments[k].amount);
                      maxFinPaymentMethod = layAwayOrders[i].payments[k].finPaymentmethodId;
                    }
                    paymentsList.push(`{
                      finPaymentmethodID: "${layAwayOrders[i].payments[k].finPaymentmethodId}",
                      amount: ${layAwayOrders[i].payments[k].amount}
                    }`);
                  }
                  let tableData = JSON.parse(localStorage.getItem("tableName"));
                  let metaData = [];
                  if (localStorage.getItem("dineIn") === "Y") {
                    const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                    localStorage.removeItem("tableName");
                    for (const key of keysToCheck) {
                      if (tableData.hasOwnProperty(key)) {
                        metaData.push(`{
                          key: "${key}",
                          value: "${tableData[key]}"
                        }`);
                      }
                    }
                  }
                  const paramsInput = {
                    query: `mutation{
                      confirmPOSLayawayOrder(posOrder:{
                  sOrderID: "${layAwayOrders[i].sOrderID}",
                  cSClientID: "${tillData.tillAccess.csClientId}",
                  cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
                  created: "${layAwayOrders[i].orderTime}",
                  createdby: "${layAwayOrders[i].createdBy}",
                  updated: "${layAwayOrders[i].orderTime}",
                  updatedby: "${layAwayOrders[i].createdBy}",
                  csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
                  sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
                  sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
                  sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
                  sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
                  documentno: "${layAwayOrders[i].documentno}",
                  dateordered: "${layAwayOrders[i].orderTime}",
                  datepromised: "${layAwayOrders[i].orderTime}",
                  csPaymenttermID: null,
                  finPaymentmethodId: ${maxFinPaymentMethod ? `"${maxFinPaymentMethod}"` : null},
                  csCurrencyId: "${csCurrencyId}",
                  mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
                  cwrLongitude: "",
                  cwrLatitude: "",
                  csUserId: "${tillData.tillAccess.csUserId}",
                  cwrB2cCustomerId: "${layAwayOrders[i].customer.cwrCustomerId}",
                  orderreference: "",
                  cwrPayref: "",
                  cwrPayremarks: "",
                  description: "",
                  cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
                  redemption: ${layAwayOrders[i].redemptionPoints ? layAwayOrders[i].redemptionPoints : null},
                  accumulation: ${layAwayOrders[i].accumulationPoints ? layAwayOrders[i].accumulationPoints : null},
                  roundoff: ${Math.abs(layAwayOrders[i].roundOff.toFixed(precision))},
                  cwrProductQty: ${layAwayOrders[i].totalQty},
                  cwrProductCount: ${layAwayOrders[i].totalQty},
                  ofdStatus: "Delivered",
                  ofdIspaid: "Y",
                  mPricingruleId: ${layAwayOrders[i].mPricingruleId !== null && layAwayOrders[i].mPricingruleId !== undefined ? `"${layAwayOrders[i].mPricingruleId}"` : null},
                  cwrSaletypeId: "${layAwayOrders[i].orderType}",
                  salesRepId:${layAwayOrders[i].salesRepId !== null && layAwayOrders[i].salesRepId !== undefined ? `"${layAwayOrders[i].salesRepId}"` : null},
                  discAmount: ${layAwayOrders[i].discount},
                  creditAmount: ${layAwayOrders[i].creditAmount},
                  metaData: [${metaData}], 
                  pricingCoupon:{
                    mPricingCouponId:${layAwayOrders[i].mPricingCouponId ? `"${layAwayOrders[i].mPricingCouponId}"` : null},
                    redemptionCount:${layAwayOrders[i].couponRedemptionCount ? parseInt(layAwayOrders[i].couponRedemptionCount) : null},
                    referenceId:${layAwayOrders[i]?.couponInput?.length > 0 ? `"${layAwayOrders[i].couponInput[0].referenceId}"` : null}
                  }
                  orderTotal: ${layAwayOrders[i].total}
                  isReturn: ${layAwayOrders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
                  sOrderReturnId: ${layAwayOrders[i].items?.[0]?.sOrderReturnId ? `"${layAwayOrders[i].items?.[0]?.sOrderReturnId}"` : null},
                  layAway: "${layAwayOrders[i].layAway}",
                  nettotal: ${parseFloat((layAwayOrders[i].total - layAwayOrders[i].tax).toFixed(2))}
                  taxamt: ${parseFloat(layAwayOrders[i].tax.toFixed(2))}
                  payments: [${paymentsList}],
                  line: [${orderLines}],
                  })
                {
                  documentno 
                  status
                  message
                  }
                }
                `,
                  };
                  // console.log(paramsInput,"-->confirm")
                  Axios({
                    url: serverUrl,
                    method: "POST",
                    data: paramsInput,
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${setAuthTokens}`,
                    },
                  })
                    .then(async (response) => {
                      // console.log(response,"---------------->statusText")
                      // console.log(response.data.data.confirmPOSLayawayOrder.status,"---------------->statusText1",response.statusText)
                      //  const result = response.data.data.data.confirmPOSLayawayOrder;
                      //  console.log(result,"-------->result")
                      if (response.statusText === "200") {
                        console.log(response, "----------->response");
                        console.info(`Order ${layAwayOrders[i].documentno} synced to Server`);
                        db.orders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 1 });
                        let rfidData = [];
                        await db.rfidData.toArray((products) => {
                          products.map((ele) => {
                            if (ele.tag_status === "SO") {
                              rfidData.push(` {
                                  tagValue: "${ele.tag_value}"
                                  taggingDate: null
                                  batchNumber: null
                                  batchId: null
                                  warehouseId: null
                                  tagStatus: "SO"
                                  lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                                  scannedBy: null
                                  expirydate: null
                                  customAttribute: null
                                  tagType: null
                                  productCode: "${ele.product_code}"
                                  }`);
                              //  db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
                            }
                          });
                        });
                        await Axios({
                          url: serverUrl,
                          method: "POST",
                          data: {
                            query: `mutation{
                              RFIDTag(rfidTag:[${rfidData}]){
                              status
                              message
                              }
                              }`,
                          },
                          headers: {
                            "Content-Type": "Application/json",
                            Authorization: `${setAuthTokens}`,
                          },
                        });
                      } else {
                        console.error("Failed Order Sync ====> ", response);
                        const syncFailedCount = parseInt(layAwayOrders[i].syncAttempts) + 1;
                        if (parseInt(layAwayOrders[i].syncAttempts) < 100) {
                          db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                        } else {
                          db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 2 });
                        }
                      }
                    })
                    .catch((error) => {
                      // const err = JSON.parse(JSON.stringify(error));
                      // const { message } = err;
                      // if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
                      //   const refToken = tokens.refresh_token;
                      //   const requestBody = {
                      //     grant_type: "refresh_token",
                      //     refresh_token: refToken,
                      //   };
                      //   const config = {
                      //     headers: {
                      //       "Content-Type": "application/x-www-form-urlencoded",
                      //       Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
                      //     },
                      //   };
                      //   Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
                      //     setAuthTokens=newToken.data;
                      //   });
                      // } else {
                      //   const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                      //   if (parseInt(orders[i].syncAttempts) < 100) {
                      //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                      //   } else {
                      //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                      //   }
                      // }
                    });
                }
              }
            }
          }
        });
    }
    if (syncTrigger === "orderHistory") {
      showOrderHistory();
    }
  };

  useEffect(() => {
    const syncOrdersInterval = setInterval(() => syncOrders(), 10000);
    return () => {
      clearTimeout(syncOrdersInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // ORDER SYNC BLOCK END

  const handleCloseModal = () => {
    history.push("/dashboard");
  };

  const confirmDiscardCart = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        className: "parkedItemsClass",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        autoFocusButton: null,
        onOk() {
          parkBill();
          setTimeout(() => {
            history.push("/dashboard");
          }, 700);
        },
        onCancel: () => {
          handleCloseModal();
        },
      });
    } else {
      history.push("/dashboard");
    }
  };

  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [couponInput, setCouponInput] = useState("");

  const closeCouponModal = () => {
    setCouponModalVisible(false);
    setCouponInput("");
    setIsInputFocused(false);
  };

  const handleCouponInput = (value) => {
    if (value === "clear") {
      setCouponInput("");
    } else if (value === "x") {
      setCouponInput(`${couponInput.toString().substring(0, couponInput.toString().length - 1)}`);
    } else {
      setCouponInput(`${couponInput}${value}`);
    }
  };

  const checkCoupon = async () => {
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    let uniqReferenceId = uuidv4().replace(/-/g, "").toUpperCase();
    let couponFlag = true;
    cart?.couponInput?.forEach((coupon) => {
      if (coupon.couponCode === couponInput) {
        couponFlag = false;
      }
      // discardCoupon(coupon);
    });
    if (couponFlag) {
      const verifyCouponResponse = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query{
                  verifyCoupons(couponcode:"${couponInput}",referenceId: "${uniqReferenceId}"){
                  mPricingCouponId
                  csClientId
                  csBunitId
                  created
                  createdBy
                  updated
                  updatedBy
                  line
                  mPricingRulesId
                  couponcode
                  status
                  redemptionCount
                  usedDate
                  upc
              }
          }
            `,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      }).catch((error) => {
        Sentry.captureException(error);
      });
      const { verifyCoupons } = verifyCouponResponse?.data?.data;
      if (verifyCoupons?.length > 0) {
        if (verifyCoupons[0].status === "A") {
          const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(verifyCoupons[0].mPricingRulesId).toArray();
          // console.log(matchingPricingRules);
          let discardCoupon = false;
          if (matchingPricingRules.length > 0) {
            setCouponModalVisible(false);
            setCouponInput("");
            const pricingRule = matchingPricingRules[0];
            let finalObj;
            if (pricingRule.type === "TD" && pricingRule.iscoupon === "Y") {
              if (dateValidator(pricingRule.startDate, pricingRule.endDate)) {
                if (pricingRule.timeSpecific === "Y") {
                  const weekDay = currentDay();
                  const pStartTime = pricingRule.starttime.substring(11);
                  const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
                  const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                  const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                  if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                    finalObj = processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId);
                  }
                } else {
                  finalObj = processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId);
                }
              }
              setCart(finalObj);
              let filteredCoupon = finalObj?.couponInput?.findIndex((item) => item.mPricingCouponId === verifyCoupons[0].mPricingCouponId);
              if (filteredCoupon >= 0) {
                message.success(`Coupon Applied: ${pricingRule.printedName}`);
              } else {
                discardCoupon = true;
              }
            }

            if (pricingRule.type === "TDF" && pricingRule.iscoupon === "Y") {
              if (dateValidator(pricingRule.startDate, pricingRule.endDate)) {
                if (pricingRule.timeSpecific === "Y") {
                  const weekDay = currentDay();
                  const pStartTime = pricingRule.starttime.substring(11);
                  const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
                  const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                  const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                  if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                    processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId, verifyCoupons[0].mPricingRulesId);
                  }
                } else {
                  processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId, verifyCoupons[0].mPricingRulesId);
                }
              }
            }

            if (pricingRule.type !== "TDF" && pricingRule.iscoupon === "Y" && pricingRule.type !== "TD") {
              let addToCart = cart.items.filter((item) => pricingRule.mPricingXProducts.some((pro) => item.productId === pro.mProductId));

              // Check if the filtered array is empty
              if (addToCart.length === 0 && cart.items.length > 0) {
                addToCart = cart.items[cart.items.length - 1]; // Add the last item of the cart
              } else {
                addToCart = addToCart[0];
              }

              let cartObj = cart;
              let iscoupon = true;
              let expiryDiscount = localStorage.getItem("expiryDiscount") !== null && localStorage.getItem("expiryDiscount") === "Y" ? true : false;
              let updatedCart = expiryDiscount
                ? cartObj
                : await pricingRuleController(
                    addToCart,
                    cartObj,
                    cart,
                    setCart,
                    cartRef,
                    orderType,
                    iscoupon,
                    couponInput,
                    uniqReferenceId,
                    verifyCoupons[0].mPricingCouponId,
                    verifyCoupons[0].mPricingRulesId
                  );
              let updatedTotalTax = 0;
              let updatedTotalPrice = 0;
              let updatedTotalItemsQty = 0;
              let updatedTotalDiscounts = 0;
              for (let i = 0; i < updatedCart.items.length; i += 1) {
                updatedTotalPrice += updatedCart.items[i].nettotal;
                updatedTotalItemsQty += updatedCart.items[i].weight;
                updatedTotalTax += updatedCart.items[i].taxAmount;
                updatedTotalDiscounts += updatedCart.items[i].discount;
                updatedCart.items[i].key = i;
              }

              const updatedRoundOffValue = Math.round(updatedTotalPrice);
              const updatedTotalRoundOff = updatedTotalPrice - updatedRoundOffValue;

              let finalCartObj = {
                ...updatedCart,
                items: [...updatedCart.items],
                total: parseFloat(updatedTotalPrice.toFixed(precision)),
                tax: parseFloat(updatedTotalTax.toFixed(precision)),
                discount: parseFloat(updatedTotalDiscounts.toFixed(precision)),
                totalQty: updatedTotalItemsQty,
                roundOff: parseFloat(updatedTotalRoundOff.toFixed(precision)),
              };
              setCart(finalCartObj);
              localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
              let filteredCoupon =
                finalCartObj?.couponInput?.length > 0 ? finalCartObj?.couponInput?.findIndex((item) => item.mPricingCouponId === verifyCoupons[0].mPricingCouponId) : -1;
              if (filteredCoupon >= 0) {
                message.success(`Coupon Applied: ${pricingRule.printedName}`);
              } else {
                discardCoupon = true;
              }
            }

            if (discardCoupon) {
              const serverUrl = process.env.REACT_APP_serverUrl;
              const couponsData = [{ couponCode: couponInput, referenceId: uniqReferenceId }];
              // Function to discard a coupon
              const discardCoupon = async (coupon) => {
                const discardCouponQuery = {
                  query: `query {
                      discardCoupon(couponcode: "${coupon.couponCode}", referenceId: "${coupon.referenceId}") {
                        status
                        message
                      }
                    }`,
                };
                try {
                  const response = await Axios({
                    url: serverUrl,
                    method: "POST",
                    data: discardCouponQuery,
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${setAuthTokens}`,
                    },
                  });

                  console.log(`Coupon ${coupon.couponCode} discarded:`, response.data);
                } catch (error) {
                  console.error(`Error discarding coupon ${coupon.couponCode}:`, error);
                }
              };
              couponsData.forEach((coupon) => {
                discardCoupon(coupon);
              });
              if (pricingRule.type === "TD" || pricingRule.type === "TDF") {
                finalObj.totalBillDicount = null;
                setCart(finalObj);
              }
            }
            upsertPOSLog(cart, "CUP");
          } else {
            message.warning("No discount available. Please check the coupon code and try again.");
          }
        } else {
          message.warning("This coupon has expired. Please use a different coupon.");
        }
      } else {
        Sentry.captureException(new Error("Coupon code failed"), {
          extra: {
            couponcode: couponInput,
            referenceId: uniqReferenceId,
          },
        });
        message.warning(`${t("coupon_error")}`);
      }
    } else {
      message.warning("Multiple entries found for this coupon code. Please contact technical support for assistance.");
    }
    setIsInputFocused(false);
  };

  const removeCoupon = async (coupon) => {
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    const discardCoupon = async () => {
      const discardCouponQuery = {
        query: `query {
            discardCoupon(couponcode: "${coupon.couponCode}", referenceId: "${coupon.referenceId}") {
              status
              message
            }
          }`,
      };
      try {
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: discardCouponQuery,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });
      } catch (error) {
        console.error(`Error discarding coupon ${coupon.couponCode}:`, error);
      }
    };
    discardCoupon();
    let latestData = removeAllDiscounts();

    let cartObj = {
      ...latestData,
      customer: defaultCustomer,
    };
    cartObj.couponInput = cartObj.couponInput.filter((couponCode) => couponCode.mPricingCouponId !== coupon.mPricingCouponId);
    let updatedCart = cartObj;
    let cart = cartObj;

    if (cartObj.items.length > 0) {
      await Promise.all(
        cartObj.items.map(async (ele) => {
          let addToCart = ele;
          addToCart.nettotal = parseFloat(addToCart.nettotal.toFixed(precision));
          updatedCart = await pricingRuleController(addToCart, cartObj, cartObj, setCart, cartObj, orderType);
          return updatedCart;
        })
      );
    }
    //  else {
    //   let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
    //     ? JSON.parse(localStorage.getItem("orderTimeDetails"))
    //     : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
    //   orderTimeDetails = {
    //     ...orderTimeDetails,
    //     orderStartTime: "", // Update orderStartTime to current time
    //   };
    //   localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    // }

    if (cartObj.totalDiscountFlag) {
      await openPaymentModalByCustomer(updatedCart);
    }

    if (updatedCart?.couponInput?.length > 0) {
      let addToCart = cartObj.items[0];
      await Promise.all(
        updatedCart.couponInput.map(async (coupon) => {
          const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(coupon.mPricingruleId).toArray();
          if (matchingPricingRules[0].type !== "TD" && matchingPricingRules[0].type !== "TDF") {
            updatedCart = await pricingRuleController(
              addToCart,
              updatedCart,
              cart,
              setCart,
              cartRef,
              orderType,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          } else {
            updatedCart = await processBillDiscounts(
              matchingPricingRules[0],
              updatedCart,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          }
        })
      );
    }

    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;

    updatedCart.items = updatedCart.items.map((item, i) => {
      const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
      const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
      const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

      // Update aggregated totals
      updatedTotalPrice += nettotalFixed;
      updatedTotalItemsQty += item.weight;
      updatedTotalTax += taxAmountFixed;
      updatedTotalDiscounts += discountFixed;

      // Update individual item properties
      item.discount = discountFixed;
      item.key = i;
      item.nettotal = parseFloat(item.nettotal.toFixed(precision));

      if (!item.isGiftCard) {
        let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
        if (!isFinite(unitPrice)) unitPrice = 0;

        const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
        const netList = (item.listPrice - item.listPrice / (1 + item.taxRate / 100)).toFixed(precision);

        item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
        item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
        item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
        item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
        item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
        item.grosslist = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
        item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
        item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      }
      return item;
    });

    const sumLineTotals = (lines) => {
      let totalLineGross = 0;
      let totalLineTax = 0;

      lines.forEach((line) => {
        totalLineGross += line.nettotal;
        totalLineTax += line.taxAmount;
      });

      return { totalLineGross, totalLineTax };
    };
    // Compare and Adjust Order Totals
    const adjustOrderTotals = (order, lines) => {
      const { totalLineGross, totalLineTax } = sumLineTotals(lines);
      let adjustedOrderGross = order.total;
      let adjustedOrderTax = order.tax;

      const grossDifference = totalLineGross - adjustedOrderGross;
      const taxDifference = totalLineTax - adjustedOrderTax;

      if (Math.abs(grossDifference) > 0.01) {
        adjustedOrderGross += grossDifference;
      }

      if (Math.abs(taxDifference) > 0.01) {
        adjustedOrderTax += taxDifference;
      }

      return {
        ...order,
        total: adjustedOrderGross,
        tax: adjustedOrderTax,
      };
    };
    updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);

    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
    };
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    setCart({ ...finalCartObj });
  };

  // Side Menu Drawer Starts //

  const [sideMenuDrawervisible, setSideMenuDrawervisible] = useState(false);
  const openSideMenu = () => {
    setSideMenuDrawervisible(true);
  };
  const onClose = () => {
    setSideMenuDrawervisible(false);
  };

  const [displayReturnOrderSearch, setDisplayReturnOrderSearch] = useState(false);

  const handleSalesReturnFromSideMenu = () => {
    setSideMenuDrawervisible(false);
    setDisplayReturnOrderSearch(true);
  };

  // Side Menu Drawer Ends //

  // OMS Orders Modal Starts //

  const omsOrderDetailsList = JSON.parse(localStorage.getItem("omsOrderDetails"));
  const initialOmsOrderList = omsOrderDetailsList ? omsOrderDetailsList : [];
  const [omsOrdersList, setOmsOrderList] = useState(initialOmsOrderList);

  const omsOrderStatus = [
    {
      title: "New",
      imgSrc: NewWhite,
      selectedImgSrc: New,
      statusValue: "NE",
    },
    {
      title: "Preparing",
      imgSrc: PreparingWhite,
      selectedImgSrc: Preparing,
      statusValue: "UP",
    },
    {
      title: "Ready",
      imgSrc: ReadyWhite,
      selectedImgSrc: Ready,
      statusValue: "PK",
    },
    {
      title: "Today's Orders",
      imgSrc: CompletedWhite,
      selectedImgSrc: Completed,
      statusValue: "DE",
    },
  ];

  const [displayOMSOrderItemsModal, setDisplayOMSOrderItemsModal] = useState(false);
  const [selectedOMSOrderStatus, setSelectedOMSOrderStatus] = useState(omsOrderStatus[0]);

  const [order, setOrder] = useState("");
  const [searchOrders, setSearchOrders] = useState(omsOrdersList);

  const [selectedOrder, setSelectedOrder] = useState({});

  const handleOmsOrders = () => {
    setSideMenuDrawervisible(false);
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        if (data?.length > 0) {
          data?.map((item) => {
            let time = new Date(item.orderTime);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            let newLineItems = item?.items?.map((itemLine) => ({
              ...itemLine,
              price: itemLine?.salePrice || 0,
              quantity: itemLine?.weight || 0,
            }));
            let orderObj = {
              cWCOrderId: item.sOrderID || "",
              customerId: item?.customer?.cwrCustomerId || "",
              customerName: item?.customer?.name || "",
              lineItems: [...newLineItems],
              mobileNo: item?.customer?.mobileNo,
              noOfItems: item?.items?.length,
              orderNo: item.documentno,
              posOrders: "Y",
              status: "NE",
              total: item?.total || 0,
              totalQty: item?.totalQty || 0,
              dateCreated: newTime,
            };
            let findIndexOrder = _.findIndex(omsOrdersList, ["cWCOrderId", orderObj.cWCOrderId]);
            if (findIndexOrder === -1) {
              omsOrdersList.push(orderObj);
            } else {
              omsOrdersList[findIndexOrder] = orderObj;
            }
            return null;
          });
        }
      });
    const paramsInput = {
      query: `query{
          getNewOmsOrders(bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
          cWCOrderId
          orderNo
          dateCreated
          customerId
          customerName
          mobileNo
          total
          noOfItems
          totalQty
          lineItems{
              productId
              name
              productCode
              quantity
              price
              subTotal
              addOnProducts{
                  id
                  name
                  price
              }
          }
      }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.access_token}`,
      },
    }).then((response) => {
      const { getNewOmsOrders } = response.data.data;
      getNewOmsOrders?.map((item, index) => {
        let obj = { ...item };
        obj.status = "NE";
        obj.noOfItems = item.lineItems.length;
        obj.posOrders = "N";
        let time = new Date(item.dateCreated);
        let newTime = time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        obj.dateCreated = newTime;
        let findIndex = _.findIndex(omsOrdersList, ["cWCOrderId", item.cWCOrderId]);
        if (findIndex === -1) {
          omsOrdersList.push(obj);
        } else {
          omsOrdersList[findIndex] = obj;
          let prodCode = _.map(omsOrdersList?.[findIndex]?.lineItems || [], "productCode");
          db.products
            .where("value")
            .startsWithAnyOfIgnoreCase(prodCode)
            .toArray()
            .then((productsFetched) => {
              if (productsFetched?.length > 0) {
                let newLineItems = [...omsOrdersList?.[findIndex]?.lineItems].map((itemLine) => {
                  let itemImageIndex = _.findIndex(productsFetched, (item) => item.value === itemLine.productCode);
                  return itemImageIndex >= 0
                    ? {
                        ...itemLine,
                        img: productsFetched[itemImageIndex]?.imageurl,
                      }
                    : { ...itemLine };
                });
                omsOrdersList[findIndex].lineItems = [...newLineItems];
              }
            });
        }
        return null;
      });
      localStorage.setItem("omsOrderDetails", JSON.stringify([...omsOrdersList]));
      setOmsOrderList([...omsOrdersList]);
    });
    setDisplayOMSOrderItemsModal(true);
  };

  const handleOMSOrderStatusSelection = (record) => {
    setSelectedOMSOrderStatus(record);
  };

  const setOMSStatus = (status) => {
    let newOMSStatus = "";
    switch (status) {
      case "NE":
        newOMSStatus = "UP";
        break;
      case "UP":
        newOMSStatus = "PK";
        break;
      case "PK":
        newOMSStatus = "DE";
        break;
      default:
        newOMSStatus = "NE";
    }
    return newOMSStatus;
  };

  const nextOMSOrderStatus = () => {
    let newOMSOrderStatus = [...omsOrderStatus];
    let statusIndex = _.findIndex(newOMSOrderStatus, (item) => item.title === selectedOMSOrderStatus.title);
    if (statusIndex <= newOMSOrderStatus.length - 1) setSelectedOMSOrderStatus(newOMSOrderStatus[statusIndex + 1]);
  };

  const handleOMSStatusButton = (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
          updateOMSOrderStatus(order:{
              cWCOrderId: "${record.cWCOrderId}"
              status: "${setOMSStatus(record.status)}"
          })
          {
              status
              message
          }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.data.updateOMSOrderStatus.status === "200") {
        let newOMSOrderDetails = [...omsOrdersList];
        let recordIndex = _.findIndex(newOMSOrderDetails, ["cWCOrderId", record.cWCOrderId]);
        newOMSOrderDetails[recordIndex].status = setOMSStatus(record.status);
        localStorage.setItem("omsOrderDetails", JSON.stringify(omsOrdersList));
        setOmsOrderList([...newOMSOrderDetails]);
        nextOMSOrderStatus();
      }
    });
  };

  const handleOrderCard = (record) => {
    setSelectedOrder(record);
    handleOMSOrderStatusSelection(_.filter(omsOrderStatus, (statusItem) => statusItem.statusValue === record.status)?.[0]);
  };

  const handleOrderSearchInput = (value) => {
    if (value !== "") {
      const results = omsOrdersList.filter((orderDetails) => {
        return orderDetails.orderNo.startsWith(value);
      });
      setSearchOrders(results);
    } else {
      setSearchOrders(omsOrdersList);
    }
    setOrder(value);
  };

  const omsOrderTotalPrice = _.sumBy(selectedOrder.lineItems, "price");

  // OMS Orders Modal Ends //

  // Sales Representative Modal Starts //

  const [salesRepModalOpen, setSalesRepModalOpen] = useState({
    status: false,
    title: "",
  });
  const [salesRepresent, setSalesRepresent] = useState({});
  const [salesRepresentDefaultLine, setSalesRepresentDefaultLine] = useState({ salesRepresentId: null, name: null });

  const handleSalesRepresentive = (record) => {
    if (salesRepModalOpen.title === "orderSalesRep") {
      setSalesRepresentDefaultLine({ ...record });
      setCart({
        ...cart,
        salesRepId: record.salesRepresentId,
      });
      localStorage.setItem(
        "cartObj",
        JSON.stringify({
          ...cart,
          salesRepId: record.salesRepresentId,
        })
      );
      setSalesRepresent(record);
    } else if (salesRepModalOpen.title === "itemSalesRep" && !_.isEmpty(selectedProductInCart)) {
      setSalesRepresentDefaultLine({ ...record });
      let lineItemsData = [...cart.items];
      const lineItemsIndex = _.findIndex(lineItemsData, (item) => item.productId === selectedProductInCart.productId);
      lineItemsData[lineItemsIndex]["salesRepId"] = record.salesRepresentId;
      lineItemsData[lineItemsIndex]["salesRepName"] = record.name;

      setCart({
        ...cart,
        items: lineItemsData,
      });
      localStorage.setItem(
        "cartObj",
        JSON.stringify({
          ...cart,
          items: lineItemsData,
        })
      );
    }
    setSalesRepModalOpen({ status: false, title: "" });
  };

  const [salesRepresentSearchInput, setSalesRepresentSearchInput] = useState("");
  const salesReprestiveList = tillData.tillAccess.csBunit.salesRep;
  const [filteredSalesRepresentList, setFilteredSalesRepresentList] = useState(salesReprestiveList);

  const handleSalesRepresentSearchInput = (value) => {
    if (value !== "") {
      const results = salesReprestiveList.filter((list) => {
        return list.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setFilteredSalesRepresentList(results);
    } else {
      setFilteredSalesRepresentList(salesReprestiveList);
    }
    setSalesRepresentSearchInput(value);
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey) {
      if (keyCode === 83) {
        if (posConfig.showLineSalesRep === "Y") {
          setSalesRepModalOpen({ status: true, title: "itemSalesRep" });
          setSalesRepresentSearchInput("");
          setFilteredSalesRepresentList(salesReprestiveList);
        }
      }
      if (keyCode === 67) {
        setSelectedRowKeys((v) => {
          if (v.length > 0) {
            return [];
          } else {
            return [0];
          }
        });
      }
      if (keyCode === 80) {
        openPaymentModal();
      }

      if (keyCode === 79) {
        setDisplayOrderHistory(true);
      }

      if (keyCode === 66) {
        setDisplayParkedBillModal(true);
      }

      if (keyCode === 73) {
        if (selectedRowKeysRef.current.length > 0) {
          setIsQtyUpdate(selectedProductInCartRef.current);
        }
      }

      if (keyCode === 76) {
        parkBill("parkKey");
      }

      if (keyCode === 72) {
        confirmDiscardCart();
      }
    }

    if (keyCode === 115) {
      setTimeout(() => {
        // productSearchInputRef.current.focus();
      }, 100);
    }
    if (keyCode === 27) {
      if ((e.target.id === "productSearchInputId" || e.target.id.search("productCardItem")) >= 0) {
        closeProductPanel();
      }
      setDisplayBatchSelection((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
      setSalesRepModalOpen({ status: false, title: "" });
      setSalesRepresentSearchInput("");
      closeCustomerSearch();

      if (paymentModalStateRef.current) {
        closePaymentModal();
      }
    }
    if (keyCode === 38) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] > 0) {
          return [v[0] - 1];
        } else {
          return [v[0]];
        }
      });
    }
    if (keyCode === 40) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] < cartItemsLengthRef.current - 1) {
          return [v[0] + 1];
        } else {
          return [v[0]];
        }
      });
    }

    if (keyCode === 187 || keyCode === 107) {
      if (selectedRowKeysRef.current.length > 0) {
        increaseProductQty(selectedProductInCartRef.current);
      }
    }
    if (keyCode === 109 || keyCode === 189) {
      if (selectedRowKeysRef.current.length > 0) {
        if (parseFloat(selectedProductInCartRef.current.weight) > 1) {
          decreaseProductQty(selectedProductInCartRef.current);
        }
      }
    }
    if (keyCode === 46) {
      if (selectedRowKeysRef.current.length > 0) {
        deleteProduct(selectedProductInCartRef.current);
      }
    }
  };

  const cartItemsLengthRef = useRef(0);
  const [cartObj, setOrderObj] = useState(null);

  useEffect(() => {
    cartItemsLengthRef.current = cart.items.length;
    setAmount((Math.abs(cart.total) - cart.paid).toFixed(precision));
    let flag = tillLayout !== 1 ? true : false;
    if (Math.abs(cart.total) <= cart.paid && cart.payments.length > 0 && flag) {
      orderState.current = 0;
      setPaymentProcessFlag(false);
      processOrder(cart.sOrderID);
    }
    try {
      const cartDetails = JSON.parse(localStorage.getItem("cartObj"));
      if (cartDetails !== null) {
        setOrderObj(cartDetails);
      }
    } catch (error) {
      console.error("Error parsing cart details:", error);
    }
    if (cart.discount) {
      upsertPOSLog(cart, "DAP");
    }
  }, [cart]);

  const selectedProductInCartRef = useRef({});
  useEffect(() => {
    selectedProductInCartRef.current = selectedProductInCart;
  }, [selectedProductInCart]);

  const selectedRowKeysRef = useRef([0]);
  useEffect(() => {
    selectedRowKeysRef.current = selectedRowKeys;
    if (selectedRowKeys.length > 0) {
      const cartIndex = cart.items.findIndex((ci) => ci.key === selectedRowKeys[0]);
      // setSelectedProductInCart({ ...cart.items[cartIndex] });
    }
  }, [selectedRowKeys]);

  const paymentModalStateRef = useRef(false);
  useEffect(() => {
    paymentModalStateRef.current = paymentModal;
    if (paymentModal) {
      setSelectedPaymentMethod(tillDataPaymentMethods.filter((item, index) => item.isDefault === "Y")?.[0] || {});
    }
  }, [paymentModal]);

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  });

  // Sales Representative Modal Ends //

  const [manualDiscountModalVisible, setManualDiscountModalVisible] = useState(false);
  const [manualDiscountInput, setManualDiscountInput] = useState("");
  const [manualDiscountTypes, setManualDiscountTypes] = useState([]);
  const [selectedManualDiscountType, setSelectedManualDiscountTypeValue] = useState("");
  const [enableManualDiscountInput, setEnableManualDiscountInput] = useState(false);

  const handleManualDiscountKeyPress = (value) => {
    if (!enableManualDiscountInput) {
      if (manualDiscountInput === "" && value === "x") {
        setManualDiscountInput("");
      } else if (value === "x") {
        setManualDiscountInput(`${manualDiscountInput.toString().substring(0, manualDiscountInput.toString().length - 1)}`);
      } else {
        setManualDiscountInput(`${manualDiscountInput}${value}`);
      }
    }
  };

  useEffect(() => {
    db.pricingRules.toArray().then((pr) => {
      const manualPricingRules = pr.filter((rule) => rule.manualDiscount === "Y");
      setManualDiscountTypes([...manualPricingRules]);
    });
  }, []);

  const setSelectedManualDiscountType = (value) => {
    setSelectedManualDiscountTypeValue(value);
    if (value !== "") {
      const mdi = manualDiscountTypes.findIndex((md) => md.mPricingrulesId === value);
      const discountValue = manualDiscountTypes[mdi].amountDiscount ? manualDiscountTypes[mdi].amountDiscount : manualDiscountTypes[mdi].percentageDiscount;
      if (discountValue) {
        setManualDiscountInput(discountValue);
        setEnableManualDiscountInput(true);
      } else {
        setManualDiscountInput("");
        setEnableManualDiscountInput(false);
      }
    }
  };

  const applyManualDiscount = () => {
    if (selectedManualDiscountType && manualDiscountInput && cart.items.length > 0) {
      setManualDiscountModalVisible(false);
      if (selectedRowKeys.length > 0) {
        // Process line discount
        processLineManualDiscount();
      } else {
        // Process a total discount
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
        if (selectedManualDiscountType === "F797B4AEB15149B1AC0A3E2CA39EB97A" && pricingRule.foc === "N") {
          processTotalManualDiscount();
        } else if (selectedManualDiscountType !== "F797B4AEB15149B1AC0A3E2CA39EB97A") {
          processTotalManualDiscount();
        }
      }
      upsertPOSLog("DAP");
    } else {
      message.warning("Please provide a valid input !");
    }
  };

  const processLineManualDiscount = () => {
    const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
    if (pricingRule.type === "V") {
      CheckoutFlatDiscount(cart, setCart, selectedRowKeys, pricingRule, manualDiscountInput, tillaccess);
    }

    if (pricingRule.type === "P") {
      CheckoutPercentageDiscount(cart, setCart, selectedRowKeys, pricingRule, manualDiscountInput, tillaccess);
    }
    setSelectedRowKeys([]);
  };

  const processTotalManualDiscount = (discountParam) => {
    CheckoutTotalManualDiscount(discountParam, setCart, cart, manualDiscountTypes, selectedManualDiscountType, manualDiscountInput, tillaccess);
  };

  const removeAllDiscounts = () => {
    setManualDiscountModalVisible(false);
    setManualDiscountInput("");
    setSelectedManualDiscountTypeValue("");
    const cartItems = cart.items;

    cartItems.forEach((addedToCart, i) => {
      const sp = parseFloat(addedToCart.realPrice);
      const mrp = parseFloat(sp) * addedToCart.weight;
      const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
      addedToCart.salePrice = sp;
      addedToCart.taxAmount = tax;
      addedToCart.nettotal = mrp;
      addedToCart.discount = 0;
      addedToCart.discountName = "";
      delete addedToCart.priority;
      cartItems[i] = addedToCart;
    });

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;

    for (let i = 0; i < cartItems.length; i += 1) {
      totalPrice += cartItems[i].nettotal;
      totalItemsQty += cartItems[i].weight;
      totalTax += cartItems[i].taxAmount;
      totalDiscounts += cartItems[i].discount;
      cartItems[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    delete cart["manualDiscountApplied"];

    if (tillaccess?.layout === "2" && localStorage.getItem("dineIn") === "Y") {
      let obj;
      const fbOrderData = JSON.parse(localStorage.getItem("tableName"));
      db.fbOrderData
        .where("cwrFbTableId")
        .equals(fbOrderData?.cwrFbTableId)
        .toArray()
        .then((ordersFetched) => {
          if (ordersFetched.length > 0) {
            ordersFetched.map(async (fbOrder) => {
              if (fbOrder.fbOrderStatus === "IP") {
                let orderLines = [];
                fbOrder.cart = {
                  ...cart,
                  items: [...cartItems],
                  total: totalPrice,
                  tax: totalTax,
                  discount: totalDiscounts,
                  totalQty: totalItemsQty,
                  roundOff: totalRoundOff,
                };
                fbOrder.fbOrderSync = "N";
                fbOrder.lines = orderLines;
                cartItems.map((obj) => {
                  orderLines.push(`{
                    fbOrderId: "${fbOrder.fbOrderId}"
                    fbOrderLineId: "${obj.fbOrderLineId}"
                    mPoductId: "${obj.productId}"
                    mBatchId: null
                    description: "good"
                    csUomId: "${obj.uom}"
                    csTaxId: "${obj.tax}"
                    discount:${obj.discount}
                    line: 1
                    qty: ${obj.weight}
                    unitPrice: ${obj.realPrice}
                    listPrice: 30
                    lineNet: 2.6
                    lineTax: ${obj.taxRate}
                    lineGross: 30
                    sOrderLineId: null
                    isOrdered: "Y"
                    meta:[]
                    }`);
                });
                await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                obj = {
                  fbOrder: {
                    fbOrderId: fbOrder.fbOrderId,
                    order: fbOrder,
                  },
                };
                // sendOrder(obj);
              }
            });
          }
        });
      SyncData(fbOrderData, "upsertFbOrder");
    }
    const finalCartObj = {
      ...cart,
      items: [...cartItems],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    };

    // setCart(finalCartObj);
    // localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    return finalCartObj;
  };

  const clearSelectedProductInCart = () => {
    setSelectedProductInCart({});
    // setTimeout(()=>{
    setSelectedRowKeys([]);
    // },100)
  };

  function parseDateTime(dateTimeString) {
    return new Date(dateTimeString.replace(" ", "T") + "Z");
  }

  function calculateDuration(startTime, endTime) {
    const durationMilliseconds = endTime - startTime;
    const totalSeconds = Math.floor(durationMilliseconds / 1000);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
  }

  function formatDuration(duration) {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(duration.hours).padStart(2, "0");
    const minutes = String(duration.minutes).padStart(2, "0");
    const seconds = String(duration.seconds).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const posLogActivity = (record, activity) => {
    const timeMark = timeStamp();
    const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
    let products = "";
    let formattedDuration = "";

    // Calculate duration if orderTimeDetails are present
    if (record.orderTimeDetails) {
      const { orderStartTime, orderEndTime } = record.orderTimeDetails;
      const startTime = parseDateTime(orderStartTime);
      const endTime = parseDateTime(orderEndTime);
      const duration = calculateDuration(startTime, endTime);
      formattedDuration = formatDuration(duration);
    }
    const trxId = uuidv4().replace(/-/g, "").toUpperCase();

    if (_.isArray(record)) {
      record.forEach((item) => {
        products += `SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.salePrice}, `;
      });
    } else {
      products = `SKU: ${record.value}, Qty: ${record.weight}, Unit Price: ${record.salePrice}, `;
    }

    db.logInformation.add({
      type: activity,
      action: "LOG",
      description: activity === "DLN" || activity === "DOR" || activity === "RQT" || activity === "SLR" ? products : activity === "SRD" || activity === "ACT" ? record : "",
      date: currentDate,
      time: timeMark,
      orderNo: `${cart.documentno}`,
      remarks: "",
      transactionId: `${trxId}`,
      status: "SCS",
      duration: formattedDuration ? `"${formattedDuration}"` : null,
    });
  };

  // Paytm QR Code Starts //

  const [paytmQrCodeModalOpens, setPaytmQrCodeModalOpens] = useState(false);
  const [qrCodeResponse, setQrCodeResponse] = useState({});

  const handleVerifyPayment = () => {
    let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
    let verifyPaytmUrl = `${hostUrl}paytm/verifyPayment`;
    const getPaymentSuccessValues = {
      midId: "Excelo34085435005810",
      orderId: `${cart.sOrderID}`,
      merchantKey: "qQUxrwRx@qE6zTxt",
      payUrlForVerify: "https://securegw-stage.paytm.in/v3/order/status",
      clientId: "C11",
      version: "v1",
    };
    Axios({
      url: verifyPaytmUrl,
      method: "POST",
      data: getPaymentSuccessValues,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.body.resultInfo.resultStatus === "TXN_SUCCESS") {
        setPaytmQrCodeModalOpens(false);
        processPayment(selectedPaymentMethod, amount);
      }
    });
  };

  // Paytm QR Code Ends //
  const [customerFlag, setCustomerFlag] = useState(true);

  const removeCutomer = async () => {
    setCustomerFlag(true);
    let latestData = removeAllDiscounts();
    let cartObj = {
      ...latestData,
      customer: defaultCustomer,
    };
    let updatedCart = cartObj;

    if (cartObj.items.length > 0) {
      await Promise.all(
        cartObj.items.map(async (ele) => {
          let addToCart = ele;
          addToCart.nettotal = parseFloat(addToCart.nettotal.toFixed(precision));
          updatedCart = await pricingRuleController(addToCart, cartObj, cartObj, setCart, cartObj, orderType);
          return updatedCart;
        })
      );
    } else {
      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
        ? JSON.parse(localStorage.getItem("orderTimeDetails"))
        : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails = {
        ...orderTimeDetails,
        orderStartTime: "", // Update orderStartTime to current time
      };
      localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    }

    if (cartObj.totalDiscountFlag) {
      await openPaymentModalByCustomer(updatedCart);
    }

    if (updatedCart?.couponInput?.length > 0) {
      let addToCart = cartObj.items[0];
      await Promise.all(
        updatedCart.couponInput.map(async (coupon) => {
          const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(coupon.mPricingruleId).toArray();
          if (matchingPricingRules[0].type !== "TD" && matchingPricingRules[0].type !== "TDF") {
            updatedCart = await pricingRuleController(
              addToCart,
              updatedCart,
              cart,
              setCart,
              cartRef,
              orderType,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          } else {
            updatedCart = await processBillDiscounts(
              matchingPricingRules[0],
              updatedCart,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          }
        })
      );
    }

    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;

    updatedCart.items = updatedCart.items.map((item, i) => {
      const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
      const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
      const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

      // Update aggregated totals
      updatedTotalPrice += nettotalFixed;
      updatedTotalItemsQty += item.weight;
      updatedTotalTax += taxAmountFixed;
      updatedTotalDiscounts += discountFixed;

      // Update individual item properties
      item.discount = discountFixed;
      item.key = i;
      item.nettotal = parseFloat(item.nettotal.toFixed(precision));

      if (!item.isGiftCard) {
        let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
        if (!isFinite(unitPrice)) unitPrice = 0;

        const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
        const netList = (item.listPrice - item.listPrice / (1 + item.taxRate / 100)).toFixed(precision);

        item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
        item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
        item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
        item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
        item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
        item.grosslist = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
        item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
        item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      }
      return item;
    });

    const sumLineTotals = (lines) => {
      let totalLineGross = 0;
      let totalLineTax = 0;

      lines.forEach((line) => {
        totalLineGross += line.nettotal;
        totalLineTax += line.taxAmount;
      });

      return { totalLineGross, totalLineTax };
    };
    // Compare and Adjust Order Totals
    const adjustOrderTotals = (order, lines) => {
      const { totalLineGross, totalLineTax } = sumLineTotals(lines);
      let adjustedOrderGross = order.total;
      let adjustedOrderTax = order.tax;

      const grossDifference = totalLineGross - adjustedOrderGross;
      const taxDifference = totalLineTax - adjustedOrderTax;

      if (Math.abs(grossDifference) > 0.01) {
        adjustedOrderGross += grossDifference;
      }

      if (Math.abs(taxDifference) > 0.01) {
        adjustedOrderTax += taxDifference;
      }

      return {
        ...order,
        total: adjustedOrderGross,
        tax: adjustedOrderTax,
      };
    };
    updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);

    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
    };
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    setCart({ ...finalCartObj });
    setShowPaymentMethods(false);
    upsertPOSLog(cart, "RCT");
  };

  // Bill Management //

  const handleManagement = () => {
    // history.push("/bill-management");
    setManagementScreenShow(true);
  };

  const [managementScreenShow, setManagementScreenShow] = useState(false);
  const [orderTypeSelection, setOrderTypeSelection] = useState("Dine In");

  const componentProps = {
    checkIsManualWeight,
    parkBill,
    openPaymentModal,
    addProduct,
    cart,
    setCart,
    cartObj,
    setOrderObj,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    decreaseProductQty,
    deleteCart,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    setIsProductsVisible,
    isProductsVisible,
    getSearchedItem,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    loading,
    setLoading,
    onBarcodeInput,
    openDisplayParkedBillModal,
    orderType,
    parkedList,
    setAllProductCategories,
    allProductCategories,
    handleBrandCheckboxChange,
    selectCategotyList,
    setSelectCategotyList,
    handleCategoryCheckboxChange,
    setSelectedProductBrand,
    selectedProductBrand,
    productSearchInput,
    filtersFlag,
    setFiltersFlag,
    // productSearchInputRef,
    productsCopy,
    selectProductCategory,
    selectProductInCart,
    selectedProductQty,
    selectProduct,
    selectSalseProduct,
    selectedProductInCart,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedKeys,
    searchHistoryInput,
    setSearchhistoryInput,
    setDisplayCustomerSearch,
    displayUAECustomerSearch,
    setDisplayUAECustomerSearch,
    displayUAECustomer,
    setDisplayUAECustomer,
    setDisplayOrderType,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    setParkedBillSearchInput,
    showOrderHistory,
    tillData,
    displayCustomerSearch,
    closeCustomerSearch,
    setCustomerSearchType,
    customerSearchType,
    handleCustomerSearch,
    customerSearchInput,
    setCustomerSearchResults,
    setCloseCustomerFlag,
    setCustomerSearchInput,
    customerSearchResults,
    selectCustomer,
    showEditOldCustomerFields,
    showAddNewCustomerFields,
    showAddNewUAECustomerFields,
    setDisplayAddNewCustomer,
    displayAddNewCustomer,
    form,
    UAECustomerForm,
    addNewCustomer,
    setDisplayEditOldCustomer,
    displayEditOldCustomer,
    editFlag,
    setEditFlag,
    editOldCustomer,
    displayOrderHistory,
    setDisplayOrderHistory,
    changeOrderHistorySearchType,
    searchOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    salesHistoryCustomerSearchInput,
    salesHistoryDocumentNoSearchInput,
    setSalesHistoryCustomerSearchInput,
    setSalesHistoryDocumentNoSearchInput,
    filterdParkedList,
    parkedList,
    setFilterdParkedList,
    discardParkedBill,
    selectParkedBill,
    selectLayAwayOrder,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    requestPayment,
    setDenaminationsKeyboard,
    denaminationsKeyboard,
    setCouponModalVisible,
    closePaymentModal,
    paymentModalLoyalityMessages,
    amount,
    onChangeAmount,
    processOrder,
    handleAmount,
    handleCashPayment,
    overPayedAmount,
    setOverPayedAmount,
    handleTotalQty,
    enterTotalQty,
    showPaymentMethods,
    setShowPaymentMethods,
    onChangeTotalQuantity,
    displaySetOrderType,
    posSaleTypes,
    changeOrderType,
    displayOfferProductSelectiton,
    offerProductsList,
    selectOfferProduct,
    displayBatchSelection,
    setDisplayBatchSelection,
    batchSetAvailable,
    selectProductToCart,
    displayManualQtyWeightInput,
    setDisplayManualQtyWeightInput,
    setDefaultImage,
    currentWeightSelectedProduct,
    productWeightModalInput,
    onProductModalChangeWeight,
    addManualWeightToProduct,
    couponModalVisible,
    closeCouponModal,
    checkCoupon,
    couponInput,
    setCouponInput,
    handleCouponInput,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    processOtpInput,
    loyaltyInputValue,
    setLoyaltyInputValue,
    handleLoyalityInput,
    checkLoyality,
    loyaltyPaymentOtp,
    setLoyaltyPaymentOtp,
    currencyType,
    setCurrencyType,
    setDisplayClock,
    pickProduct,
    addDefinedProduct,
    handleWeightManual,
    selectedProductCategory,
    filterDrawer,
    setFilterDrawer,
    // OMS Order MOdal and Side menu drawer//,
    openSideMenu,
    onClose,
    displayOMSOrderItemsModal,
    omsOrderStatus,
    selectedOMSOrderStatus,
    setSelectedOrder,
    handleOMSOrderStatusSelection,
    order,
    handleOrderSearchInput,
    searchOrders,
    selectedOrder,
    handleOrderCard,
    handleOmsOrders,
    setDisplayOMSOrderItemsModal,
    handleOMSStatusButton,
    omsOrderTotalPrice,
    sideMenuDrawervisible,
    setDisplayOfferProductSelection,
    // Sales Representative Modal //
    salesRepModalOpen,
    setSalesRepModalOpen,
    handleSalesRepresentive,
    salesRepresent,
    setSalesRepresent,
    salesRepresentSearchInput,
    setSalesRepresentSearchInput,
    handleSalesRepresentSearchInput,
    salesReprestiveList,
    filteredSalesRepresentList,
    setFilteredSalesRepresentList,
    prevProductsListRef,
    prevHistoryRef,
    manualDiscountModalVisible,
    setManualDiscountModalVisible,
    manualDiscountInput,
    setManualDiscountInput,
    manualDiscountTypes,
    setManualDiscountTypes,
    handleManualDiscountKeyPress,
    applyManualDiscount,
    selectedManualDiscountType,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    setEnableManualDiscountInput,
    removeAllDiscounts,
    clearSelectedProductInCart,
    setProductWeightModalInput,
    productListCardRef,
    paymentModalByCustomerState,
    posLogActivity,
    upsertPOSLog,
    // Paytm QR Code //
    paytmQrCodeModalOpens,
    setPaytmQrCodeModalOpens,
    qrCodeResponse,
    setQrCodeResponse,
    handleVerifyPayment,
    removeCutomer,
    posConfig,
    displayReturnOrderSearch,
    setDisplayReturnOrderSearch,
    handleSalesReturnFromSideMenu,
    // Bill Management //
    handleManagement,
    managementScreenShow,
    setManagementScreenShow,
    orderTypeSelection,
    setOrderTypeSelection,
    // Cash Management
    setAddCashFlag,
    addCashFlag,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    setCouponModalVisible,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    setSelectedKeys,

    // payment
    setQtyNumberFlag,
    setAmount,
    setNumb,
    amount,
    onChangeAmount,
    processOrder,
    paymentModalLoyalityMessages,
    paymentModalInputRef,
    quantityInputRef,
    // Cash Management
    setCashAddInFlag,
    cashAddInFlag,
    handleCahInOut,
    cashManagementForm,
    cashIn,
    pettCashIn,
    onChangeCheckbox,
    setSelectedProductInCart,
    // kiosk Screen
    kioskUI,
    setKioskUI,
    kioskLogin,
    layoutType,
    setLayoutType,
    kioskFilteredProducts,
    // gift card
    giftCardFlag,
    setGiftCardFlag,
    isGiftCardFlag,
    setIsGiftCardFlag,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    giftCardItems,
    CardPaymentForm,
    setGiftCardItems,
    setAddToBagProducts,
    setAddToBagFlag,
    addToBagFlag,
    addToBagProducts,
    giftCardType,
    setGiftCardType,
    selectGiftCardItem,
    setSelectGiftCardItem,
    validateGiftCard,
    setValidateGiftCard,
    validateGiftCardForm,
    giftCardBalance,
    setGiftCardBalance,
    handleGiftCardDetails,
    redeemGiftCard,
    giftCardData,
    setGiftCardData,
    handleGiftCard,
    giftCardForm,
    // keybord
    setKeyboardType,
    keyboardType,
    layout,
    setLayout,
    inputName,
    setInputName,
    keyboardParkbill,
    keyboardRef,
    keyboardProduct,
    handleKeyboardInput,
    handleKeyPress,
    orderHistorySearchInputRef,
    orderHistoryInput,
    setLoader,
    openPaymentModalByCustomer,
    paymentProcessFlag,
    setPaymentProcessFlag,
    selectedEditOldCustomer,
    setIsInputFocused,
    isInputFocused,
    inputFocused,
    setInputFocused,
    handleSelectProduct,
    getCategoryProducts,
    setSalesHistoryType,
    salesHistoryType,
    setStartRowData,
    setFilterdDate,
    startRowData,
    setOrderHistoryDetails,
    setOrdersCopy,
    ordersCopy,
    documentSequence,
    setDocumnetSequence,
    customerFlag,
    setCustomerFlag,
    notesValue,
    setNotesValue,
    selectedProductForNotes,
    setSelectedProductForNotes,
    productsData,
    orderDelay,
    setOrderDelay,
    setProductsData,
    stockList,
    setStockList,
    restaurantProductCategory,
    removeCoupon,
    // product add on
    setDisplayAddOnSelection,
    displayAddOnSelection,
    handleAddOnValue,
    handleQty,
    handleAdd,
    addOnsList,
    handleAddOnModalClose,
    selectedAddons,
    selectedProduct,
    setSelectedProduct,
    isUpdatingAddon,
    setIsUpdatingAddon,
    addDefinedProductWithAddons,
    handleAddOnModal,
    dynamicForm,
    setDynamicForm,
    dynamicModalForm,
    submitCustomAttributs,
    isSubmitting,
    setIsSubmitting,
  };

  return (
    <div>
      <RenderComponent {...componentProps} />
      <CoreModals {...componentProps} />
      <ReturnBill {...componentProps} />
    </div>
  );
};
// PointOfSale Component End

export default PointOfsaleCore;
