import db from "../../../database";
import { message,Modal } from "antd";
import i18next from "i18next";
import NotFoundImg from "../../../assets/images/NotFoundImg.svg";

export const barcodeScaner = (data, tillData, tillLayout, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection, setLayoutType, setIsProductsVisible, setProductsData, chackBarcodeFlag, setSelectedProductInCart,setProductSearchInput,cart) => {
  const t = i18next.t;
  if (data.length === 24) {
        rfidScaner(data, tillData, tillLayout, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection, setLayoutType, setIsProductsVisible, setProductsData, chackBarcodeFlag, setSelectedProductInCart,setProductSearchInput,cart);
    } else {
      if (tillData.posScanConfigs.length === 0 || tillData.posScanConfigs === null || chackBarcodeFlag){
        db.products
        .filter(
          (product) =>
            (product.name.toLowerCase().includes(data.toLowerCase())) ||
            (product.upcIndex === data) ||
            (product.upc === data) ||
            (product.value === data)
        )
        .limit(100)
        .toArray()
          .then(async(product) => {
            if (product.length === 1) {
              const obj = product[0];
              if (tillLayout === 3) {
                localStorage.setItem("layoutType", 1);
                setLayoutType(1);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "layoutType", newValue: 1 },
                });
                window.dispatchEvent(customEvent);
              }
              if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === data.toLowerCase());
                if (batchProductIndex >= 0 && obj.multiPrice === "N") {
                  addDefinedProduct(
                    obj,
                    obj.mBatch[batchProductIndex].upc,
                    obj.mBatch[batchProductIndex].batchno,
                    obj.mBatch[batchProductIndex].mBatchId,
                    obj.mBatch[batchProductIndex].price
                  );
                } else if (obj.mBatch.length === 1) {
                  addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
                } else {
                  // let upcKeys = 0;
                  const productSet = [];
                  const localObj = obj;
                  for (let i = 0; i < obj.mBatch.length; i += 1) {
                    const batchItem = { ...localObj.mBatch[i] };
                    const obj = { ...localObj };
                    if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                      // prettier-ignore
                      const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                      const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                      batchItem.price = taxedPrice;
                      obj.cTaxId = obj.contraTaxId;
                      obj.taxRate = obj.contraRate;
                    }
                    const productDefined = {
                      batchno: batchItem.batchno,
                      description: obj.description,
                      discount: 0,
                      discountName: "",
                      imageurl: obj.imageurl,
                      isDecimal: obj.isDecimal,
                      isManualQty: obj.isManualQty,
                      isPromoApplicable: obj.isPromoApplicable,
                      isReturn: false,
                      mBatchId: batchItem.mBatchId,
                      mPricingruleId: null,
                      name: obj.name,
                      nettotal: 0,
                      primaryOrderLine: null,
                      productId: obj.mProductId,
                      realPrice: obj.cTaxId,
                      listPrice: obj.slistprice,
                      sunitprice:obj.sunitprice,
                      returnQty: null,
                      salePrice: obj.cTaxId,
                      mrpPrice: batchItem.listPrice,
                      stock: obj.onhandQty,
                      taxCategory: obj.taxCategory,
                      tax: obj.cTaxId,
                      taxAmount: 0,
                      taxRate: obj.taxRate,
                      uom: obj.csUomId,
                      uom_name: obj.uomName,
                      isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
                      isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                      upc: batchItem.upc,
                      value: obj.value,
                      weight: 0,
                      shortDescription: obj.shortDescription,
                      hsncode: obj.hsncode,
                      csBunitId: obj.csBunitId,
                      mProductCategoryId: obj.mProductCategoryId,
                      productManufacturerId: obj.productManufacturerId,
                      productBrandId: obj.productBrandId,
                      batchedProduct: obj.batchedProduct,
                      batchedForSale: obj.batchedForSale,
                      batchedForStock: obj.batchedForStock,
                      multiPrice: obj.multiPrice,
                      shelfLife: obj.shelfLife,
                    };
                    // setSelectedProductInCart(productDefined);
                    // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                    productSet.push(productDefined);
                  }
                  setBatchSetAvailable([...productSet]);
                  setDisplayBatchSelection(true);
                }
              } else {
                let modifiedQty = obj.weight >=0 ? obj.weight + 1 : 1
                addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice, true, modifiedQty,true);
              }
            } else if(product.length>1){
              setIsProductsVisible(true);
              setProductsData([...product]);
            }else { 
              await upcPricingRule(data, t, addDefinedProduct, setProductSearchInput);
          }
          
          });
      }else {
        barcodeScanFromConfig(data, tillData, tillLayout, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection, setLayoutType, setIsProductsVisible, setProductsData, setSelectedProductInCart,setProductSearchInput);
      }
    }
}

const barcodeScanFromConfig = (data, tillData, tillLayout, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection, setLayoutType, setIsProductsVisible, setProductsData, setSelectedProductInCart,setProductSearchInput) => {
    let newData = data.toString();
    if (tillData.posScanConfigs.map((item) => item.scanTrxType === "PR" && item.barcodeLength === newData.length)) {
      let barcodeCheck = false;
      tillData.posScanConfigs.map((item) => {
        if (item.formula !== null) {
            let formula = JSON.parse(item.formula);
            let propertyValue = formula.property["product.value"];
            let formulaString;
            let formulaTotalPrice;
            let modifiedQty;
            let modifiedPrice;
            if(item.startsWith === "21" && data.substring(0, 2) === "21"){
                let propertyTotalprice = formula.property["cartline.total"]
                let totalPrice = newData.substr(Number(propertyTotalprice.position - 1), Number(propertyTotalprice.length));
                formulaString = newData.substr(Number(propertyValue.position - 1), Number(propertyValue.length));
                formulaTotalPrice = parseFloat(totalPrice.slice(0, -3) + "." + totalPrice.slice(-3))
            }else if(item.startsWith === "36" && formula.property["product.qty"] && formula.property["product.unitprice"] && data.substring(0, 2) === "36"){
                let propertyQty = formula.property["product.qty"];
                let propertyUnitprice = formula.property["product.unitprice"];
                formulaString = newData.substr(Number(propertyValue.position - 1), Number(propertyValue.length));
                let formulaQty = newData.substr(Number(propertyQty.position - 1), Number(propertyQty.length));
                let formulaUnitprice = newData.substr(Number(propertyUnitprice.position - 1), Number(propertyUnitprice.length));
                modifiedQty = (formulaQty / 1000).toFixed(2);
                modifiedPrice = Number((formulaUnitprice / 100).toFixed(2));
            }
            if(formulaString === undefined){
              formulaString = data
            }
          db.products
            .where("value")
            .equalsIgnoreCase(formulaString)
            .toArray()
            .then((product) => {
              if (product.length > 0) {
                const obj = product[0];
                if(item.startsWith === "21" && data.substring(0, 2) === "21"){
                    modifiedQty = (formulaTotalPrice / obj.sunitprice) >=0 ? (formulaTotalPrice / obj.sunitprice): 1;
                    let multipliedValue = modifiedQty * obj.sunitprice * 100;
                    // Round the multiplied value to integer
                    let roundedValue = Math.floor(multipliedValue);
                    // Divide by 100 to get the original value with trailing zeros
                    modifiedPrice = Number(Number(roundedValue/100).toFixed(obj.uomData[0]?.stdprecision >0 ? obj.uomData[0]?.stdprecision : 2)) >=0 ?Number(Number(roundedValue/100).toFixed(obj.uomData[0]?.stdprecision >0 ? obj.uomData[0]?.stdprecision : 2)) : obj.sunitprice ;
                }
                if(formulaString === undefined){
                  modifiedQty = 1;
                  modifiedPrice = obj.sunitprice;
                }
                if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
                  const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === data.toLowerCase());
                  if (batchProductIndex >= 0 && obj.multiPrice === "N") {
                    obj.mBatch[batchProductIndex].price = modifiedPrice;
                    addDefinedProduct(
                      obj,
                      obj.mBatch[batchProductIndex].upc,
                      obj.mBatch[batchProductIndex].batchno,
                      obj.mBatch[batchProductIndex].mBatchId,
                      obj.mBatch[batchProductIndex].price,
                      true,
                      modifiedQty
                    );
                  } else if (obj.mBatch.length === 1) {
                    obj.mBatch[0].price = modifiedPrice;
                    addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price, true, modifiedQty);
                  } else {
                    // let upcKeys = 0;
                    const productSet = [];
                    const localObj = obj;
                    for (let i = 0; i < obj.mBatch.length; i += 1) {
                      const batchItem = { ...localObj.mBatch[i], price: modifiedPrice };
                      const obj = { ...localObj };
                      if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                        // prettier-ignore
                        const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                        const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                        batchItem.price = taxedPrice;
                        obj.cTaxId = obj.contraTaxId;
                        obj.taxRate = obj.contraRate;
                      }
                      const productDefined = {
                        batchno: batchItem.batchno,
                        description: obj.description,
                        discount: 0,
                        discountName: "",
                        imageurl: obj.imageurl,
                        isDecimal: obj.isDecimal,
                        isManualQty: obj.isManualQty,
                        isPromoApplicable: obj.isPromoApplicable,
                        isReturn: false,
                        mBatchId: batchItem.mBatchId,
                        mPricingruleId: null,
                        name: obj.name,
                        nettotal: 0,
                        primaryOrderLine: null,
                        productId: obj.mProductId,
                        realPrice: batchItem.price,
                        listPrice: obj.slistprice,
                        sunitprice:obj.sunitprice,
                        returnQty: null,
                        salePrice: batchItem.price,
                        mrpPrice: batchItem.listPrice,
                        stock: obj.onhandQty,
                        taxCategory: obj.taxCategory,
                        tax: obj.cTaxId,
                        taxAmount: 0,
                        taxRate: obj.taxRate,
                        uom: obj.csUomId,
                        uom_name: obj.uomName,
                        isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
                        isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                        upc: batchItem.upc,
                        value: obj.value,
                        weight: modifiedQty,
                        shortDescription: obj.shortDescription,
                        hsncode: obj.hsncode,
                        csBunitId: obj.csBunitId,
                        mProductCategoryId: obj.mProductCategoryId,
                        productManufacturerId: obj.productManufacturerId,
                        productBrandId: obj.productBrandId,
                        batchedProduct: obj.batchedProduct,
                        batchedForSale: obj.batchedForSale,
                        batchedForStock: obj.batchedForStock,
                        multiPrice: obj.multiPrice,
                        shelfLife: obj.shelfLife,
                      };
                      // setSelectedProductInCart(productDefined);
                      // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                      productSet.push(productDefined);
                    }
                    setBatchSetAvailable([...productSet]);
                    setDisplayBatchSelection(true);
                  }
                } else {
                    if(item.startsWith === "36" && data.substring(0, 2) === "36"){
                      obj.sunitprice = modifiedPrice > 0 ? modifiedPrice : obj.sunitprice;
                    }
                    if(modifiedQty === undefined){
                      modifiedQty = obj?.weight >0 ? obj.weight + 1 : 1;
                    }
                  addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice, true, modifiedQty,false,modifiedPrice);
                }
              } else {
                if(typeof(data) !== "object"){
                  let chackBarcodeFlag = true
                  barcodeScaner(data, tillData, tillLayout, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection, setLayoutType, setIsProductsVisible, setProductsData, chackBarcodeFlag, setSelectedProductInCart,setProductSearchInput);
                }
                // message.warning("Product Not Found !");
              }
            });
        }
      });
    }
}

const rfidScaner = (data,tillData, tillLayout, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection, setLayoutType, setIsProductsVisible, setProductsData, chackBarcodeFlag, setSelectedProductInCart,setProductSearchInput,cart) => {
    db.rfidData
    .where("tag_value")
    .equalsIgnoreCase(data)
    .toArray()
    .then((item) => {
      if (item.length > 0) {
        const scannedProductCode = item[0].product_code;
        const existingIndex = cart?.items?.findIndex((item) => item.value === scannedProductCode);
        if (existingIndex === -1) {
          barcodeScaner(item[0].product_code, tillData, tillLayout, addDefinedProduct, setBatchSetAvailable, setDisplayBatchSelection, setLayoutType, setIsProductsVisible, setProductsData, chackBarcodeFlag, setSelectedProductInCart,setProductSearchInput,cart);
          db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
          if (tillLayout === 3) {
            localStorage.setItem("layoutType", 1);
            setLayoutType(1);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "layoutType", newValue: 1 },
            });
            window.dispatchEvent(customEvent);
          }
        } else {
          message.info("Product Already Scanned");
        }
      }
    });
}


const upcPricingRule = async (data, t, addDefinedProduct, setProductSearchInput) => {
  const pr = await db.pricingRules.toArray();
  let productFlag = false;

  for (const ele of pr) {
      if (ele.upc === data) {
          const pro = await db.products
              .where("mProductId")
              .equalsIgnoreCase(ele.mPricingXProducts[0].mProductId)
              .toArray();

          if (pro.length > 0) {
              productFlag = true;
              addDefinedProduct(pro[0], pro[0].upc, null, null, pro[0].sunitprice, true, ele.mPricingXProducts[0].quantity);
          }
      }
  }

  if (!productFlag) {
      // message.warning(`${t('Product not found')}`);
      Modal.info({
        icon:null,
        okButtonProps: { style: { display: 'none' } },
        okText:"Go Back",
        content: (
          <div style={{ 
            fontSize: '18px', 
            fontWeight: '500', 
            textAlign: 'center', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            paddingLeft:0,
            fontSize:"1vw"
           }}>
            <img src={NotFoundImg} alt="Not Found" style={{ width: '120px', height: '100px', marginBottom: '5px',marginTop:"10px" }} /> 
            <p style={{ margin: '5px 0', fontSize: '2em', fontWeight: '600',color:"#0F0718" }}>Product Not Found</p>
            <hr style={{width:"70%",color:"rgba(146,144,152,0.5"}}/>
            <p style={{ color:"#0F0718",fontWeight:400,fontSize:"1.25em",padding: '15px 15px 0 15px' }}>The scanned product is not in the system. Please check the barcode or enter the item manually.</p>
          </div>
        ),
        style: {
          textAlign: 'center',
          height:"50vh",
          width:"50%"
        },
        bodyStyle: {
          padding: '0',
        },
        closable: false,
        onOk: null,
      
      });

      setTimeout(() => {
        Modal.destroyAll(); 
      }, 1500);
  }
  setProductSearchInput("");
}